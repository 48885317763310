function convertTo12HourFormat(time) {
    // Split the input time into components
    let [hours, minutes, seconds] = time.split(':').map(Number);

    // Determine AM or PM
    let period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours from 24-hour to 12-hour format
    hours = hours % 12 || 12; // Convert '0' or '12' to '12', all other hours modulo 12

    // Format the hours, minutes, and seconds to always be two digits
    hours = String(hours).padStart(2, '0');
    minutes = String(minutes).padStart(2, '0');
    seconds = String(seconds).padStart(2, '0');

    // Return the formatted time
    return `${hours}:${minutes} ${period}`;
    // return `${hours}:${minutes}:${seconds} ${period}`;
}

// Export the function
export default convertTo12HourFormat;