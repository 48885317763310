import { useState } from 'react';
import FacilityTypeMasterBody from '../Component/FacilityTypeMasterBody';
import Layout from '../Layout';


export default function Master() {

 
    return (

        <Layout>
            <FacilityTypeMasterBody  />
        </Layout>
    )
}