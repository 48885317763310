import React, { useState } from 'react';
import Layout from '../../Layout';
import BillerBody from './Components/BillerBody';


export default function Biller() {

    return (
        <Layout>
            <BillerBody />
        </Layout>
    )
}