import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    InputLabel,
    Grid,
    IconButton,
    Menu,
    CircularProgress
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';


import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getAllFacility, getAllFacilityTypes, getAllProviders, getFacilityByProviderId, getReportByProvider, getSuperBillByAdmin } from '../api/action';
import { useDispatch } from 'react-redux';
import CustomPagination from '../Utils/CustomPagination';
import * as XLSX from 'xlsx';
import CustomTextField from '../Utils/CustomTextfield';

const ReportsBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    // const [searchFacilityType, setSearchFacilityType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [updated, setUpdated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [facilityTypeList, setFacilityTypeList] = useState([]);
    const [searchProvider, setSearchProvider] = useState(null);
    const [searchFacility, setSearchFacility] = useState(null);
    const [searchFacilityType, setSearchFacilityType] = useState(null);
    const providerId = localStorage.getItem("userId")
    const profileId = localStorage.getItem("profileId")
    const name = localStorage.getItem("userName")
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchFirstName, setSearchFirstName] = useState('');
    const [searchLastName, setSearchLastName] = useState('');
    const [counts, setCounts] = useState("");
    const [search, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [status, setStatus] = useState('');
    const [startDate, endDate] = dateRange;
    const [previousPayload, setPreviousPayload] = useState(null)
    const ROWS_PER_PAGE = 50;

    useEffect(() => {
        const matchingProvider = providerList.find(provider =>
            `${provider.First_Name} ${provider.Last_Name}` === name
        );
        if (matchingProvider) {
            setSearchProvider(matchingProvider);
        }
    }, [providerList, name]);

    console.log(providerId)
    const dispatch = useDispatch();

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        setSelectedRows((prevSelected) =>
            isSelected
                ? prevSelected.filter((code) => code !== facilityCode)
                : [...prevSelected, facilityCode]
        );
    };

    const handleEditClick = (event, selectedFacility) => {
        setAnchorEl(event.currentTarget);
        setSelectedFacility(selectedFacility);
    };

    const handleEditClose = () => {
        setAnchorEl(null);
    };

    // useEffect(() => {
    //     setLoading(true)
    //     const payload = null;
    //     dispatch(
    //         getReportByProvider(payload, profileId,
    //             (data) => {
    //                 setLoading(false);
    //                 setData(data?.data)
    //             },
    //             (error) => {
    //                 console.log(error)
    //                 setLoading(false)
    //             }
    //         )
    //     );

    // }, [updated])

    const formatDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleSearch = () => {

        setLoading(true)
        const payload = {
            "facility_name": searchFacility?.id,
            "first_name": searchFirstName,
            "last_name": searchLastName,
            "status": status,
            "date_from": startDate ? formatDate(startDate) : null,
            "date_to": endDate ? formatDate(endDate) : null,
            "provider_id": profileId,
            "download_excel": false

        }

        dispatch(
            getReportByProvider(payload, currentPage,
                (data) => {
                    setLoading(false);
                    setFilteredData(search === true ? data?.data?.results : []);
                    setCounts(data?.data?.count)
                    setSelectAll(false)
                    setSelectedRows([])
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

        // setCurrentPage(1); 
    };

    useEffect(() => {
        handleSearch()
        setSearch(true)
    }, [updated, currentPage])

    useEffect(() => {
        setCurrentPage(1)

    }, [searchFacility, searchFirstName, searchLastName, startDate, endDate, status])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllProviders(payload,
                (data) => {
                    setLoading(false);
                    setProviderList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacilityTypes(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setFacilityTypeList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    // useEffect(() => {
    //     setLoading(true)
    //     const payload = null;
    //     dispatch(
    //         getAllFacility(payload,
    //             (data) => {
    //                 console.log(data?.data?.Data)
    //                 setLoading(false);
    //                 setFacilityList(data?.data?.Data)
    //             },
    //             (error) => {
    //                 console.log(error)
    //                 setLoading(false)
    //             }
    //         )
    //     );

    // }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getFacilityByProviderId(payload, providerId,
                (data) => {
                    setLoading(false);
                    // const formattedFacilityList = data?.data?.Data.map(facility => ({
                    //   title: `${facility.facility_name}`
                    // }));
                    //   setAllFacilities(data?.data?.Data)
                    setFacilityList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    const handleEdit = (newStatus) => {
        if (selectedFacility) {
            const updatedFacility = {
                ...selectedFacility,
                status: newStatus,
            };

            // Update the status in the data array
            const updatedData = data.map((facility) =>
                facility.facilityCode === selectedFacility.facilityCode ? updatedFacility : facility
            );

            // Trigger a re-render by updating the data state
            setData(updatedData);
            onEdit(updatedFacility);
            setAnchorEl(null);
        }
    };



    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    console.log(searchProvider)

    // const filteredData = data.filter((row) => {
    //     // Filter based on provider
    //     const matchesProvider = searchProvider || (typeof searchProvider === 'string' && (
    //         row.provider_profile?.First_Name.toLowerCase().includes(searchProvider.toLowerCase()) ||
    //         row.provider_profile?.Last_Name.toLowerCase().includes(searchProvider.toLowerCase())
    //     ));



    //     // Filter based on other criteria (if needed)

    //     // Return true only if all conditions match
    //     return matchesProvider 
    // });


    // useEffect(() => {
    //     setCurrentPage(1)
    // }, [searchProvider, searchFacility, searchFacility, searchFacilityType, searchFirstName, searchLastName])


    // const filteredData = data && data.length !== 0 ? data?.filter((row) => {
    //     const providerFullName = searchProvider ? searchProvider.First_Name + ' ' + searchProvider.Last_Name : '';
    //     const facilityName = searchFacility ? searchFacility.facility_name : '';
    //     const facilityType = searchFacilityType ? searchFacilityType.facility_type_Name : '';
    //     const matchesFirstName = row.patient_name.toLowerCase().includes(searchFirstName.toLowerCase());
    //     const matchesLastName = row.last_name.toLowerCase().includes(searchLastName.toLowerCase());

    //     const matchesProvider = !searchProvider || (row.provider_profile?.First_Name + ' ' + row.provider_profile?.Last_Name === providerFullName);
    //     const matchesFacility = !searchFacility || (row.facility_master?.facility_name === facilityName);
    //     const matchesFacilityType = !searchFacilityType || (row.facility_type?.facility_type_Name === facilityType);
    //     const matchesStatus = status === '' || row?.is_status.toLowerCase() === status.toLowerCase();
    //     const serviceDate = new Date(row.date_of_service);
    //     const matchesDateRange = (!startDate && !endDate) ||
    //         (startDate && endDate && serviceDate >= startDate && serviceDate <= endDate);

    //     return matchesProvider && matchesFacility && matchesFacilityType && matchesDateRange && matchesStatus && matchesFirstName && matchesLastName;
    // }) : [];

    const handleClearSearch = () => {
        // setSearchProvider(null);
        setSearchFirstName("")
        setSearchLastName("")
        setDateRange([null, null])
        setSearchFacility(null);
        setSearchFacilityType(null);
        setStatus("")
    };

    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            const allCodes = filteredData.map((row) => row.id);
            setSelectedRows(allCodes);
            // Set all rows id in id state
            //   setId(allCodes);
        } else {
            setSelectedRows([]);
            //   setId([]);
        }
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;

    const handleDownloadExcel = () => {
        if (selectAll === true) {
            setLoading(true)
            const payload = {
                "facility_name": searchFacility?.id,
                "first_name": searchFirstName,
                "last_name": searchLastName,
                "status": status,
                "date_from": startDate ? formatDate(startDate) : null,
                "date_to": endDate ? formatDate(endDate) : null,
                "provider_id": profileId,
                "download_excel": selectAll

            }

            dispatch(
                getReportByProvider(payload, currentPage,
                    (data) => {
                        setLoading(false);
                        // setData(data?.data)
                        const exportData = data?.data
                            .map(row => ({
                                // 'Facility Type': row.facility_type?.facility_type_Name,
                                // 'State': row.state?.state_Name,
                                'Facility': row.facility_master?.facility_name,
                                'Facility': row.facility_master?.facility_name,
                                'Provider First Name': row.provider_profile?.First_Name,
                                'Provider Last Name': row.provider_profile?.Last_Name,

                                // 'Provider': row.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name,
                                'Patient First Name': row.patient_name,
                                'Patient Last Name': row.last_name,
                                'Date of Birth': row.dob,
                                'Date of Service': row.date_of_service,
                                'CPT Code': row.cpt_code?.visit_reason_code,
                                'DX Code': row.dx_code,
                                'Modifier': row.modifier,
                                'Admission': row.admission,
                                'Comment': row.comment,
                                'Reason for Visit': row.reason_for_visit,
                                // 'Status': row.is_status,
                                // 'Biller': row.biller_profile !== null ? row.biller_profile?.First_Name + " " + row.biller_profile?.Last_Name : "",
                                'Submitted On': "",
                                'Time': "",

                            }));

                        // Create a new workbook

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();

                        // Convert data to worksheet
                        const ws = XLSX.utils.json_to_sheet(exportData);

                        // Convert data to worksheet
                        const columnWidths = [
                            // { wch: 20 }, // Width of Provider column
                            // { wch: 20 }, // Width of Facility column
                            { wch: 45 }, // Width of Facility Type column
                            { wch: 15 }, // Width of State column
                            { wch: 20 }, // Width of Patient Name column
                            { wch: 20 }, // Width of Last Name column
                            { wch: 15 }, // Width of Date of Birth column
                            { wch: 15 }, // Width of Date of Service column
                            { wch: 15 }, // Width of CPT Code column
                            { wch: 15 }, // Width of DX Code column
                            { wch: 15 }, // Width of Admission column
                            { wch: 20 }, // Width of Reason for Visit column
                            { wch: 20 }, // Width of Comment column
                            { wch: 15 }, // Width of Modifier column
                            { wch: 15 }, // Width of Status column
                            { wch: 20 }  // Width of Biller column
                        ];

                        ws['!cols'] = columnWidths;

                        // Add worksheet to workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

                        // Save the workbook as an Excel file
                        XLSX.writeFile(wb, 'Reports.xlsx');
                        setSelectAll(false)
                        setSelectedRows([])
                        // setFilteredData(data?.data)
                    },
                    (error) => {
                        console.log(error)
                        setLoading(false)
                    }
                )
            );
        } else {
            const exportData = filteredData?.filter(row => selectedRows.includes(row.id))
                .map(row => ({
                    'Facility Type': row.facility_type?.facility_type_Name,
                    // 'State': row.state?.state_Name,
                    'Facility': row.facility_master?.facility_name,
                    // 'Facility': row.facility_master?.facility_name,
                    'Provider First Name': row.provider_profile?.First_Name,
                    'Provider Last Name': row.provider_profile?.Last_Name,

                    // 'Provider': row.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name,
                    'Patient First Name': row.patient_name,
                    'Patient Last Name': row.last_name,
                    'Date of Birth': row.dob,
                    'Date of Service': row.date_of_service,
                    'CPT Code': row.cpt_code?.visit_reason_code,
                    'DX Code': row.dx_code,
                    'Modifier': row.modifier,
                    'Admission': row.admission,
                    'Comment': row.comment,
                    'Reason for Visit': row.reason_for_visit,
                    // 'Status': row.is_status,
                    // 'Biller': row.biller_profile !== null ? row.biller_profile?.First_Name + " " + row.biller_profile?.Last_Name : "",
                    'Submitted On': "",
                    'Time': "",

                }));

            // Create a new workbook

            // Create a new workbook
            const wb = XLSX.utils.book_new();

            // Convert data to worksheet
            const ws = XLSX.utils.json_to_sheet(exportData);

            // Convert data to worksheet
            const columnWidths = [
                // { wch: 20 }, // Width of Provider column
                // { wch: 20 }, // Width of Facility column
                { wch: 45 }, // Width of Facility Type column
                { wch: 15 }, // Width of State column
                { wch: 20 }, // Width of Patient Name column
                { wch: 20 }, // Width of Last Name column
                { wch: 15 }, // Width of Date of Birth column
                { wch: 15 }, // Width of Date of Service column
                { wch: 15 }, // Width of CPT Code column
                { wch: 15 }, // Width of DX Code column
                { wch: 15 }, // Width of Admission column
                { wch: 20 }, // Width of Reason for Visit column
                { wch: 20 }, // Width of Comment column
                { wch: 15 }, // Width of Modifier column
                { wch: 15 }, // Width of Status column
                { wch: 20 }  // Width of Biller column
            ];

            ws['!cols'] = columnWidths;

            // Add worksheet to workbook
            XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

            // Save the workbook as an Excel file
            XLSX.writeFile(wb, 'Reports.xlsx');
            setSelectAll(false)
            setSelectedRows([])
        }

        // Filter rows based on selectedRows array

    };

    function convertDateString(dateString) {
        // Create a Date object from the date string
        const date = new Date(dateString);

        // Extract the month, day, and year
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        // Format the date as MM/DD/YYYY
        return `${month}/${day}/${year}`;
    }


    console.log(convertDateString(startDate), "-", convertDateString(endDate))


    return (
        <Box sx={{ px: 10, py: 2 }}>
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold' }}>Billing Report</Typography>
            <Grid container spacing={2} sx={{ py: 4 }}>
                {/* <Grid item md={2} xs={6}>
                    <InputLabel id="provider-label">Provider</InputLabel>
                    <Autocomplete
                        disabled
                        className='scrollBar'
                        fullWidth
                        value={searchProvider}
                        onChange={(event, newValue) => {
                            setSearchProvider(newValue);
                        }}
                        // sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={providerList}
                        getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />
                </Grid> */}
                <Grid item md={2.4} xs={6}>
                    <InputLabel id="facility-name-label">Facility Name</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchFacility}
                        onChange={(event, newValue) => {
                            setSearchFacility(newValue);
                        }}
                        // sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={facilityList} // Pass the array directly
                        getOptionLabel={(option) => option.facility_name}
                        getOptionSelected={(option, value) => option.facility_name === value.facility_name}
                        isOptionEqualToValue={(option, value) => option.facility_name === value.facility_name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px' } }}
                            />
                        )}
                    />
                </Grid>


                <Grid item md={2.4} xs={6}>
                    <InputLabel>Patient First Name</InputLabel>
                    <CustomTextField
                        value={searchFirstName}
                        setValue={setSearchFirstName}
                    />
                </Grid>

                <Grid item md={2.4} xs={6}>
                    <InputLabel>Patient Last Name</InputLabel>
                    <CustomTextField
                        value={searchLastName}
                        setValue={setSearchLastName}
                    />
                </Grid>

                <Grid item md={2.4} xs={6}>

                    <InputLabel id="facility-type-label">Date From / DoS</InputLabel>
                    <DatePicker
                        // placeholderText='Select Start Date'
                        selected={startDate}
                        onChange={(date) => setDateRange([date, endDate])}
                        isClearable={true}
                        className='reactDateRangePicker3'
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    // style={{height:'50px !important' }}

                    />
                </Grid>

                <Grid item md={2.4} xs={6}>

                    <InputLabel id="facility-type-label">Date To / DoS</InputLabel>
                    <DatePicker
                        // placeholderText='Select End Date'
                        selected={endDate}
                        onChange={(date) => setDateRange([startDate, date])}
                        isClearable={true}
                        className='reactDateRangePicker3'
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />


                </Grid>

                {/* <Grid item md={2.4} xs={6}>
                   
                    <InputLabel id="facility-type-label">Facility Type</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchFacilityType}
                        onChange={(event, newValue) => {
                            setSearchFacilityType(newValue);
                        }}
                        sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={facilityTypeList}
                        getOptionLabel={(option) => option.facility_type_Name}
                        getOptionSelected={(option, value) => option.facility_type_Name}
                        isOptionEqualToValue={(option, value) => option.facility_type_Name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />
                </Grid> */}

                <Grid item md={2.4} xs={6}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        fullWidth
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        sx={{ height: '32px', padding: '5px 10px', }}
                    >
                        <MenuItem value={""}>
                            All
                        </MenuItem>
                        {['Pending', 'Confirmed'].map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                    </Select>

                </Grid>

                <Grid item md={9.2} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', mt:3 }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 1 }}
                        >
                            Clear
                        </Button>

                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', }}
                        >
                            Search
                        </Button>
                    </Box>
                </Grid>
            </Grid>



            {loading ? (
                <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress sx={{ color: '#0093AF !important' }} />
                </Box>
            ) :
                filteredData?.length === 0 ? (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                        No Data Available
                    </Typography>
                ) :
                    (
                        <Box sx={{ my: 4 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}>
                                <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', }}>Reports</Typography>
                                <Button
                                    sx={{
                                        float: 'right',
                                        boxShadow: 'none !important',
                                        background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important',

                                    }}
                                    variant="contained"
                                    onClick={handleDownloadExcel}
                                    disabled={selectedRows.length === 0}
                                >
                                    Download Excel
                                </Button>
                            </Box>

                            <TableContainer className='scrollBar table-container' component={Paper} >
                                <Table className='custom-table'>
                                    <TableHead sx={{ backgroundColor: '#0093AF', py: 0, }}>
                                        <TableRow>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                    sx={{ color: '#fff', }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Provider</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Facility</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Facility Type</TableCell>
                                            {/* <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >State</TableCell> */}
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >First Name</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Last Name</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Birth</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Service</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >CPT Code</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >DX Code</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Admission</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Reason for Visit</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Comment</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Modifier</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1 }} >Status</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1 }} >Biller</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody sx={{ height: '80vh', overflow: 'auto', }}    >
                                        {(filteredData ? filteredData.reverse() : []).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ py: 0.5 }}>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.id)}
                                                        onChange={() => handleCheckboxChange(row.id)}
                                                        sx={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_master?.facility_name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_type?.facility_type_Name}</TableCell>
                                                {/* <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.state?.state_Name}</TableCell> */}
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.patient_name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.last_name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dob}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.date_of_service}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.cpt_code?.visit_reason_code}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dx_code}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.admission}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.reason_for_visit}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.comment}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.modifier}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }} >{row?.is_status}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }} >{row?.biller_profile !== null ? row?.biller_profile?.First_Name + " " + row?.biller_profile?.Last_Name : ""}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                count={Math.ceil(counts / ROWS_PER_PAGE)}
                                page={currentPage}
                                onChange={handleChangePage}
                            />
                        </Box>
                    )}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEditClose}
            >
                <MenuItem onClick={() => handleEdit('Active')}>Active</MenuItem>
                <MenuItem onClick={() => handleEdit('Inactive')}>Inactive</MenuItem>
            </Menu>
        </Box>
    );
};

export default ReportsBody;
