import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    TextField,
    Button,
    FormControl,
    Select,
    MenuItem,
    Typography,
    Box,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Checkbox,
    InputLabel,
    Grid,
    IconButton,
    Menu,
    CircularProgress
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { deleteSuperBill, deleteSuperBillByBiller, getAllFacility, getAllFacilityTypes, getAllProviders, getFacilityByProviderId, getFacilityTypeByProviderId, getProvidersByBillerId, getReportByBiller, getReportByProvider, getSuperBillByAdmin, updateMultipleReportByBillerId, updateReportByBillerId } from '../../../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../../../Utils/CustomSnackbar';
import CustomPagination from '../../../Utils/CustomPagination';
import CustomTextField from '../../../Utils/CustomTextfield';
import DeleteModal from '../../../Utils/DeleteModal';

const BillerReportsBody = ({ onEdit, onDelete }) => {
    const billerId = localStorage.getItem("userId")
    const isDeletedAccess = localStorage.getItem("is_deleted_access")
    console.log(isDeletedAccess, "isDeletedAccess")

    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    // const [searchFacilityType, setSearchFacilityType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [dateRange, setDateRange] = useState([null, null]);
    const [updated, setUpdated] = useState(false);
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [providerList, setProviderList] = useState([]);
    const [facilityList, setFacilityList] = useState([]);
    const [facilityTypeList, setFacilityTypeList] = useState([]);
    const [searchProvider, setSearchProvider] = useState(null);
    const [searchFacility, setSearchFacility] = useState(null);
    const [searchFacilityType, setSearchFacilityType] = useState(null);
    const [status, setStatus] = useState('');
    const [editStatus, setEditStatus] = useState('');
    const [editMultipleStatus, setEditMultipleStatus] = useState('');
    const [rowId, setRowId] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    // const [filteredData, setFilteredData] = useState([]);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(50);
    const [searchFirstName, setSearchFirstName] = useState('');
    const [searchLastName, setSearchLastName] = useState('');
    const ROWS_PER_PAGE = 50;
    const [startDate, endDate] = dateRange;
    const [counts, setCounts] = useState("");
    const [search, setSearch] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [deleteBillId, setDeleteBillId] = useState("");


    const navigate = useNavigate();

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        setSelectedRows((prevSelected) =>
            isSelected
                ? prevSelected.filter((code) => code !== facilityCode)
                : [...prevSelected, facilityCode]
        );
    };

    // const handleEditClick = (event, selectedFacility) => {
    //  console.log("Click")
    // };

    const handleEditClose = () => {
        setAnchorEl(null);
    };


    // useEffect(() => {
    //     if (searchProvider !== null) {
    //         setLoading(true);
    //         const payload = null;
    //         const providerId = searchProvider?.id;

    //         dispatch(
    //             getReportByBiller(
    //                 payload,
    //                 providerId,
    //                 (data) => {
    //                     setLoading(false);
    //                     setData(data?.data);
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                     setLoading(false);
    //                 }
    //             )
    //         );
    //     }
    // }, [searchProvider, updated]);

    const formatDate = (date) => {
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${year}-${month}-${day}`;
    };

    const handleSearch = () => {
        const providerId = searchProvider?.id;

        setLoading(true)
        const payload = {
            "facility_name": searchFacility?.id,
            "first_name": searchFirstName,
            "last_name": searchLastName,
            "status": status,
            "date_from": startDate ? formatDate(startDate) : null,
            "date_to": endDate ? formatDate(endDate) : null,
            "provider_id": providerId,
        }

        dispatch(
            getReportByProvider(payload, currentPage,
                (data) => {
                    setLoading(false);
                    // setFilteredData(data?.data?.results);
                    setFilteredData(search === true ? data?.data?.results : []);
                    setCounts(data?.data?.count)
                    setSelectAll(false)
                    setSelectedRows([])
                },
                (error) => {
                    console.log(error)
                    if (error?.response?.data?.code === "token_not_valid") {
                        localStorage.clear();
                        navigate('/');
                    }
                    setLoading(false)
                }
            )
        );

        // setCurrentPage(1); 
    };


    useEffect(() => {
        handleSearch()
        setSearch(true)
    }, [updated, currentPage])


    useEffect(() => {
        // setLoading(true)
        const payload = null;
        dispatch(
            getProvidersByBillerId(payload, billerId,
                (data) => {
                    setLoading(false);
                    setProviderList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [])

    useEffect(() => {

        if (searchProvider !== null) {
            // setLoading(true);
            const payload = null;
            const providerId = searchProvider?.provider_id;
            dispatch(
                getFacilityByProviderId(
                    payload,
                    providerId,
                    (data) => {
                        console.log(data?.data?.Data)
                        setLoading(false);
                        setFacilityList(data?.data?.Data)
                    },
                    (error) => {
                        console.log(error);
                        setLoading(false);
                    }
                )
            );
        }

    }, [searchProvider])


    // useEffect(() => {
    //     if (searchProvider !== null) {
    //         setLoading(true);
    //         const payload = null;
    //         const providerId = searchFacility?.id;
    //         dispatch(
    //             getFacilityTypeByProviderId(
    //                 payload,
    //                 providerId,
    //                 (data) => {
    //                     console.log(data?.data?.Data)
    //                     setLoading(false);
    //                     setFacilityTypeList(data?.data?.Data)
    //                 },
    //                 (error) => {
    //                     console.log(error);
    //                     setLoading(false);
    //                 }
    //             )
    //         );
    //     }

    // }, [updated, searchFacility])

    const handleEditStatus = (newStatus) => {
        setLoading(true);
        const payload = {
            biller_id: billerId,
            superbiller_id: rowId,
            is_status: newStatus
        }
        dispatch(
            updateReportByBillerId(
                payload,
                (data) => {
                    setLoading(false);
                    // setData(data?.data);
                    setSnackbarMessage('Status Updated Successfully');
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    setUpdated(prevState => !prevState);
                    handleEditClose()
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    };


    const handleEditMultipleStatus = (status) => {
        // Your logic to handle the status change
        console.log(`Status changed to: ${status}`);
    };

    const handleChange = (event) => {
        const value = event.target.value;
        setEditMultipleStatus(value);
        handleMultipleEditStatus();
    };


    const handleMultipleEditStatus = (newStatus) => {
        setLoading(true);
        const payload = {
            biller_id: billerId,
            superbiller_id: selectedRows,
            is_status: newStatus
        }
        dispatch(
            updateMultipleReportByBillerId(
                payload,
                (data) => {
                    setLoading(false);
                    // setData(data?.data);
                    setSnackbarMessage('Status Updated Successfully');
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    setUpdated(prevState => !prevState);
                    setSelectedRows([])
                    setSelectAll(false)
                    setEditMultipleStatus("Edit Status")
                    handleEditClose()
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    };

    const handleDelete = (id) => {
        setLoading(true);
        const payload = {
            biller_id: billerId,
            super_biller_id: id,
            // is_status: newStatus
        }
        dispatch(
            deleteSuperBillByBiller(
                payload,
                (data) => {
                    setLoading(false);
                    // setData(data?.data);
                    setSnackbarMessage('Superbill Deleted Successfully');
                    setSnackbarSeverity("success");
                    setSnackbarOpen(true);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)
                    // setSelectedRows([])
                    // setSelectAll(false)
                    // setEditMultipleStatus("Edit Status")
                    // handleEditClose()
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    };

    const handleModalDelete = (id) => {
        setDeleteBillId(id)
        setOpenDeleteModal(true)
    };



    // const handleDelete = () => {
    //     onDelete(selectedRows);
    //     setSelectedRows([]);
    // };


    // const filteredData = data.filter((row) => {
    //     // Filter based on provider
    //     const matchesProvider = searchProvider || (typeof searchProvider === 'string' && (
    //         row.provider_profile?.First_Name.toLowerCase().includes(searchProvider.toLowerCase()) ||
    //         row.provider_profile?.Last_Name.toLowerCase().includes(searchProvider.toLowerCase())
    //     ));



    //     // Filter based on other criteria (if needed)

    //     // Return true only if all conditions match
    //     return matchesProvider 
    // });

    // useEffect(() => {
    //     setCurrentPage(1)
    // }, [searchProvider, searchFacility, searchFacility, searchFacilityType, searchFirstName, searchLastName])
    // const [filteredData, setFilteredData] = useState([]);

    // useEffect(() => {
    //     if (data && data.length !== 0) {
    //         const filtered = data.filter((row) => {
    //             console.log("abc", data);

    //             // const providerFullName = searchProvider ? searchProvider.First_Name + ' ' + searchProvider.Last_Name : '';
    //             const facilityName = searchFacility ? searchFacility.facility_name : '';

    //             const facilityType = searchFacilityType ? searchFacilityType.facility_type_Name : '';

    //             const matchesStatus = status === '' || row?.is_status.toLowerCase() === status.toLowerCase();

    //             const matchesFirstName = row.patient_name.toLowerCase().includes(searchFirstName.toLowerCase());

    //             const matchesLastName = row.last_name.toLowerCase().includes(searchLastName.toLowerCase());

    //             // const matchesProvider = !searchProvider || (row.provider_profile?.First_Name + ' ' + row.provider_profile?.Last_Name === providerFullName);
    //             const matchesFacility = !searchFacility || (row.facility_master?.facility_name === facilityName);

    //             const matchesFacilityType = !searchFacilityType || (row.facility_type?.facility_type_Name === facilityType);

    //             const serviceDate = new Date(row.date_of_service);

    //             const matchesDateRange = (!startDate && !endDate) ||
    //                 (startDate && endDate && serviceDate >= startDate && serviceDate <= endDate);

    //             return matchesFacility && matchesFacilityType && matchesStatus && matchesDateRange && matchesFirstName && matchesLastName;
    //         });

    //         setFilteredData(filtered);
    //         console.log("filteredData:", filtered);
    //     } else {
    //         setFilteredData([]);
    //     }
    // }, [data, searchFacility, searchFacilityType, status, searchFirstName, searchLastName, startDate, endDate]);

    // useEffect(() => {
    //     const Something = filteredData && filteredData.length !== 0 ? data?.filter((row) => {
    //         const facilityName = searchFacility ? searchFacility.facility_name : '';
    //         const facilityType = searchFacilityType ? searchFacilityType.facility_type_Name : '';
    //         const matchesStatus = status === '' || row?.is_status.toLowerCase() === status.toLowerCase();
    //         const matchesFacility = !searchFacility || (row.facility_master?.facility_name === facilityName);
    //         const matchesFacilityType = !searchFacilityType || (row.facility_type?.facility_type_Name === facilityType);
    //         const serviceDate = new Date(row.date_of_service);
    //         const matchesDateRange = (!startDate && !endDate) ||
    //             (startDate && endDate && serviceDate >= startDate && serviceDate <= endDate);
    //         return matchesFacility && matchesFacilityType && matchesStatus && matchesDateRange;
    //     }) : [];

    //     setFilteredData(Something);
    // }, [searchProvider, searchFacility, searchFacilityType, status, startDate, endDate, data]);

    const handleClearSearch = () => {
        setFilteredData([])
        setSearchProvider(null);
        setSearchFacility(null);
        setSearchFacilityType(null);
        setStatus("")
        setSearchFirstName("")
        setSearchLastName("")
        setDateRange([null, null])
    };

    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            const allCodes = filteredData.map((row) => row.id);
            setSelectedRows(allCodes);
            // Set all rows id in id state
            //   setId(allCodes);
        } else {
            setSelectedRows([]);
            //   setId([]);
        }
    };

    const handleEditClick = (event, row, newStatus) => {
        setRowId(row?.id);
        setEditStatus(newStatus); // Set the new status in editStatus
        setAnchorEl(event.currentTarget);
        // handleEditStatus()
    };


    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };



    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;

    const handleDownloadExcel = () => {
        if (selectAll === true) {
            setLoading(true)
            const providerId = searchProvider?.id;

            const payload = {
                "facility_name": searchFacility?.id,
                "first_name": searchFirstName,
                "last_name": searchLastName,
                "status": status,
                "date_from": startDate ? formatDate(startDate) : null,
                "date_to": endDate ? formatDate(endDate) : null,
                "provider_id": providerId,
                "download_excel": selectAll
            }

            dispatch(
                getReportByProvider(payload, currentPage,
                    (data) => {
                        setLoading(false);
                        // setData(data?.data)
                        const exportData = data?.data
                            .map(row => ({
                                'Provider': row.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name,
                                'Facility': row.facility_master?.facility_name,
                                'Facility Type': row.facility_type?.facility_type_Name,
                                // 'State': row.state?.state_Name,
                                'Patient Name': row.patient_name,
                                'Last Name': row.last_name,
                                'Date of Birth': row.dob,
                                'Date of Service': row.date_of_service,
                                'CPT Code': row.cpt_code?.visit_reason_code,
                                'DX Code': row.dx_code,
                                'Admission': row.admission,
                                'Reason for Visit': row.reason_for_visit,
                                'Comment': row.comment,
                                'Modifier': row.modifier,
                                'Status': row.is_status,
                                'Biller': row.biller_profile !== null ? row.biller_profile?.First_Name + " " + row.biller_profile?.Last_Name : "",
                                'Submitted On': row?.submitted_on,
                                'Time': row?.time,
                            }));


                        // Create a new workbook

                        // Create a new workbook
                        const wb = XLSX.utils.book_new();

                        // Convert data to worksheet
                        const ws = XLSX.utils.json_to_sheet(exportData);

                        // Convert data to worksheet
                        const columnWidths = [
                            // { wch: 20 }, // Width of Provider column
                            // { wch: 20 }, // Width of Facility column
                            { wch: 45 }, // Width of Facility Type column
                            { wch: 30 }, // Width of State column
                            { wch: 30 }, // Width of Patient Name column
                            { wch: 30 }, // Width of Last Name column
                            { wch: 30 }, // Width of Date of Birth column
                            { wch: 30 }, // Width of Date of Service column
                            { wch: 30 }, // Width of CPT Code column
                            { wch: 30 }, // Width of DX Code column
                            { wch: 30 }, // Width of Admission column
                            { wch: 30 }, // Width of Reason for Visit column
                            { wch: 30 }, // Width of Comment column
                            { wch: 30 }, // Width of Modifier column
                            { wch: 30 }, // Width of Status column
                            { wch: 30 },  // Width of Biller column
                            { wch: 30 }, // Width of Status column
                            { wch: 30 }  // Width of Biller column
                        ];

                        ws['!cols'] = columnWidths;

                        // Add worksheet to workbook
                        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

                        // Save the workbook as an Excel file
                        XLSX.writeFile(wb, 'Reports.xlsx');
                        setSelectAll(false)
                        setSelectedRows([])
                        // setFilteredData(data?.data)
                    },
                    (error) => {
                        console.log(error)
                        setLoading(false)
                    }
                )
            );
        } else {
            const exportData = filteredData?.filter(row => selectedRows.includes(row.id))
                .map(row => ({
                    'Provider': row.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name,
                    'Facility': row.facility_master?.facility_name,
                    'Facility Type': row.facility_type?.facility_type_Name,
                    // 'State': row.state?.state_Name,
                    'Patient Name': row.patient_name,
                    'Last Name': row.last_name,
                    'Date of Birth': row.dob,
                    'Date of Service': row.date_of_service,
                    'CPT Code': row.cpt_code?.visit_reason_code,
                    'DX Code': row.dx_code,
                    'Admission': row.admission,
                    'Reason for Visit': row.reason_for_visit,
                    'Comment': row.comment,
                    'Modifier': row.modifier,
                    'Status': row.is_status,
                    'Biller': row.biller_profile !== null ? row.biller_profile?.First_Name + " " + row.biller_profile?.Last_Name : "",
                    'Submitted On': row?.submitted_on,
                    'Time': row?.time,
                }));


            // Create a new workbook

            // Create a new workbook
            const wb = XLSX.utils.book_new();

            // Convert data to worksheet
            const ws = XLSX.utils.json_to_sheet(exportData);

            // Convert data to worksheet
            const columnWidths = [
                { wch: 45 }, // Width of Facility Type column
                { wch: 30 }, // Width of State column
                { wch: 30 }, // Width of Patient Name column
                { wch: 30 }, // Width of Last Name column
                { wch: 30 }, // Width of Date of Birth column
                { wch: 30 }, // Width of Date of Service column
                { wch: 30 }, // Width of CPT Code column
                { wch: 30 }, // Width of DX Code column
                { wch: 30 }, // Width of Admission column
                { wch: 30 }, // Width of Reason for Visit column
                { wch: 30 }, // Width of Comment column
                { wch: 30 }, // Width of Modifier column
                { wch: 30 }, // Width of Status column
                { wch: 30 },  // Width of Biller column
                { wch: 30 }, // Width of Status column
                { wch: 30 }  // Width of Biller column

            ];

            ws['!cols'] = columnWidths;

            // Add worksheet to workbook
            XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

            // Save the workbook as an Excel file
            XLSX.writeFile(wb, 'Reports.xlsx');
            setSelectAll(false)
            setSelectedRows([])
        }

        // Filter rows based on selectedRows array

    };

    const [anchorEl1, setAnchorEl1] = useState(null);

    const handleClick = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl1(null);
    };

    const handleMenuItemClick = (value) => {
        setEditMultipleStatus(value);
        handleMultipleEditStatus(value)
        handleClose();
    };

    return (
        <Box sx={{ px: 10, py: 2 }}>
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold' }}>Billing Report</Typography>
            <Grid container spacing={2} sx={{ py: 4 }}>
                <Grid item md={2.4} xs={6}>
                    <InputLabel id="provider-label">Provider</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchProvider}
                        onChange={(event, newValue) => {
                            setSearchProvider(newValue);
                        }}
                        // sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={providerList}
                        getOptionLabel={(option) => option.name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />
                </Grid>
                <Grid item md={2.4} xs={6}>
                    <InputLabel id="facility-name-label">Facility Name</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchFacility}
                        onChange={(event, newValue) => {
                            setSearchFacility(newValue);
                        }}
                        // sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={facilityList} // Pass the array directly
                        getOptionLabel={(option) => option.facility_name}
                        getOptionSelected={(option, value) => option.facility_name === value.facility_name}
                        isOptionEqualToValue={(option, value) => option.facility_name === value.facility_name}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px' } }}
                            />
                        )}
                    />
                </Grid>

                <Grid item md={2.4} xs={6}>
                    <InputLabel>Patient First Name</InputLabel>
                    <CustomTextField
                        value={searchFirstName}
                        setValue={setSearchFirstName}
                    />
                </Grid>

                <Grid item md={2} xs={6}>
                    <InputLabel>Patient Last Name</InputLabel>
                    <CustomTextField
                        value={searchLastName}
                        setValue={setSearchLastName}
                    />
                </Grid>


                {/* <Grid item md={3} xs={6}>
                    <InputLabel id="facility-type-label">Select Date</InputLabel>
                    <DatePicker
                        placeholder='Select Date'
                        selectsRange={true}
                        startDate={startDate}
                        endDate={endDate}
                        onChange={(update) => {
                            setDateRange(update);
                        }}
                        isClearable={true}
                        className='reactDateRangePicker'
                    />
                </Grid> */}

                <Grid item md={2.4} xs={6}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        fullWidth
                        value={status}
                        onChange={(e) => setStatus(e.target.value)}
                        sx={{ height: '32px', padding: '5px 10px', }}
                    >

                        <MenuItem value={""}>
                            All
                        </MenuItem>

                        <MenuItem value={"Pending"}>
                            Pending
                        </MenuItem>
                        <MenuItem value={"Confirmed"}>
                            Confirmed
                        </MenuItem>

                    </Select>

                </Grid>

                <Grid item md={2.4} xs={6}>

                    <InputLabel id="facility-type-label">Date From / DoS</InputLabel>
                    <DatePicker
                        // placeholderText='Select Start Date'
                        selected={startDate}
                        onChange={(date) => setDateRange([date, endDate])}
                        isClearable={true}
                        className='reactDateRangePicker3'
                        selectsStart
                        startDate={startDate}
                        endDate={endDate}
                    />
                </Grid>

                <Grid item md={2.4} xs={6}>
                    <InputLabel id="facility-type-label">Date To / DoS</InputLabel>
                    <DatePicker
                        // placeholderText='Select End Date'
                        selected={endDate}
                        onChange={(date) => setDateRange([startDate, date])}
                        isClearable={true}
                        className='reactDateRangePicker3'
                        selectsEnd
                        startDate={startDate}
                        endDate={endDate}
                        minDate={startDate}
                    />
                </Grid>
            </Grid>
            <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'right', mr: 2 }}>
                <Button
                    variant="contained"
                    className='buttonPadding'
                    onClick={handleClearSearch}
                    sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 1 }}
                >
                    Clear
                </Button>

                <Button
                    variant="contained"
                    className='buttonPadding'
                    onClick={handleSearch}
                    sx={{ background: '#0093AF !important', boxShadow: 'none !important', }}
                >
                    Search
                </Button>
            </Box>


            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress sx={{ color: '#0093AF !important' }} />
                    </Box>
                ) :
                    filteredData?.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <Box sx={{ my: 4 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                                <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 3 }}>Reports</Typography>

                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                                    {/* <Button
                                        sx={{ width: '100%' }}
                                        variant="contained"
                                        onClick={handleClick}
                                        disabled={selectedRows.length === 0}
                                    >
                                        {editMultipleStatus || 'Edit Status'}
                                    </Button> */}
                                    <FormControl sx={{ mr: 2 }}>
                                        <Button
                                            sx={{ width: '100%' }}
                                            variant="contained"
                                            onClick={handleClick}
                                            disabled={selectedRows.length === 0}
                                        >
                                            {editMultipleStatus || 'Edit Status'}
                                        </Button>
                                        <Menu
                                            id="edit-status-menu"
                                            anchorEl={anchorEl1}
                                            open={Boolean(anchorEl1)}
                                            onClose={handleClose}
                                        >
                                            {/* <MenuItem onClick={() => handleMenuItemClick('Approved')}>Approved</MenuItem> */}
                                            <MenuItem onClick={() => handleMenuItemClick('Pending')}>Pending</MenuItem>
                                            <MenuItem onClick={() => handleMenuItemClick('Confirmed')}>Confirmed</MenuItem>
                                        </Menu>
                                    </FormControl>
                                    <Button
                                        sx={{ float: 'right', boxShadow: 'none !important', width: 'fit-content' }}
                                        variant="contained"
                                        onClick={handleDownloadExcel}
                                        disabled={selectedRows.length === 0}
                                    >
                                        Download Excel
                                    </Button>
                                </Box>
                            </Box>

                            <TableContainer className='scrollBar table-container' component={Paper}>
                                <Table className='custom-table  '>
                                    <TableHead sx={{ backgroundColor: '#0093AF', }}>
                                        <TableRow>
                                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                    style={{ color: '#fff' }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Provider</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Facility</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Facility Type</TableCell>
                                            {/* <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >State</TableCell> */}
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >First Name</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Last Name</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Birth</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Service</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Date of Submission</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >CPT Code</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >DX Code</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Admission</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Reason for Visit</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Comment</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Modifier</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Status</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Biller</TableCell>
                                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }} >Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData?.map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ py: 0.5 }}>
                                                    <Checkbox
                                                        checked={selectedRows.includes(row.id)}
                                                        onChange={() => handleCheckboxChange(row.id)}
                                                        style={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_master?.facility_name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_type?.facility_type_Name}</TableCell>
                                                {/* <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.state?.state_Name}</TableCell> */}
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.patient_name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.last_name}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dob}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.date_of_service}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.submitted_on}, {row?.time}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.cpt_code?.visit_reason_code}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dx_code}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.admission}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.reason_for_visit}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.comment}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.modifier}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }} >{row?.is_status}</TableCell>
                                                <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}  >{row?.biller_profile !== null ? row?.biller_profile?.First_Name + " " + row?.biller_profile?.Last_Name : ""}</TableCell>
                                                <TableCell sx={{ display: 'flex', whiteSpace: 'nowrap' }}>
                                                    <IconButton color="primary" onClick={(e) => handleEditClick(e, row)}>
                                                        <EditIcon sx={{ color: '#0093AF', fontSize: "16px", my: 1 }} />
                                                    </IconButton>
                                                    {isDeletedAccess === "true" ? (
                                                        <IconButton color="primary" onClick={() => handleModalDelete(row.id)}>
                                                            <DeleteIcon sx={{ color: '#0093AF', fontSize: "16px" }} />
                                                        </IconButton>
                                                    ) : null}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <CustomPagination
                                count={Math.ceil(counts / ROWS_PER_PAGE)}
                                page={currentPage}
                                onChange={handleChangePage}
                            />
                        </Box>
                    )}

            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDelete}
                id={deleteBillId}
            />
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEditClose}
            >
                <MenuItem onClick={(e) => {
                    setEditStatus('Confirmed');
                    handleEditStatus('Confirmed');
                    handleEditClose(); // Close the menu after selecting the option
                }} value="Confirmed">Confirmed</MenuItem>
                <MenuItem onClick={(e) => {
                    setEditStatus('Pending');
                    handleEditStatus('Pending');
                    handleEditClose(); // Close the menu after selecting the option
                }} value="Pending">Pending</MenuItem>
                {/* <MenuItem onClick={(e) => {
                    setEditStatus('Rejected');
                    handleEditStatus('Rejected');
                    handleEditClose(); // Close the menu after selecting the option
                }} value="Rejected">Rejected</MenuItem> */}
            </Menu>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default BillerReportsBody;
