import React from 'react';
import { useState } from 'react';
import Layout from '../../Layout';
import BillerBody from './Components/BillerBody';
import AdminsBody from './Components/AdminsBody';


export default function Biller() {

    
    return (
        <Layout>
            <AdminsBody/>
        </Layout>
    )
}