import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Button,
    AppBar,
    Toolbar,
    IconButton,
    Drawer,
    Hidden,
    Collapse,
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import GiteIcon from '@mui/icons-material/Gite';
import MapIcon from '@mui/icons-material/Map';
import SummarizeIcon from '@mui/icons-material/Summarize';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import LogoutIcon from '@mui/icons-material/Logout';
import Logo from '../Image/Logo.png';

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [openMasters, setOpenMasters] = useState(false);



    const Routes = [
        {
            name: 'Dashboard',
            icon: <DashboardIcon style={{ width: 24, height: 24 }} />,
            link: '/dashboard'
        },
        {
            name: 'Masters',
            icon: <AcUnitIcon style={{ width: 24, height: 24 }} />,
            link: '/',
            submenu: [
                { name: 'State Master', link: '/state-master' },
                { name: 'Facility Type Master', link: '/facility-type-master' },
                { name: 'Reason for visit Master', link: '/visit-reason' },
            ]
        },
        {
            name: 'Facility/Clinics',
            icon: <GiteIcon style={{ width: 24, height: 24 }} />,
            link: '/facility'
        },
        {
            name: 'Billers',
            icon: <AccountBalanceWalletIcon style={{ width: 24, height: 24 }} />,
            link: '/biller'
        },
        {
            name: 'Provider',
            icon: <LocalHospitalIcon style={{ width: 24, height: 24 }} />,
            link: '/provider'
        },
        {
            name: 'Biller Provider Mapping',
            icon: <MapIcon style={{ width: 24, height: 24 }} />,
            link: '/'
        },
        {
            name: 'Report',
            icon: <SummarizeIcon style={{ width: 24, height: 24 }} />,
            link: '/'
        },
        {
            name: 'Admin User',
            icon: <AdminPanelSettingsIcon style={{ width: 24, height: 24 }} />,
            link: '/'
        },
    ];

    // useEffect(() => {
    //     // Check if pathname matches any submenu link
    //     const isSubitemLink = Routes.some(route =>
    //         route.submenu && route.submenu.some(subitem => subitem.link === pathname)
    //     );
    //     setOpenMasters(isSubitemLink);
    // }, [pathname, Routes]);

    const handleLinkClick = (link) => {
        navigate(link);
    };

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleLogout = () => {
        localStorage.clear();
        navigate('/');
    };

    const handleMastersClick = () => {
        setOpenMasters((prevOpenMasters) => !prevOpenMasters);
    };

    return (
        <Box>
            {/* Desktop View */}
            <Hidden mdDown>
                <Box
                    sx={{
                        height: "-webkit-fill-available",
                        pt: 5,
                        position: 'fixed',
                        boxShadow: '2px 0px 5px rgba(0, 0, 0, 0.1)',
                        background: '#f8f9fa',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        pb: 5
                    }}
                >
                    <Box>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', pb: 5, color: '#0093AF', fontWeight: 'bold', verticalAlign: 'middle' }}>
                            <img src={Logo} height={"50px"} width={"50px"} />
                            <Typography variant='h6' className='fs20' sx={{ fontWeight: 'bold' }}>Billing Portal</Typography>
                        </Box>

                        {Routes.map((route, index) => (
                            <Box key={index} style={{ cursor: 'pointer' }}>
                                {route.submenu ? (
                                    <>
                                        <Button
                                            onClick={handleMastersClick}
                                            sx={{
                                                textAlign: 'left',
                                                backgroundColor: pathname === "" ? '#0093AF' : 'transparent',
                                                color: pathname === "" ? '#fff' : '#0093AF',
                                                textAlign: 'left',
                                                width: '80%',
                                                mx: 3,
                                                alignItems: 'left',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                my: 1.2,
                                                py: 1,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#0093AF',
                                                    color: '#fff',
                                                },
                                            }}
                                        >
                                            {route.icon}
                                            {/* <Box sx={{display:'flex', justifyContent:"space-between", alignItems:'center'}}> */}
                                                <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                                                {openMasters ? <ExpandLess sx={{ml:8}} /> : <ExpandMore sx={{ml:8}} />}
                                            {/* </Box> */}
                                        </Button>
                                        <Collapse in={openMasters} timeout="auto" >
                                            <Box sx={{ pl: 3 }}>
                                                {route.submenu.map((subitem, index) => (
                                                    <Button
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: pathname === subitem.link ? '#0093AF' : 'transparent',
                                                            color: pathname === subitem.link ? '#fff' : '#0093AF',
                                                            textAlign: 'left',
                                                            width: '80%',
                                                            mx: 3,
                                                            alignItems: 'left',
                                                            display: 'flex',
                                                            justifyContent: 'left',
                                                            my: 1.2,
                                                            py: 1,
                                                            fontWeight: 'bold',
                                                            '&:hover': {
                                                                backgroundColor: '#0093AF',
                                                                color: '#fff',
                                                            },
                                                            textTransform: 'none'
                                                        }}
                                                        onClick={() => handleLinkClick(subitem.link)}
                                                        key={index}
                                                    >
                                                        <Typography
                                                            className="fs16"
                                                        >
                                                            {subitem.name}
                                                        </Typography>
                                                    </Button>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </>
                                ) : (
                                    <Link to={route.link} >
                                        <Button
                                            sx={{
                                                textAlign: 'left',
                                                backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                                                color: pathname === route.link ? '#fff' : '#0093AF',
                                                textAlign: 'left',
                                                width: '80%',
                                                mx: 3,
                                                alignItems: 'left',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                my: 1.2,
                                                py: 1,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#0093AF',
                                                    color: '#fff',
                                                },
                                            }}
                                        >
                                            {route.icon}
                                            <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                                        </Button>
                                    </Link>
                                )}
                            </Box>
                        ))}
                    </Box>

                    <Button
                        sx={{
                            textAlign: 'left',
                            backgroundColor: '#0093AF',
                            color: '#fff',
                            textAlign: 'left',
                            width: '80%',
                            mx: 3,
                            alignItems: 'left',
                            display: 'flex',
                            justifyContent: 'left',
                            py: 1,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#0093AF',
                                color: '#fff',
                            },
                        }}
                        onClick={() => handleLogout()}
                    >
                        <LogoutIcon style={{ width: 24, height: 24 }} />
                        <Typography className="fs16" sx={{ px: 2, textTransform: 'none' }}>Logout</Typography>
                    </Button>
                </Box>
            </Hidden>

            {/* Mobile and Tab View */}
            <Hidden mdUp>
                <AppBar position="static" sx={{ width: '100vw', background: '#f8f9fa', }}>
                    <Toolbar>
                        <IconButton onClick={toggleDrawer} color="inherit" aria-label="menu">
                            <MenuIcon sx={{ color: '#0093AF' }} />
                        </IconButton>
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#0093AF', fontWeight: 'bold', verticalAlign: 'middle', mx: "auto" }}>
                            <img src={Logo} height={"30px"} width={"30px"} />
                            <Typography variant='subtitle' sx={{ fontWeight: 'bold' }}>Billing Portal</Typography>
                        </Box>
                    </Toolbar>
                </AppBar>

                <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer}>
                    <Box sx={{ py: 5 }}>
                        {Routes.map((route, index) => (
                            <Box key={index} onClick={() => handleLinkClick(route.link)} style={{ cursor: 'pointer' }}>
                                {route.submenu ? (
                                    <>
                                        <Button
                                            onClick={handleMastersClick}
                                            sx={{
                                                textAlign: 'left',
                                                backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                                                color: pathname === route.link ? '#fff' : '#0093AF',
                                                textAlign: 'left',
                                                width: '80%',
                                                mx: 3,
                                                alignItems: 'left',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                my: 1.2,
                                                py: 1,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#0093AF',
                                                    color: '#fff',
                                                },
                                            }}
                                        >
                                            {route.icon}
                                            <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                                            {openMasters ? <ExpandLess /> : <ExpandMore />}
                                        </Button>
                                        <Collapse in={openMasters} timeout="auto" unmountOnExit>
                                            <Box sx={{ pl: 3 }}>
                                                {route.submenu.map((subitem, index) => (
                                                    <Button
                                                        sx={{
                                                            textAlign: 'left',
                                                            backgroundColor: pathname === subitem.link ? '#0093AF' : 'transparent',
                                                            color: pathname === subitem.link ? '#fff' : '#0093AF',
                                                            textAlign: 'left',
                                                            width: '80%',
                                                            mx: 3,
                                                            alignItems: 'left',
                                                            display: 'flex',
                                                            justifyContent: 'left',
                                                            my: 1.2,
                                                            py: 1,
                                                            fontWeight: 'bold',
                                                            '&:hover': {
                                                                backgroundColor: '#0093AF',
                                                                color: '#fff',
                                                            },
                                                            textTransform: 'none'
                                                        }}
                                                        onClick={() => handleLinkClick(subitem.link)}
                                                        key={index}
                                                    >
                                                        <Typography
                                                            className="fs16"
                                                        >
                                                            {subitem.name}
                                                        </Typography>
                                                    </Button>
                                                ))}
                                            </Box>
                                        </Collapse>
                                    </>
                                ) : (
                                    <Link to={route.link} >
                                        <Button
                                            sx={{
                                                textAlign: 'left',
                                                backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                                                color: pathname === route.link ? '#fff' : '#0093AF',
                                                textAlign: 'left',
                                                width: '80%',
                                                mx: 3,
                                                alignItems: 'left',
                                                display: 'flex',
                                                justifyContent: 'left',
                                                my: 1.2,
                                                py: 1,
                                                fontWeight: 'bold',
                                                '&:hover': {
                                                    backgroundColor: '#0093AF',
                                                    color: '#fff',
                                                },
                                            }}
                                        >
                                            {route.icon}
                                            <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                                        </Button>
                                    </Link>
                                )}
                            </Box>
                        ))}
                    </Box>

                    <Button
                        sx={{
                            textAlign: 'left',
                            backgroundColor: '#0093AF',
                            color: '#fff',
                            textAlign: 'left',
                            width: '80%',
                            mx: 3,
                            alignItems: 'left',
                            display: 'flex',
                            justifyContent: 'left',
                            py: 1,
                            fontWeight: 'bold',
                            '&:hover': {
                                backgroundColor: '#0093AF',
                                color: '#fff',
                            },
                        }}
                        onClick={() => handleLogout()}
                    >
                        <LogoutIcon style={{ width: 24, height: 24 }} />
                        <Typography className="fs16" sx={{ px: 2, textTransform: 'none' }}>Logout</Typography>
                    </Button>
                </Drawer>
            </Hidden>
        </Box>
    );
};

export default Sidebar;
