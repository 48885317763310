import React from 'react';
import { useState } from 'react';
import BillerLayout from './Component/BillerLayout';
import BillerReportsBody from './Component/BillerReportBody';


export default function BillerReport() {

   
    return (
        <BillerLayout>
            <BillerReportsBody  />
        </BillerLayout>
    )
}