const allDxCodes = [
    { "Code": "F03.90", "Description": "Unspecified dementia without behavioral disturbance" },
    { "Code": "F03.91", "Description": "Unspecified dementia with behavioral disturbance" },
    { "Code": "F19.20", "Description": "Other psychoactive substance dependence, uncomplicated" },
    { "Code": "B00.9", "Description": "Herpesviral infection, unspecified" },
    { "Code": "D19.0", "Description": "Benign neoplasm of mesothelial tissue of pleura" },
    { "Code": "D64.9", "Description": "Anemia, Unspecified" },
    { "Code": "E03.9", "Description": "Hypothyroidism, unspecified" },
    { "Code": "E11.9", "Description": "Type 2 diabetes mellitus without complications" },
    { "Code": "E16.2", "Description": "Hypoglycemia, unspecified" },
    { "Code": "E66.01", "Description": "Morbid (severe) obesity from excess calories" },
    { "Code": "E66.9", "Description": "Obesity, unspecified" },
    { "Code": "E75.6", "Description": "Lipid storage disorder, unspecified" },
    { "Code": "E78.0", "Description": "Pure hypercholesterolemia" },
    { "Code": "E78.00", "Description": "Pure hypercholesterolemia, unspecified" },
    { "Code": "E78.01", "Description": "Familial hypercholesterolemia" },
    { "Code": "E78.5", "Description": "Hyperlipidemia, unspecified" },
    { "Code": "F01.50", "Description": "Vascular dementia, unspecified severity, without behavioral disturbance, psychotic disturbance, mood" },
    { "Code": "F01.51", "Description": "Vascular dementia with behavioral disturbance" },
    { "Code": "F01.52", "Description": "Vascular dementia, unspecified severity, with psychotic disturbance" },
    { "Code": "F02.80", "Description": "Dementia in other diseases classified elsewhere without behavioral disturbance" },
    { "Code": "F02.81", "Description": "Dementia in other diseases classified elsewhere with behavioral disturbance" },
    { "Code": "F05", "Description": "Delirium due to known physiological condition" },
    { "Code": "F05.9", "Description": "Delirium, unspecified" },
    { "Code": "F06.2", "Description": "Psychotic disorder with delusions due to known physiological condition" },
    { "Code": "F06.30", "Description": "Psychotic disorder with delusions due to known physiological condition" },
    { "Code": "F06.33", "Description": "Mood disorder due to known physiological condition with manic features" },
    { "Code": "F06.71", "Description": "Mild neurocognitive disorder due to known physiological condition with behavioral disturbance" },
    { "Code": "F06.8", "Description": "Other specified mental disorders due to brain damage and dysfunction and to physical disease" },
    { "Code": "F09", "Description": "Unspecified mental disorder due to known physiological condition" },
    { "Code": "F10.10", "Description": "Alcohol abuse, uncomplicated" },
    { "Code": "F10.20", "Description": "Alcohol dependence, uncomplicated" },
    { "Code": "F10.231", "Description": "Alcohol dependence with withdrawal delirium" },
    { "Code": "F10.24", "Description": "Alcohol dependence with alcohol-induced mood disorder" },
    { "Code": "F10.288", "Description": "Alcohol dependence with other alcohol-induced disorder" },
    { "Code": "F11.20", "Description": "Opioid dependence, uncomplicated" },
    { "Code": "F12.10", "Description": "Cannabis abuse, uncomplicated" },
    { "Code": "F12.20", "Description": "Cannabis dependence, uncomplicated" },
    { "Code": "F14.20", "Description": "Cocaine dependence, uncomplicated" },
    { "Code": "F14.259", "Description": "Cocaine dependence with cocaine-induced psychotic disorder, unspecified" },
    { "Code": "F15.20", "Description": "Other stimulant dependence, uncomplicated" },
    { "Code": "F15.24", "Description": "Other stimulant dependence with stimulant-induced mood disorder" },
    { "Code": "F15.259", "Description": "Other stimulant dependence with stimulant-induced psychotic disorder, unspecified" },
    { "Code": "F16.259", "Description": "Hallucinogen dependence with hallucinogen-induced psychotic disorder, unspecified" },
    { "Code": "F20.0", "Description": "Paranoid schizophrenia" },
    { "Code": "F20.9", "Description": "Schizophrenia, unspecified" },
    { "Code": "F23", "Description": "Acute and transient psychotic disorders" },
    { "Code": "F23.3", "Description": "Other acute predominantly delusional psychotic disorders" },
    { "Code": "F25.0", "Description": "Schizoaffective disorder, bipolar type" },
    { "Code": "F25.1", "Description": "Schizoaffective disorder, depressive type" },
    { "Code": "F25.9", "Description": "Schizoaffective disorder, unspecified" },
    { "Code": "F28", "Description": "Other psychotic disorder not due to a substance or known physiological condition" },
    { "Code": "F29", "Description": "Unspecified psychosis not due to a substance or known physiological condition" },
    { "Code": "F30.2", "Description": "Manic episode, severe with psychotic symptoms" },
    { "Code": "F30.3", "Description": "Manic episode in partial remission" },
    { "Code": "F300.0", "Description": "Anxiety state, unspecified" },
    { "Code": "F31.10", "Description": "Bipolar disorder, current episode manic without psychotic features, unspecified" },
    { "Code": "F31.12", "Description": "Bipolar disorder, current episode manic without psychotic features, moderate" },
    { "Code": "F31.13", "Description": "Bipolar disorder, current episode manic without psychotic features, severe" },
    { "Code": "F31.2", "Description": "Bipolar disorder, current episode manic severe with psychotic features" },
    { "Code": "F31.32", "Description": "Bipolar disorder, current episode depressed, moderate" },
    { "Code": "F31.4", "Description": "Bipolar affective disorder, current episode severe depression without psychotic symptoms" },
    { "Code": "F31.5", "Description": "Bipolar disorder, current episode depressed, severe, with psychotic features" },
    { "Code": "F31.60", "Description": "Bipolar disorder, current episode mixed, unspecified" },
    { "Code": "F31.64", "Description": "Bipolar disorder, current episode mixed, severe, with psychotic features" },
    { "Code": "F31.81", "Description": "Bipolar II disorder" },
    { "Code": "F31.89", "Description": "Other bipolar disorder" },
    { "Code": "F31.9", "Description": "Bipolar disorder, unspecified" },
    { "Code": "F32.0", "Description": "Major depressive disorder, single episode, mild" },
    { "Code": "F32.1", "Description": "Major Depressive Disorder, Single Episode, Moderate" },
    { "Code": "F32.2", "Description": "Major depressive disorder, single episode, severe without psychotic features" },
    { "Code": "F32.3", "Description": "Major depressive disorder, single episode, severe with psychotic features" },
    { "Code": "F32.9", "Description": "Major Depressive Disorder, Single Episode, Unspecified" },
    { "Code": "F32.A", "Description": "Depression, unspecified" },
    { "Code": "F33..0", "Description": "Major Depressive Disorder, Recurrent, Mild" },
    { "Code": "F33.1", "Description": "Major Depressive Disorder (MDD), Recurrent, Moderate" },
    { "Code": "F33.2", "Description": "Major depressive disorder, recurrent severe without psychotic features" },
    { "Code": "F33.3", "Description": "Recurrent depressive disorder, current episode severe with psychotic symptoms" },
    { "Code": "F33.41", "Description": "Major depressive disorder, recurrent, in partial remission" },
    { "Code": "F33.9", "Description": "Major depressive disorder, recurrent, unspecified" },
    { "Code": "F34.1", "Description": "Dysthymic disorder" },
    { "Code": "F34.8", "Description": "Other persistent mood disorders" },
    { "Code": "F34.9", "Description": "Persistent mood [affective] disorder, unspecified" },
    { "Code": "F39", "Description": "Unspecified mood [affective] disorder" },
    { "Code": "F41.1", "Description": "Generalized anxiety disorder" },
    { "Code": "F43.10", "Description": "Post-Traumatic Stress Disorder, Unspecified" },
    { "Code": "F43.11", "Description": "Post-traumatic stress disorder, acute" },
    { "Code": "F43.12", "Description": "Post-traumatic stress disorder, chronic" },
    { "Code": "F43.20", "Description": "Adjustment disorder, unspecified" },
    { "Code": "F43.21", "Description": "Adjustment disorder with depressed mood" },
    { "Code": "F43.22", "Description": "Adjustment disorder with anxiety" },
    { "Code": "F43.23", "Description": "Adjustment disorder with mixed anxiety and depressed mood" },
    { "Code": "F43.24", "Description": "Adjustment disorder with disturbance of conduct" },
    { "Code": "F43.25", "Description": "Adjustment disorder with mixed disturbance of emotions and conduct" },
    { "Code": "F43.8", "Description": "Other reactions to severe stress" },
    { "Code": "F44.89", "Description": "Other dissociative and conversion disorders" },
    { "Code": "F45.8", "Description": "Other somatoform disorders" },
    { "Code": "F45.9", "Description": "Somatoform disorder, unspecified" },
    { "Code": "F48.1", "Description": "Depersonalization-derealization syndrome" },
    { "Code": "F48.2", "Description": "Pseudobulbar affect" },
    { "Code": "F50.02", "Description": "Anorexia nervosa, restricting type" },
    { "Code": "F50.9", "Description": "Eating disorder, unspecified" },
    { "Code": "F52.21", "Description": "Male hypoactive sexual desire disorder" },
    { "Code": "F52.22", "Description": "Female sexual arousal disorder" },
    { "Code": "F52.6", "Description": "Sexual aversion disorder" },
    { "Code": "F53.0", "Description": "Postpartum depression" },
    { "Code": "F54", "Description": "Psychological and behavioral factors associated with disorders or diseases classified elsewhere" },
    { "Code": "F59", "Description": "Unspecified behavioral syndromes associated with physiological disturbances and physical factors" },
    { "Code": "F60.3", "Description": "Borderline personality disorder" },
    { "Code": "F60.81", "Description": "Narcissistic personality disorder" },
    { "Code": "F60.89", "Description": "Other specific personality disorders" },
    { "Code": "F60.9", "Description": "Personality disorder, unspecified" },
    { "Code": "F63.81", "Description": "Intermittent explosive disorder" },
    { "Code": "F63.9", "Description": "Impulse disorder, unspecified" },
    { "Code": "F64.9", "Description": "Gender identity disorder, unspecified" },
    { "Code": "F68.10", "Description": "Factitious disorder, unspecified" },
    { "Code": "F68.13", "Description": "Factitious disorder with predominantly psychological signs and symptoms" },
    { "Code": "F70", "Description": "Mild intellectual disabilities" },
    { "Code": "F71", "Description": "Moderate intellectual disabilities" },
    { "Code": "F72", "Description": "Severe intellectual disabilities" },
    { "Code": "F73", "Description": "Profound intellectual disabilities" },
    { "Code": "F78", "Description": "Other intellectual disabilities" },
    { "Code": "F79", "Description": "Unspecified intellectual disabilities" },
    { "Code": "F80.2", "Description": "Mixed receptive-expressive language disorder" },
    { "Code": "F80.89", "Description": "Other developmental disorders of speech and language" },
    { "Code": "F80.9", "Description": "Developmental disorder of speech and language, unspecified" },
    { "Code": "F81.9", "Description": "Developmental disorder of scholastic skills, unspecified" },
    { "Code": "F82", "Description": "Specific developmental disorder of motor function" },
    { "Code": "F84.0", "Description": "Autistic disorder" },
    { "Code": "F84.3", "Description": "Other childhood disintegrative disorder" },
    { "Code": "F84.9", "Description": "Pervasive developmental disorder, unspecified" },
    { "Code": "F88", "Description": "Other disorders of psychological development" },
    { "Code": "F89", "Description": "Unspecified disorder of psychological development" },
    { "Code": "F90.0", "Description": "Attention-deficit hyperactivity disorder, predominantly inattentive type" },
    { "Code": "F90.1", "Description": "Attention-deficit hyperactivity disorder, predominantly hyperactive type" },
    { "Code": "F90.2", "Description": "Attention-deficit hyperactivity disorder, combined type" },
    { "Code": "F91.9", "Description": "Conduct disorder, unspecified" },
    { "Code": "F94.8", "Description": "Other childhood emotional disorders" },
    { "Code": "F95.0", "Description": "Transient tic disorder" },
    { "Code": "F95.1", "Description": "Chronic motor or vocal tic disorder" },
    { "Code": "F95.2", "Description": "Tourette's disorder" },
    { "Code": "F98.8", "Description": "Other specified behavioral and emotional disorders with onset usually occurring in childhood and adolescence" },
    { "Code": "F99", "Description": "Mental disorder, not otherwise specified" },
    { "Code": "F41.9", "Description": "Anxiety disorder, unspecified" },
    { "Code": "F42.9", "Description": "Obsessive-compulsive disorder, unspecified." },
    { "Code": "F44.7", "Description": "Conversion disorder with mixed symptom presentation" },
    { "Code": "F60.0", "Description": "Paranoid personality disorder" },
    { "Code": "F98.9", "Description": "Unspecified behavioral and emotional disorders with onset usually occurring in childhood and adolesc" },
    { "Code": "G25.0", "Description": "Essential tremor" },
    { "Code": "G30.9", "Description": "Alzheimer disease, unspecified." },
    { "Code": "G31.01", "Description": "Pick's disease" },
    { "Code": "G31.84", "Description": "Mild cognitive impairment, so stated" },
    { "Code": "G31.9", "Description": "Degenerative disease of nervous system, unspecified" },
    { "Code": "G43.909", "Description": "Migraine, Unspecified, not Intractable, without Status Migrainosus" },
    { "Code": "G47.00", "Description": "Anxiety Insomnia, Unspecified" },
    { "Code": "G47.09", "Description": "Other insomnia" },
    { "Code": "G81.94", "Description": "Hemiplegia, unspecified," },
    { "Code": "G89.4", "Description": "Chronic pain syndrome" },
    { "Code": "H54.8", "Description": "Legal blindness usa" },
    { "Code": "I10", "Description": "Essential (primary) hypertension" },
    { "Code": "I25.10", "Description": "Atherosclerotic heart disease of native coronary artery without angina pectoris" },
    { "Code": "I48", "Description": "Atrial fibrillation and flutter" },
    { "Code": "I48.20", "Description": "Chronic atrial fibrillation, unspecified" },
    { "Code": "I48.91", "Description": "Unspecified atrial fibrillation" },
    { "Code": "I63.9", "Description": "Cerebral infarction, unspecified" },
    { "Code": "I70", "Description": "Atherosclerosis" },
    { "Code": "J44.9", "Description": "Chronic obstructive pulmonary disease, unspecified" },
    { "Code": "J45", "Description": "Asthma" },
    { "Code": "J45.909", "Description": "Unspecified asthma, uncomplicated" },
    { "Code": "J96.00", "Description": "Acute respiratory failure, not elsewhere classified Type 1 [with hypoxia]" },
    { "Code": "K21.0", "Description": "Gastro-esophageal reflux disease with esophagitis." },
    { "Code": "M19.90", "Description": "Unspecified osteoarthritis, unspecified site" },
    { "Code": "M34", "Description": "Systemic sclerosis" },
    { "Code": "M34.89", "Description": "Other systemic sclerosis" },
    { "Code": "M47.816", "Description": "Spondylosis without myelopathy or radiculopathy, lumbar region" },
    { "Code": "M48.00", "Description": "Spinal stenosis, site unspecified" },
    { "Code": "M54.50", "Description": "Low back pain, unspecified" },
    { "Code": "M79.7", "Description": "Fibromyalgia" },
    { "Code": "M81.0", "Description": "Osteoporosis unspecified" },
    { "Code": "M85.80", "Description": "Other specified disorders of bone density and structure, unspecified site." },
    { "Code": "N32.81", "Description": "Overactive bladder" },
    { "Code": "N40.1", "Description": "Benign prostatic hyperplasia" },
    { "Code": "R09.02", "Description": "Hypoxemia" },
    { "Code": "R13.12", "Description": "Dysphagia, oropharyngeal phase" },
    { "Code": "R25.1", "Description": "Tremor, unspecified." },
    { "Code": "R39.2", "Description": "extrarenal uremia" },
    { "Code": "R41.841", "Description": "Cognitive communication deficit" },
    { "Code": "R41.9", "Description": "Unspecified symptoms and signs involving cognitive functions and awareness" },
    { "Code": "R45.6", "Description": "Violent behavior" },
    { "Code": "R45.851", "Description": "Suicidal ideations" },
    { "Code": "Z01.810", "Description": "Encounter for preprocedural cardiovascular examination" },
    { "Code": "Z01.818", "Description": "Encounter for other preprocedural examination" },
    { "Code": "Z71.9", "Description": "Counseling, unspecified" }

]

export default allDxCodes;


