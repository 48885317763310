import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Modal,
    Select,
    Autocomplete,
    InputLabel,
    CircularProgress,
    ListItemText,
    FormControlLabel
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../../Utils/CustomTextfield';
import { useDispatch } from 'react-redux';
import { addBillerProvider, deleteBillerProvider, getAllBillers, getAllProviders, getBillerProvider, updateBillerProvider } from '../../../api/action';
import CustomSnackbar from '../../../Utils/CustomSnackbar';
import DeleteModal from '../../../Utils/DeleteModal';
import CustomPagination from '../../../Utils/CustomPagination';

const BillerProviderBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    const [searchFacilityType, setSearchFacilityType] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newStateName, setNewStateName] = useState('');
    const [newStateCode, setNewStateCode] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [providerType, setProviderType] = useState('Provider Type');
    const [lastName, setLastName] = useState('');
    const [facilityList, setFacilityList] = useState([]);
    // const [providerList, setProviderList] = useState(null);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editStateName, setEditStateName] = useState('');
    const [editLastName, setEditLastName] = useState('');
    const [editStateCode, setEditStateCode] = useState('');
    const [editEmail, setEditEmail] = useState('');
    const [editPhone, setEditPhone] = useState('');
    const [editPassword, setEditPassword] = useState('');
    const [loading, setLoading] = useState('');
    const [billers, setBillers] = useState(null);
    const [editBiller, setEditBiller] = useState(null);
    const [billerList, setBillerList] = useState([]);
    const [providers, setProviders] = useState([]);
    const [editProvider, setEditProvider] = useState([]);
    const [providerList, setProviderList] = useState([]);
    const [updated, setUpdated] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [searchBiller, setSearchBiller] = useState(null);
    const [searchProvider, setSearchProvider] = useState(null);
    const [data, setData] = useState([]);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const ROWS_PER_PAGE = 10;
    const userRole = localStorage.getItem("userRole")
    const dispatch = useDispatch();

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getBillerProvider(payload,
                (data) => {
                    console.log(data)
                    setLoading(false);
                    setData(data?.data?.relation_data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllProviders(payload,
                (data) => {
                    setLoading(false);
                    setProviderList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllBillers(payload,
                (data) => {
                    setLoading(false);
                    setBillerList(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [])

    const providerIds = providers.map(provider => provider.user_id);


    const handleAddBillerProviders = (e) => {
        e.preventDefault();

        const abc = parseInt(providers)

        const payload = {
            biller_id: parseInt(billers?.user_id),
            providers_ids: providerIds

        };

        console.log(selectAll)

        setLoading(true);
        dispatch(
            addBillerProvider(
                payload,
                (data) => {
                    setLoading(false);
                    setBillers(null)
                    setProviders([])
                    setUpdated(prevState => !prevState)

                    setSnackbarMessage("Biller Provider relationship added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setShowModal(false)
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteBillerProvider = (row) => {
        const payload = {
            biller_ids: [row.biller_id],
        };

        setLoading(true);
        dispatch(
            deleteBillerProvider(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Billier Provider mapping deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    // setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)


                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateBillerProviders = (e) => {
        e.preventDefault();

        const providerIds = editProvider.map(provider => provider.user_id);

        const payload = {
            biller_id: editBiller.user_id,
            provider_ids: providerIds
        };

        console.log(payload)

        setLoading(true);
        dispatch(
            updateBillerProvider(
                payload,
                (data) => {
                    setLoading(false);
                    // setEditBiller(null)
                    // setEditProvider([])
                    setUpdated(prevState => !prevState)

                    setSnackbarMessage("Biller Provider relationship updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false)
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const options = [
        { title: 'Option 1' },
        { title: 'Option 2' },
        { title: 'Option 3' },
        { title: 'Option 4' },
    ];

    const handleOpenEditModal = (selectedRow) => {
        console.log(selectedRow)
        const selectedBillerName = selectedRow.name;

        const matchedBiller = billerList.find(biller => biller.First_Name + " " + biller.Last_Name === selectedBillerName);

        if (matchedBiller) {
            setEditBiller(matchedBiller);
        } else {

            console.log("No matching biller found in the list");
        }

        const { providers } = selectedRow;
        const providerIds = providers.map(provider => provider.id);
        const matchedProviders = providerList.filter(provider => providerIds.includes(provider.user_id));

        if (matchedProviders.length > 0) {
            setEditProvider(matchedProviders);
            console.log("No matching providers found in the list");
        }

        setEditModalOpen(true);
    };
    
    const handleDelete = () => {
        const payload = {
            biller_ids: selectedRows,
        };

        setLoading(true);
        dispatch(
            deleteBillerProvider(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Billier Provider mapping deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    // setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)


                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );

    };

    const handleAddNew = () => {
        setShowModal(true);
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [searchBiller, searchProvider])


    const filteredData = data && data.length !== 0 ? data?.filter((row) => {
        if (searchBiller && searchProvider) {
            return (
                row.name === searchBiller.First_Name + ' ' + searchBiller.Last_Name &&
                row.providers.some(provider => provider.name === searchProvider.First_Name + ' ' + searchProvider.Last_Name)
            );
        } else if (searchBiller) {
            return row.name === searchBiller.First_Name + ' ' + searchBiller.Last_Name;
        } else if (searchProvider) {
            return row.providers.some(provider => provider.name === searchProvider.First_Name + ' ' + searchProvider.Last_Name);
        } else {
            return true;
        }
    }) : [];


    const handleClearSearch = () => {
        setSearchBiller(null);
        setSearchProvider(null);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setNewStateName('');
        setNewStateCode('');
    };



    const handleSaveEditedState = () => {

        setShowModal(false);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };


    const handleCheckboxChange = (rowId) => {
        const selectedIndex = selectedRows.indexOf(rowId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, rowId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1)
            );
        }

        setSelectedRows(newSelected);
        setSelectAll(newSelected.length === data.length);
    };

    const handleSelectAll = (event) => {
        const selectedCodes = event.target.checked ? data.map((row) => row.biller_id) : [];
        setSelectedRows(selectedCodes);
        setSelectAll(event.target.checked);
    };

    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    const endIndex = startIndex + ROWS_PER_PAGE;


    return (
        <Box sx={{ px: 10, py: 2 }}>

            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteBillerProvider}
            // id={id}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>Biller Provider Mapping</Typography>
            <Grid container spacing={5} sx={{ pb: 3 }}>
                <Grid item md={4} xs={6}>

                    <InputLabel>Billers</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchBiller}
                        onChange={(event, newValue) => {
                            setSearchBiller(newValue);
                        }}
                        sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={billerList}
                        getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />

                </Grid>
                <Grid item md={4} xs={6}>
                    <InputLabel>Provider List</InputLabel>
                    <Autocomplete
                        className='scrollBar'
                        fullWidth
                        value={searchProvider}
                        onChange={(event, newValue) => {
                            setSearchProvider(newValue);
                        }}
                        sx={{ width: '15vw !important' }}
                        clearIcon={null}
                        options={providerList}
                        getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}
                        getOptionSelected={(option, value) => option.id === value.id}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                sx={{ '& input': { height: '0px', fontSize: '14px', } }}
                            />
                        )}
                    />

                </Grid>
                <Grid item md={4} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'end', pt: 3 }}>
                        <Button
                            variant="contained"
                            className='buttonPadding'
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', ml: 1 }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={userRole !== "Admin"}
                            variant="contained"
                            className='buttonPadding'
                            onClick={handleAddNew}
                            sx={{ background: userRole !== "Admin" ? "#d3d3d3" : '#0093AF !important', boxShadow: 'none !important', mx: 1 }}
                        >
                            Add New
                        </Button>

                        {/* <Button

                            variant="contained"
                            className='buttonPadding'
                            onClick={handleDelete}
                            disabled={selectedRows.length === 0}
                            sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', ml: 1 }}
                        >
                            Delete
                        </Button> */}
                    </Box>


                </Grid>


            </Grid>



            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                       <CircularProgress sx={{color:'#0093AF !important'}} />
                    </Box>
                ) : filteredData.length === 0 ? (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15 }}>
                        No Data Available
                    </Typography>
                ) : (
                    <>
                        <TableContainer component={Paper} className='scrollBar'>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                    <TableRow>
                                        <TableCell sx={{ py: 0, color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', verticalAlign: 'middle', width: '10px' }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                                style={{ color: '#fff' }}
                                            />
                                            {/* <Typography>Select All</Typography> */}
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Biller Name</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, textAlign: 'center', whiteSpace: 'nowrap' }}>Provider Name</TableCell>

                                        <TableCell sx={{ color: '#fff', py: 0, textAlign: 'center', whiteSpace: 'nowrap' }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.slice(startIndex, endIndex).map((row, index) => (
                                        <TableRow key={index} sx={{ heigh: 'fit-content' }}>
                                            <TableCell sx={{ py: 0.3, width: '10px' }}>
                                                <Checkbox
                                                    size='small'
                                                    checked={selectedRows.includes(row.biller_id)}
                                                    onChange={() => handleCheckboxChange(row.biller_id)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ py: 0.3, whiteSpace:'nowrap' }}>{row.name}</TableCell>
                                            <TableCell sx={{ py: 0.3, whiteSpace:'nowrap' }}>
                                                <Grid container>
                                                    {row.providers.map((provider, index) => (

                                                        <Grid key={index} item md={3} sx={{ display: 'flex', justifyContent: 'center', my: 0.5 }}>
                                                            <Typography sx={{ fontSize: '13px', width: "fit-content", }}>{provider.name} </Typography>
                                                        </Grid>
                                                    ))}
                                                </Grid>



                                            </TableCell>




                                            <TableCell sx={{ py: 0.3, textAlign: 'center', whiteSpace:'nowrap' }}>
                                                <IconButton disabled={userRole !== "Admin"} color="primary" onClick={() => handleOpenEditModal(row)}>
                                                    <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                </IconButton>
                                                <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteBillerProvider(row)}>
                                                    <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            count={Math.ceil(filteredData.length / ROWS_PER_PAGE)}
                            page={currentPage}
                            onChange={handleChangePage}
                        /></>
                )}

            {/* <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add Provider Master</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Provider Code</InputLabel>
                                <CustomTextField
                                    value={newStateName}
                                    setValue={setNewStateName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider First Name</InputLabel>
                                <CustomTextField
                                    value={newStateCode}
                                    setValue={setNewStateCode}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider Last Name</InputLabel>
                                <CustomTextField
                                    value={lastName}
                                    setValue={setLastName}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    value={email}
                                    setValue={setEmail}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider Type</InputLabel>
                                <Select
                                    value={providerType}
                                    fullWidth
                                    onChange={(e) => setProviderType(e.target.value)}
                                    sx={{ height: '40px', '& .MuiOutlinedInput-input': { padding: '5px 10px' } }}
                                >

                                    <MenuItem value="Provider Type">Provider Type</MenuItem>
                                    <MenuItem value="Type 1">Type 1</MenuItem>
                                    <MenuItem value="Type 2">Type 2</MenuItem>
                                    <MenuItem value="Type 3">Type 3</MenuItem>
                                </Select>
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    value={phone}
                                    setValue={setPhone}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    value={password}
                                    setValue={setPassword}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Provider List</InputLabel>
                                <Autocomplete
                                    multiple
                                    fullWidth
                                    value={facilityList}
                                    onChange={(event, newValue) => {
                                        setFacilityList(newValue);
                                    }}

                                    options={options}
                                    getOptionLabel={(option) => option.title}
                                    renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '8px', padding: '5px 10px', } }} />}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleSaveNewState}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        Save
                    </Button>
                </Box>
            </Modal> */}

            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add Provider Master</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>

                            <Grid item md={12}>
                                <InputLabel>Biller</InputLabel>
                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    value={billers}
                                    onChange={(event, newValue) => {
                                        setBillers(newValue);
                                    }}
                                    // sx={{ width: '15vw !important' }}
                                    clearIcon={null}
                                    options={billerList}
                                    getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important' } }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <InputLabel>Provider Name</InputLabel>
                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    multiple
                                    disableCloseOnSelect
                                    value={providers}
                                    onChange={(event, newValue) => {
                                        setProviders(newValue);
                                    }}
                                    // sx={{ height: '15vw !important' }}
                                    clearIcon={null}
                                    options={providerList}
                                    getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}// Display facility name
                                    getOptionSelected={(option, value) => option.id === value.id} // Set value based on id
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // Customize equality test
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selected} />}
                                                label={<ListItemText primary={option.First_Name + " " + option.Last_Name} />} // Display facility name
                                            />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important' } }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleAddBillerProviders}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        Add
                    </Button>
                </Box>
            </Modal>


            <Modal open={editModalOpen} onClose={handleCloseEditModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Provider Master</Typography>
                        <IconButton onClick={handleCloseEditModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>

                            <Grid item md={12}>
                                <InputLabel>Biller</InputLabel>
                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    value={editBiller}
                                    onChange={(event, newValue) => {
                                        setEditBiller(newValue);
                                    }}
                                    // sx={{ width: '15vw !important' }}
                                    clearIcon={null}
                                    options={billerList}
                                    getOptionLabel={(option) => option.First_Name + " " + option.Last_Name}
                                    getOptionSelected={(option, value) => option.id === value.id}
                                    isOptionEqualToValue={(option, value) => option.id === value.id}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important' } }}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <InputLabel>Provider Name</InputLabel>
                                <Autocomplete
                                    className='scrollBar'
                                    fullWidth
                                    multiple
                                    disableCloseOnSelect
                                    value={editProvider}
                                    onChange={(event, newValue) => {
                                        setEditProvider(newValue);
                                    }}
                                    // sx={{ width: '15vw !important' }}
                                    clearIcon={null}
                                    options={providerList}
                                    getOptionLabel={(option) => option.First_Name} // Display facility name
                                    getOptionSelected={(option, value) => option.id === value.id} // Set value based on id
                                    isOptionEqualToValue={(option, value) => option.id === value.id} // Customize equality test
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props}>
                                            <FormControlLabel
                                                control={<Checkbox checked={selected} />}
                                                label={<ListItemText primary={option.First_Name} />} // Display facility name
                                            />
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important' } }}
                                        />
                                    )}
                                />
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleUpdateBillerProviders}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        Update
                    </Button>
                </Box>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

        </Box>
    );
};

export default BillerProviderBody;
