import React from 'react';
import { Button, CircularProgress, Modal, Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
const DeleteModal = ({ loading, setLoading, open, setOpen, handleDeletefunction, id }) => {

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = () => {
        handleDeletefunction(id);
        // handleClose();
    };

    return (
        <>
            {/* <Button onClick={handleOpen}>Open Delete Modal</Button> */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="delete-modal-title"
                aria-describedby="delete-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 400,
                        bgcolor: 'background.paper',
                        border: '2px solid #fff',
                        borderRadius: '6px',
                        boxShadow: 24,
                        p: 4,
                    }}
                >
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography id="delete-modal-title" variant="h6" component="h2">
                            Approval for Deletion
                        </Typography>
                        <IconButton onClick={handleClose}><CloseIcon /></IconButton>

                    </Box>

                    <Typography id="delete-modal-description" sx={{ mt: 2 }}>
                        Are you sure you want to delete this?
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'right', mt: 4 }}>
                        <Button
                            variant="contained"
                            disableElevation
                            onClick={handleDelete}
                            color="primary"
                        >
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Delete"}
                        </Button>
                    </Box>
                </Box>
            </Modal >
        </>
    );
};

export default DeleteModal;
