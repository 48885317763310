import { Box, Grid } from "@mui/material";
import BillerSidebar from "./BillerSidebar";


export default function BillerLayout({children}) {
    return (
        <Box>
           <BillerSidebar children={children}/>
        </Box>
    )
}