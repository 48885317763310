import React, { useState } from 'react';
import Layout from '../../Layout';
import BillerBody from './Components/BillerBody';
import EmployeeBody from './Components/EmployeeBody';


export default function Employee() {


    return (
        <Layout>
            <EmployeeBody  />
        </Layout>
    )
}