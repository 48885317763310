import React from 'react';
import { Box, Pagination } from '@mui/material';

const CustomPagination = ({ count, page, onChange, }) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'right', mt: 2 }}>
            <Pagination
                count={count}
                page={page}
                onChange={onChange}
                sx={{color:'#0093AF !important'}}
                size="small"

            />
        </Box>
    );
};

export default CustomPagination;
