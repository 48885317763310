// Import necessary libraries
import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, InputLabel, Typography } from '@mui/material';
import Chart from 'chart.js/auto';
import 'chartjs-plugin-datalabels';
import Layout from '../Layout';
import { useDispatch } from 'react-redux';
import { getAllGraph } from '../api/action';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ChartDataLabels from 'chartjs-plugin-datalabels';

const Home = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [cpt, setCpt] = useState([]);
  const [facility, setFacility] = useState([]);
  const [facilityType, setFacilityType] = useState([]);
  const [provider, setProvider] = useState([]);
  const [loading, setLoading] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  // Chart.register(ChartDataLabels);

  const adjustToLocalDate = (date) => {
    if (date === null) return null;
    const adjustedDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);
    return adjustedDate.toISOString().split('T')[0];
  };

  useEffect(() => {
      setLoading(true);
      const payload = {
        start_date: adjustToLocalDate(startDate),
        end_date: adjustToLocalDate(endDate),
      };
      dispatch(
        getAllGraph(payload,
          (data) => {
            setLoading(false);
            setCpt(data?.data?.Data?.cpt);
            setFacility(data?.data?.Data?.facility);
            setProvider(data?.data?.Data?.provider);
          },
          (error) => {
            console.log(error);
            setLoading(false);
          }
        )
      );
  }, [endDate, dispatch]);


  // Corrected the chart refs
  const chart1Ref = useRef(null);
  // const chart2Ref = useRef(null);
  const chart3Ref = useRef(null);
  const chart4Ref = useRef(null);

  // Corrected the chart data
  const chartData1 = {
    labels: cpt.map(item => `CPT Code: ${item["CPT Code"]}`),
    datasets: [
      {
        label: 'CPT Count',
        data: cpt.map(item => item.Count),
        borderWidth: 1,
      },
    ],
  };

  const chartData2 = {
    labels: [''], // You mentioned an empty label, adjust this if needed
    datasets: facilityType.map(item => ({
      label: item.facility_type,
      data: [item.Count],
      borderWidth: 0.5,
      barThickness: 30,
      // You can uncomment and add backgroundColor and borderColor properties if needed
    }))
  };

  const getInitials = (facility) => {
    const words = facility.split(' ');
    const initials = words.map(word => word.charAt(0).toUpperCase());
    return initials.join('');
  };

  const chartData3 = {
    labels: facility.map(item => getInitials(item.facility)),
    datasets: [
      {
        label: 'Facilty',
        data: facility.map(item => item.Count),
        borderWidth: 1,
        fill: false

      },
    ],
  };

  const chartData4 = {
    labels: provider.map(item => item.provider),
    datasets: [
      {
        label: 'CPT Count',
        data: provider.map(item => item.Count),
        backgroundColor: [
          'rgba(255, 99, 132, 0.5)', // Red
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)', // Red
        ],
        borderWidth: 2,
        barThickness: 30,
      },
    ],
  };

  useEffect(() => {
    if (!chart1Ref.current) {
      chart1Ref.current = new Chart(document.getElementById('myChart1').getContext('2d'), {
        type: 'pie',
        data: chartData1,
        options: {
          cutout: '0%',
          legend: {
            labels: {
              font: {
                weight: 'bold',
                size: 20,
              },
            },
          },
          maintainAspectRatio: true,
          aspectRatio: 2,
          height: "100px",
        },
      });
    }

    // if (!chart2Ref.current) {
    //   chart2Ref.current = new Chart(document.getElementById('myChart2').getContext('2d'), {
    //     type: 'bar',
    //     data: chartData2,
    //     options: {
    //       scales: {
    //         x: {
    //           title: {
    //             display: true,
    //             text: 'Facility Type ',
    //             font: {
    //               weight: 'bold',
    //             },
    //           },
    //           ticks: {
    //             font: {
    //               weight: 'bold',
    //             },
    //           },
    //         },
    //         y: {
    //           title: {
    //             display: true,
    //             text: 'Total Count',
    //             font: {
    //               weight: 'bold',
    //             },
    //           },
    //           beginAtZero: true,
    //           grid: {
    //             display: false, // Remove horizontal lines
    //           },
    //         },
    //       },
    //       plugins: {
    //         datalabels: {
    //           anchor: 'end',
    //           align: 'end',
    //           display: true,
    //         },
    //       },
    //     },
    //   });
    // }

    if (!chart3Ref.current) {
      chart3Ref.current = new Chart(document.getElementById('myChart3').getContext('2d'), {
        type: 'line',
        data: chartData3,
        options: {
          scales: {
            x: {
              title: {
                display: true,
                text: 'Facility ',
                font: {
                  weight: 'bold',
                },
              },
              ticks: {
                font: {
                  weight: 'bold',
                },
              },
            },
            y: {
              title: {
                display: true,
                text: 'CPT',
                font: {
                  weight: 'bold',
                },
              },
              beginAtZero: true,
            },
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              display: true,
            },
            tooltip: {
              callbacks: {
                title: (tooltipItems) => {
                  const index = tooltipItems[0].dataIndex;
                  return facility[index].facility; // Show full facility name
                },
                label: (tooltipItem) => {
                  return `CPT Count: ${tooltipItem.raw}`; // Customize label if needed
                },
              },
            },
          },
        },
      });
    }
    

    if (!chart4Ref.current) {
      chart4Ref.current = new Chart(document.getElementById('myChart4').getContext('2d'), {
        type: 'bar', // Change chart type to 'bar'
        data: chartData4,
        options: {
          indexAxis: 'y', // Set the index axis to 'y' for horizontal bars
          scales: {
            y: {
              title: {
                display: true,
                text: 'Providers',
                font: {
                  weight: 'bold',
                },
              },
              grid: {
                display: false, // Remove horizontal lines
              },
              ticks: {
                font: {
                  weight: 'bold',
                },
              },
            },
            x: {
              grid: {
                display: false, // Remove horizontal lines
              },
              title: {
                display: true,
                text: 'CPT Count',
                font: {
                  weight: 'bold',
                },
              },
              beginAtZero: true,
            },
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              display: true,
            },
          },
        },
      });
    }


    // Corrected the destroy calls
    return () => {
      if (chart1Ref.current) {
        chart1Ref.current.destroy();
        chart1Ref.current = null;
      }

      // if (chart2Ref.current) {
      //   chart2Ref.current.destroy();
      //   chart2Ref.current = null;
      // }

      if (chart3Ref.current) {
        chart3Ref.current.destroy();
        chart3Ref.current = null;
      }

      if (chart4Ref.current) {
        chart4Ref.current.destroy();
        chart4Ref.current = null;
      }
    };
  }, [chartData1, chartData2, chartData3, chartData4]);

  return (
    <Layout>
      <Grid container sx={{ px: 10, mt: 4 }}>
        <Grid item md={3} xs={6}>
          <InputLabel id="facility-type-label">Date From </InputLabel>
          <DatePicker
            // placeholderText='Select Start Date'
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            isClearable={true}
            className='reactDateRangePicker1'
          // selectsStart
          // startDate={startDate}
          // endDate={endDate}
          />
        </Grid>

        <Grid item md={1} xs={6}>

        </Grid>

        <Grid item md={3} xs={6}>
          <InputLabel id="facility-type-label">Date To </InputLabel>
          <DatePicker
            // placeholderText='Select End Date'
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            isClearable={true}
            className='reactDateRangePicker1'
            // selectsEnd
            // startDate={startDate}
            // endDate={endDate}
            // minDate={startDate}
            sx={{ width: "20rem !important" }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={5} sx={{ my: { md: 2, xs: 1 }, px: { md: 10, xs: 2 } }}>
        <Grid item md={6} xs={12} sx={{ textAlign: 'center', }}>
          <Box sx={{ textAlign: 'center', border: '2px solid #0093AF1a', borderRadius: '6px', pt: { md: 5, xs: 2 }, pb: 2, px: { md: 5, xs: 1 }, }}>
            <Typography className='fs16 textColorDark' sx={{ fontWeight: 'bold' }}>
              Number of Encounter
            </Typography>
            <canvas id="myChart1" width="400" height="200"></canvas>
          </Box>
        </Grid>

        {/* <Grid item md={6} xs={12} sx={{ textAlign: 'center', }}>
          <Box sx={{ textAlign: 'center', border: '2px solid #0093AF1a', borderRadius: '6px', pt: 5, pb: 2, px: 5 }}>
            <Typography className='fs16 textColorDark' sx={{ fontWeight: 'bold' }}>
              Productivity By Facility Type
            </Typography>
            <canvas id="myChart2" width="400" height="200"></canvas>
          </Box>
        </Grid> */}

        <Grid item md={6} xs={12} sx={{ textAlign: 'center', }}>
          <Box sx={{ textAlign: 'center', border: '2px solid #0093AF1a', borderRadius: '6px', pt: 5, pb: 2, px: 5 }}>
            <Typography className='fs16 textColorDark' sx={{ fontWeight: 'bold' }}>
              Productivity By Facility
            </Typography>
            <canvas id="myChart3" width="400" height="200"></canvas>
          </Box>
        </Grid>

        <Grid item md={6} xs={12} sx={{ textAlign: 'center', }}>
          <Box sx={{ textAlign: 'center', border: '2px solid #0093AF1a', borderRadius: '6px', pt: 5, pb: 2, px: 5 }}>
            <Typography className='fs16 textColorDark' sx={{ fontWeight: 'bold' }}>
              Productivity By Provider
            </Typography>
            <canvas id="myChart4" width="400" height="200"></canvas>
          </Box>
        </Grid>
      </Grid>
    </Layout>
  );
};

export default Home;
