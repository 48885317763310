import React from 'react';
import { useState } from 'react';
import Layout from '../../Layout';
import AdminReportBody from './Components/AdminReportBody';


export default function AdminReport() {

    
    return (
        <Layout>
            <AdminReportBody />
        </Layout>
    )
}