import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Box, Typography, Button, Toolbar, IconButton, Avatar, Menu, MenuItem, } from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DashboardIcon from '@mui/icons-material/Dashboard';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import GiteIcon from '@mui/icons-material/Gite';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Logo from '../Image/Logo.png'
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useDispatch } from 'react-redux';
import { getProviderGraph, getUserProfile } from '../api/action';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(4)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'right',
  justifyContent: 'right',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const ProviderRoutes = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon style={{ width: 24, height: 24 }} />,
    link: '/providerdashboard'
  },
  {
    name: 'Super Bill',
    icon: <SummarizeIcon style={{ width: 24, height: 24 }} />,
    link: '/superbill'
  },
  {
    name: 'Super Bill Excel Upload',
    icon: <AcUnitIcon style={{ width: 24, height: 24 }} />,
    link: '/excel-upload'
  },

  {
    name: 'Billing Report',
    icon: <GiteIcon style={{ width: 24, height: 24 }} />,
    link: '/report'
  },
  {
    name: 'Timesheet ',
    icon: <ListAltIcon style={{ width: 24, height: 24 }} />,
    link: '/timesheet'
  },
  

];

const EmployeeRoutes = [
 
  {
    name: 'Time Sheet',
    icon: <ListAltIcon style={{ width: 24, height: 24 }} />,
    link: '/timesheet'
  },

];

export default function SidebarProvider2({ children1 }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const location = useLocation();
  const pathname = location.pathname;
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const userRole = localStorage.getItem("userRole")
  const [Routes, setRoutes] = useState([]);
  const id = localStorage.getItem("userId")
  const [loading, setLoading] = useState('');
  const dispatch = useDispatch();

  const [detail, setDetail] = useState([]);
  const userId = localStorage.getItem("userId");

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getUserProfile(payload, userId,
        (data) => {
          setLoading(false);
          console.log(data?.data, "dataA")
          localStorage.setItem('userEmail', data?.data?.user_email);
          // localStorage.setItem('userId', data?.data?.user_email);
          localStorage.setItem('userName', data?.data?.user_name);
          // localStorage.setItem('profileId', profileId);
          // localStorage.setItem('userRole', userRole);
          localStorage.setItem('is_deleted_access', data?.data?.is_deleted_access);
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [])

  useEffect(() => {
    setLoading(true)
    const payload = {
      start_date: null,
      end_date: null,
      user_id: id
    };
    dispatch(
      getProviderGraph(payload,
        (data) => {
          setLoading(false);

        },
        (error) => {
          console.log(error?.response?.data?.code)
          if (error?.response?.data?.code === "token_not_valid") {
            handleLogout()
          }
          setLoading(false)
        }
      )
    );

  },[])

  useEffect(() => {
      if (userRole === "Provider") {
          setRoutes(ProviderRoutes);
      } else {
          setRoutes(EmployeeRoutes);
          navigate('/timesheet');
      }
  }, [userRole]);
  // const history = useHistory()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleLinkClick = (link) => {
    navigate(link);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open} sx={{ background: '#fff' }} elevation={1}>
        <Toolbar>
          {/* <Box sx={{borderRight:'1px solid #000'}}> */}
          <IconButton
            color="#0093AF"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          {/* </Box> */}
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100vw' }}>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left', verticalAlign: 'middle' }}>
              <img src={Logo} height={"50px"} width={"150px"} />
              <Typography sx={{ px: 1, py: 1 }} variant="h6" noWrap component="div" color="#0093AF" fontWeight="bold">
                {/* Billing Portal */}
              </Typography>

            </Box>
            <Box >
              <IconButton onClick={handleClick} disableRipple sx={{ cursor: 'default' }}>
                <Avatar alt="Profile Picture" src="" sx={{ height: { md: 24, xs: 20 }, width: { md: 24, xs: 20 }, p: { xs: 0 } }} />
                <Typography className="fontSans fw500 fs14 mHide" sx={{ px: 1 }}>
                  {localStorage.getItem("userName")}
                </Typography>
                {/* {anchorEl ? <KeyboardArrowUpIcon className="iconLightColor mHide" /> : <KeyboardArrowDownRoundedIcon className="iconLightColor mHide" />} */}
              </IconButton>

              {/* <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My Account</MenuItem>

              </Menu> */}
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '98vh' }}>
          <Box>
            <DrawerHeader>
              <IconButton disableRipple onClick={handleDrawerClose}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>

            </DrawerHeader>
            <Divider />
            {open ? Routes.map((route, index) => (
              <Box key={index} style={{ cursor: 'pointer' }}>
                <Button
                  onClick={() => handleLinkClick(route.link)}
                  sx={{
                    backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                    color: pathname === route.link ? '#fff' : '#0093AF',
                    textAlign: 'left',
                    width: '90%',
                    mx: 1,
                    alignItems: 'left',
                    display: 'flex',
                    justifyContent: 'left',
                    my: 1.2,
                    py: 1,
                    verticalAlign: 'middle',
                    fontWeight: 'bold',
                    '&:hover': {
                      backgroundColor: '#0093AF',
                      color: '#fff',
                    },
                  }}
                >
                  <Box>
                    {route.icon}
                  </Box>
                  <Typography className="fs16" sx={{ px: 1, textTransform: 'none', textAlign: 'left' }}>{route.name}</Typography>
                </Button>
              </Box>
            ))
              :
              Routes.map((route, index) => (
                <Link to={route.link} key={index} >
                  <Box style={{ cursor: 'pointer' }}>

                    <Box sx={{
                      width: 36, height: 36,
                      backgroundColor: pathname === route.link ? '#0093AF' : 'transparent',
                      color: pathname === route.link ? '#fff' : '#0093AF',
                      mx: 1,
                      my: 1.5,
                      borderRadius: '6px',
                      p: 0.8,
                    }}>
                      {route.icon}
                    </Box>
                  </Box>

                </Link>
              ))


            }
            <Divider />
          </Box>

          {
            open ?
              <Button
                sx={{
                  textAlign: 'left',
                  backgroundColor: '#0093AF',
                  color: '#fff',
                  textAlign: 'left',
                  width: '80%',
                  mx: 1,
                  alignItems: 'left',
                  display: 'flex',
                  justifyContent: 'left',
                  py: 1,
                  fontWeight: 'bold',
                  marginTop: '100%',
                  '&:hover': {
                    backgroundColor: '#0093AF',
                    color: '#fff',
                  },
                }}
                onClick={() => handleLogout()}
              >
                <LogoutIcon style={{ width: 24, height: 24 }} />
                <Typography className="fs16" sx={{ px: 2, textTransform: 'none', display: open ? 'block' : 'none', }}>Logout</Typography>
              </Button>
              :
              <LogoutIcon sx={{
                width: 36, height: 36,
                backgroundColor: '#0093AF',
                color: '#fff',
                m: 1,
                borderRadius: '6px',
                p: 1,
              }}
                onClick={() => handleLogout()}
              />


          }

        </Box>





      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, width: !open ? "95vw" : '80vw', pt: 10 }}>
        {children1}
      </Box>
    </Box>
  );
}