import React, { useEffect, useState } from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, TextField, Button, Autocomplete, Select, MenuItem, FormControl,
    Checkbox, FormControlLabel, ListItemText,
    Box,
    InputLabel
} from '@mui/material';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import allDxCodes from '../Data/DXCode';
import { getAllVisitReasons, getFacilityByProviderId, getPatientByProviderId } from '../api/action';
import { useDispatch } from 'react-redux';
import CopyDataModal from './CopyDataModal';

const CustomTable = ({ state, setState, handleAddTimeSheet, updated }) => {
    const [provider, setProvider] = useState(localStorage.getItem("userName"));
    const [providerId, setProviderId] = useState(localStorage.getItem("userId"));
    const [profileId, setProfileId] = useState(localStorage.getItem("profileId"));
    const [allPatient, setAllPatient] = useState([]);
    const [allCpt, setAllCpt] = useState([]);
    const [admissions, setAdmissions] = useState([]);
    const [filteredDxCodes, setFilteredDxCodes] = useState(allDxCodes);
    const [loading, setLoading] = useState(true);
    const [formData, setFormData] = useState({});
    const [facility, setFacility] = useState(null);
    const [facilityTypeName, setFacilityTypeName] = useState("");
    const [facilityTypeId, setFacilityTypeId] = useState("");
    const [dos, setDos] = useState(dayjs());
    // const [facility, setFacility] = useState(null);
    const [allFacilities, setAllFacilities] = useState([]);
    const [open, setOpen] = useState(false);
    const [copyData, setCopyData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        if (state === null) {
            setCopyData([])
        }
    }, [state]);

    useEffect(() => {
        setFacility(null)
        setFacilityTypeId("")
        setFacilityTypeName('')
    }, [filteredData]);

    useEffect(() => {
        if (!facility && state && state.length > 0 && state[0].facility_master) {
            setFacility(state[0].facility_master);
            setFacilityTypeName(state[0].facility_type?.facility_type_Name);
            setFacilityTypeId(state[0].facility_type?.id);
        }
    }, [state]);


    function formatDate(dateString) {
        const date = new Date(dateString);
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        const year = date.getFullYear();

        return `${year}-${month}-${day}`;
    }

    const initialRowState = {
        provider_profile: localStorage.getItem("profileId") || '',
        facility_master: facility?.id,
        patient_name: '',
        last_name: '',
        dob: null,
        // dob: formatDate((dayjs())),
        date_of_service: formatDate(dos),
        cpt_code: null,
        dx_code: [],
        admission: '',
        reason_for_visit: '',
        comment: '',
        modifier: '',
        state: '',
        facility_type: facility?.facility_type_id,
    };

    function convertToFormattedString(data) {
        if (Array.isArray(data)) {
            if (data.length === 0) {
                return "";
            } else if (typeof data[0] === 'string') {
                return data.join(', ').toUpperCase();
            } else if (typeof data[0] === 'object' && data[0].Code) {
                return data.map(item => item.Code).join(', ');
            }
        }
        return "";
    }

    const initialState = (Array.isArray(copyData) && copyData?.length > 0) ? copyData?.map(item => ({
        provider_profile: profileId,
        facility_master: facility?.id,
        patient_name: item.patient_name,
        last_name: item.last_name,
        dob: item.dob,
        date_of_service: dos,
        cpt_code: item.cpt_code,
        dx_code: item.dx_code,
        admission: item.admission,
        reason_for_visit: item.reason_for_visit,
        comment: item.comment,
        modifier: item.modifier,
        state: '',
        facility_type: facilityTypeId
    })) : Array.from({ length: 5 }, () => ({ ...initialRowState }));

    useEffect(() => {
        setState(initialState);
    }, [updated, copyData]);

    // useEffect(() => {
    //   setCopyData([])
    // }, [updated]);

    console.log(copyData, "value")
    console.log(state, "value")


    useEffect(() => {
        setLoading(true);
        const payload = null;
        dispatch(
            getAllVisitReasons(payload,
                (data) => {
                    setLoading(false);
                    setAllCpt(data?.data?.Data);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, [dispatch]);

    useEffect(() => {
        setLoading(true);
        const payload = null;
        dispatch(
            getPatientByProviderId(
                payload,
                profileId,
                (data) => {
                    setLoading(false);

                    const seen = new Set(); // Initialize a set to keep track of seen patients
                    const uniquePatients = data?.data?.reduce((acc, patient) => {
                        const key = `${patient.conclude_patient_data.firstName}-${patient.conclude_patient_data.lastName}-${patient.conclude_patient_data.dob}`;
                        if (!seen.has(key)) {
                            seen.add(key);
                            acc.push(patient);
                        }
                        return acc;
                    }, []);

                    setAllPatient(uniquePatients);
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            )
        );
    }, []);

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getFacilityByProviderId(payload, providerId,
                (data) => {
                    setLoading(false);
                    setAllFacilities(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [])

    const handleNameSelect = (selectedName, index) => {

        const selectedPatient = allPatient.find(
            (patient) => `${patient.conclude_patient_data?.firstName} ${patient.conclude_patient_data?.lastName} ${patient?.conclude_patient_data?.dob}` === selectedName
        );
        if (selectedPatient) {
            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = {
                    ...newState[index],
                    patient_name: selectedPatient?.conclude_patient_data?.firstName,
                    last_name: selectedPatient?.conclude_patient_data?.lastName,
                    dob: selectedPatient?.conclude_patient_data?.dob,
                };
                return newState;
            });

            const updatedInitialRowState = {
                ...initialRowState,
                patient_name: selectedPatient?.conclude_patient_data?.firstName,
                last_name: selectedPatient?.conclude_patient_data?.lastName,
                dob: selectedPatient?.conclude_patient_data?.dob,
            };

            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = { ...updatedInitialRowState };
                return newState;
            });
        }
    };


    const handleDxCodeFilter = (event, value) => {
        const inputValue = value.trim().toLowerCase();
        const inputLength = inputValue.length;
        const filteredCodes = inputLength === 0 ? allDxCodes : allDxCodes.filter(code =>
            code.Code.toLowerCase().includes(inputValue) ||
            code.Description.toLowerCase().includes(inputValue)
        );
        setFilteredDxCodes(filteredCodes);
    };

    const handleInputChange = (index, field, value) => {
        if (field === 'dob' || field === 'date_of_service') {
            const formattedDate = dayjs(value).format('MM/DD/YYYY');
            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = { ...newState[index], [field]: formattedDate };
                return newState;
            });
        } else if (field === 'dx_code') {
            const formattedDxCode = value.map(item => {
                if (typeof item === 'object' && item.Code) {
                    return item.Code;
                } else if (typeof item === 'string') {
                    return item;
                }
                return null;
            }).filter(item => item !== null);

            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = { ...newState[index], [field]: formattedDxCode };
                return newState;
            });
        } else if (field === 'dob') {
            const formattedDate = formatDate(value);
            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = { ...newState[index], [field]: formattedDate };
                return newState;
            });
        }
        else if (field === 'cpt_code') {
            const formattedCptCode = value?.id;
            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = { ...newState[index], [field]: formattedCptCode };
                return newState;
            });
        }
        else {
            setState((prevState) => {
                const newState = [...prevState];
                newState[index] = { ...newState[index], [field]: value };
                return newState;
            });
        }
    };

    const addRow = () => {
        setState((prevState) => [...prevState, { ...initialRowState }]);
    };

    return (
        <div>
            <CopyDataModal open={open} setOpen={setOpen} copyData={copyData} setCopyData={setCopyData} setFilteredData={setFilteredData} filteredData={filteredData} />
            <Box sx={{ display: 'flex', mb: 2, justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex' }}>
                    <Box>
                        <InputLabel>Facility Name</InputLabel>
                        <Autocomplete
                            required
                            className='scrollBar'
                            fullWidth
                            value={facility}
                            onChange={(event, newValue) => {
                                setFacility(newValue);
                                setFacilityTypeId(newValue?.facility_type_id);
                                setFacilityTypeName(newValue?.facility_type_Name);

                                // Update only the facility-related fields
                                setState((prevState) =>
                                    prevState.map(row => ({
                                        ...row,
                                        facility_master: newValue?.id,
                                        facility_type: newValue?.facility_type_id,
                                    }))
                                );
                            }}
                            sx={{ width: '10vw !important', mr: 2 }}
                            clearIcon={null}
                            options={allFacilities}
                            getOptionLabel={(option) => option.facility_name} // Display facility name
                            getOptionSelected={(option, value) => option.id === value.id} // Set value based on id
                            isOptionEqualToValue={(option, value) => option.id === value.id} // Customize equality test
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important', } }}
                                />
                            )}
                        />

                    </Box>

                    <Box sx={{ display: 'flex', mr: 2 }}>
                        <Box>
                            <InputLabel>Facility Type</InputLabel>
                            <TextField
                                sx={{ fontSize: '14px' }}
                                className='tableCellWidth'
                                variant="outlined"
                                value={facilityTypeName}
                                disabled
                                // onChange={(e) => handleInputChange(index, 'comment', e.target.value)}
                                InputProps={{ style: { height: '2rem' } }}
                            />
                        </Box>
                    </Box>

                    <Box>
                        <InputLabel>Date of Service</InputLabel>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker
                                value={dayjs(dos)}
                                onChange={(newValue) => {
                                    setDos(newValue);
                                    setState((prevState) =>
                                        prevState.map(row => ({ ...row, date_of_service: formatDate(newValue) }))
                                    );
                                }}
                                className='tableCellWidth'
                                disableFuture
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        className='tableCellWidth'
                                        style={{ height: '10px !important' }}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>

                <Button
                    elevation={0}
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                    sx={{ marginTop: '10px', boxShadow: 'none' }}
                >
                    copy Data
                </Button>
            </Box>
            <TableContainer component={Paper} className='scrollBar'>
                <Table>
                    <TableHead sx={{ backgroundColor: '#0093AF' }}>
                        <TableRow>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Patient First Name*</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Patient Last Name*</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Date of Birth*</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>CPT Code*</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>DX Code*</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Admission</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Modifier</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Reason for Visit</TableCell>
                            <TableCell sx={{ color: "#fff", py: 1, whiteSpace: 'nowrap' }}>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {state?.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell sx={{ py: 1.2 }}>
                                    {allPatient.length === 0 ? (
                                        <TextField
                                            className='tableCellWidth'
                                            variant="outlined"
                                            value={row.firstName}
                                            onChange={(e) => handleInputChange(index, 'patient_name', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ style: { height: '2rem' } }}
                                        />
                                    ) : (
                                        <Autocomplete
                                            disabled={!facility}
                                            value={row.patient_name}
                                            freeSolo
                                            options={allPatient.map((patient) => `${patient?.conclude_patient_data?.firstName} ${patient?.conclude_patient_data.lastName} ${patient?.conclude_patient_data.dob}`)}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, value) => handleNameSelect(value, index)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    className='tableCellWidth'
                                                    variant="outlined"
                                                    onChange={(e) => handleInputChange(index, 'patient_name', e.target.value)}
                                                    sx={{ '& input': { height: '18px', fontSize: '14px', width: '10vw !important' } }}
                                                />
                                            )}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    {allPatient.length === 0 ? (
                                        <TextField
                                            className='tableCellWidth'
                                            variant="outlined"
                                            value={row.lastName}
                                            onChange={(e) => handleInputChange(index, 'last_name', e.target.value)}
                                            InputLabelProps={{ shrink: true }}
                                            InputProps={{ style: { height: '2rem' } }}
                                        />
                                    ) : (
                                        <Autocomplete
                                            disabled={!facility}
                                            className='tableCellWidth'
                                            freeSolo
                                            value={row.last_name}
                                            options={allPatient.map((patient) => patient.lastName)}
                                            getOptionLabel={(option) => option}
                                            onChange={(e, value) => handleNameSelect(value, index)}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    onChange={(e) => handleInputChange(index, 'last_name', e.target.value)}
                                                    sx={{ '& input': { height: '18px', fontSize: '14px', width: '10vw !important' } }}
                                                />
                                            )}
                                        />
                                    )}
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            disabled={!facility}
                                            value={dayjs(row.dob)}
                                            onChange={(newValue) => handleInputChange(index, 'dob', newValue)}
                                            className='tableCellWidth'
                                            disableFuture
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    className='tableCellWidth'
                                                    style={{ height: '10px !important' }}
                                                    // Set the format for the date
                                                    inputProps={{ style: { textAlign: 'center' } }}
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { height: '10px !important' },
                                                    }}
                                                // Specify the format here
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <Autocomplete
                                        disabled={!facility}
                                        className='scrollBar'
                                        fullWidth
                                        // freeSolo
                                        // value={row.cptCode}
                                        // value={allCpt.find((cpt) => cpt.visit_reason_code === row.cpt_code) || row.cptCode}
                                        value={allCpt.find((cpt) => cpt?.id === row?.cpt_code) || null}
                                        onChange={(event, newValue) => handleInputChange(index, 'cpt_code', newValue)}
                                        sx={{ width: '11vw !important' }}
                                        clearIcon={null}
                                        options={allCpt}
                                        getOptionLabel={(option) => `${option.visit_reason_code} ${option.visit_reason_description}`}
                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important' } }}
                                            />
                                        )}
                                        renderOption={(props, option) => (
                                            <li {...props}>
                                                {option.visit_reason_code}: {option.visit_reason_description}
                                            </li>
                                        )}
                                    />

                                    {/* <Autocomplete
                                        value={allCpt.find((cpt) => cpt.CPTCode === row.cpt_code) || null}
                                        onChange={(event, newValue) => handleInputChange(index, 'cpt_code', newValue ? newValue.CPTCode : '')}
                                        options={allCpt}
                                        getOptionLabel={(option) => option.CPTCode || ''}
                                        isOptionEqualToValue={(option, value) =>
                                            option.CPTCode === value || value === option.CPTCode
                                        }
                                        renderInput={(params) => <TextField {...params} label="CPT Code" />}
                                    /> */}
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <Autocomplete
                                        disabled={!facility}
                                        className='scrollBar'
                                        fullWidth
                                        multiple
                                        freeSolo
                                        disableCloseOnSelect
                                        value={row?.dx_code.map(code => allDxCodes.find(option => option.Code === code) || code)}
                                        onChange={(event, newValue) => handleInputChange(index, 'dx_code', newValue)}
                                        sx={{ width: '20rem !important', height: '2.3rem !important', fontSize: "10px !important" }}
                                        options={filteredDxCodes}
                                        isOptionEqualToValue={(option, value) => {
                                            if (typeof value === 'string') {
                                                return option.Code === value;
                                            }
                                            return option.Code === value.Code;
                                        }}
                                        getOptionLabel={(option) => typeof option === 'string' ? option : `${option.Code} ${option.Description}`}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <FormControlLabel
                                                    sx={{ fontSize: "10px !important" }}
                                                    control={
                                                        <Checkbox
                                                            sx={{
                                                                transform: 'scale(0.75)',
                                                                '& .MuiSvgIcon-root': { fontSize: 22 },
                                                            }}
                                                            checked={row?.dx_code.includes(option.Code)}
                                                        />
                                                    }
                                                    label={<ListItemText sx={{
                                                        '& .MuiTypography-root': { fontSize: '12px !important' }
                                                    }} primary={`${option.Code} ${option.Description}`} />}
                                                />
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                sx={{ '& input': { height: '0px', fontSize: '14px', width: '10vw !important', overflowX: 'auto !important' } }}
                                                onInputChange={(event, newInputValue) => handleDxCodeFilter(newInputValue)}
                                            />
                                        )}
                                    />
                                    {/* <Autocomplete
                                        multiple
                                        value={row.dx_code ? row.dx_code.split(',').map((code) => code.trim()) : []}
                                        onChange={(event, newValue) =>
                                            handleInputChange(index, 'dx_code', newValue)
                                        }
                                        options={filteredDxCodes}
                                        getOptionLabel={(option) => option.Code}
                                        renderInput={(params) => <TextField {...params} label="Dx Code" />}
                                        onInputChange={handleDxCodeFilter}
                                        renderOption={(props, option) => (
                                            <li {...props} key={option.Code}>
                                                {option.Code} - {option.Description}
                                            </li>
                                        )}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <ListItemText
                                                    key={option}
                                                    primary={option}
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                    /> */}
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <FormControl fullWidth variant="outlined">
                                        <Select
                                            disabled={!facility}
                                            className='tableCellWidth'
                                            value={row.admission}
                                            onChange={(e) => handleInputChange(index, 'admission', e.target.value)}
                                            displayEmpty
                                            // sx={{ color: '#a2a2a2' }}
                                        >
                                            {/* <MenuItem value="" disabled>
                                                Admission
                                            </MenuItem> */}
                                            {["Yes", "No"].map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <TextField
                                        disabled={!facility}
                                        sx={{ fontSize: '14px' }}
                                        className='tableCellWidth'
                                        variant="outlined"
                                        value={row.modifier}
                                        onChange={(e) => handleInputChange(index, 'modifier', e.target.value)}
                                        InputProps={{ style: { height: '2rem' } }}
                                    />
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <FormControl fullWidth variant="outlined">
                                        <Select
                                            disabled={!facility}
                                            className='tableCellWidth'
                                            value={row.reason_for_visit}
                                            onChange={(e) => handleInputChange(index, 'reason_for_visit', e.target.value)}
                                            displayEmpty
                                            // sx={{ color: '#a2a2a2' }}
                                        >
                                            {/* <MenuItem value="" disabled sx={{ fontSize: "12px" }}>
                                                Reason
                                            </MenuItem> */}
                                            {["Follow Up", "Initial", "ED Consult", "IOP", "CL-Medsurg Consult", "Discharge"].map((option) => (
                                                <MenuItem key={option} value={option} sx={{ fontSize: "12px" }}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell sx={{ py: 1.2 }}>
                                    <TextField
                                        disabled={!facility}
                                        sx={{ fontSize: '14px' }}
                                        className='tableCellWidth'
                                        variant="outlined"
                                        value={row.comment}
                                        onChange={(e) => handleInputChange(index, 'comment', e.target.value)}
                                        InputProps={{ style: { height: '2rem' } }}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Button variant="contained" color="primary" onClick={addRow} style={{ marginTop: '10px' }}>
                Add Row
            </Button>
            <Button variant="contained" color="primary" onClick={handleAddTimeSheet} style={{ marginTop: '10px', marginLeft: '10px' }}>
                Save
            </Button>
        </div>
    );
};

export default CustomTable;
