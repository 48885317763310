import React from 'react';
import { useState } from 'react';
import ReportsBody from '../Component/ReportsBody';
import ProviderLayout from '../ProviderLayout';


export default function Report() {

 
    return (
        <ProviderLayout>
            <ReportsBody />
        </ProviderLayout>
    )
}