// theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#0093AF',
        },
        // secondary: {
        //     main: '#FF4081',
        // },
    },
    typography: {
        fontFamily: 'Poppins, sans-serif', // Set default font
    },
    // components: {
        components: {
            // MuiTextField: {
            //     styleOverrides: {
            //         root: {
            //             '& .MuiInputBase-root': {
            //                 height: '10px', // Set the height of the input field
            //             },
            //             '& .MuiInputBase-input': {
            //                 height: '10px', // Set the height of the input text area
            //                 padding: '0', // Remove padding
            //                 fontSize: '12px', // Adjust font size if needed
            //             },
            //         },
            //     },
            // },
            MuiInputBase: {
                styleOverrides: {
                    root: {
                        height: '2rem', // Ensure the base input height is also set
                    },
                    input: {
                        height: '10px', // Ensure the input text area height is set
                        padding: '0', // Remove padding
                        fontSize: '12px', // Adjust font size if needed
                    },
                },
            },
            // MuiButton: {
            //     styleOverrides: {
            //         root: {
            //             backgroundColor: '#0093AF !important',
            //             '&:hover': {
            //                 backgroundColor: '#007A91 !important', // Adjusted hover color for better visual distinction
            //             },
            //             color: '#fff', // Ensure text color is white
            //         },
            //     },
            // },
        },
    // },
});

export default theme;
