import React from 'react';
import { useState } from 'react';
import Layout from '../../Layout';
import ProviderBody from './Components/ProviderBody';
import BillerProviderBody from './Components/BillerProviderBody';


export default function BillerProvider() {

    return (
        <Layout>
            <BillerProviderBody  />
        </Layout>
    )
}