import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Select,
  MenuItem,
  Box,
  Typography,
  TextField,
  Button, Grid, IconButton,
  CircularProgress,
  InputLabel,
  Modal
} from '@mui/material';
import { addTimeSheetByProvider, deleteTimeSheetByProvider, getFacilityByProviderId, getTimeSheetByProvider, updateTimeSheetByProvider } from '../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../Utils/CustomSnackbar';
import CustomPagination from '../Utils/CustomPagination';
import * as XLSX from 'xlsx';
import DeleteIcon from '@mui/icons-material/Delete';
import CustomTextField from '../Utils/CustomTextfield';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import convertTo12HourFormat from '../Utils/HelpingFunction';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteModal from '../Utils/DeleteModal';
import { useNavigate } from 'react-router-dom';


function convertToMMDDYYYY(dateString) {
  const [year, month, day] = dateString.split('-');
  return `${month}/${day}/${year}`;
}

const BillerTimeSheetTable = () => {
  const currentDate = new Date();
  const today = currentDate.toISOString().split('T')[0];

  function convertToHHMMSS(time) {
    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':');

    // Convert hours and minutes to numbers
    const hoursNum = parseInt(hours, 10);
    const minutesNum = parseInt(minutes, 10);

    // Pad with '0' if necessary and add seconds
    const formattedTime = `${hoursNum.toString().padStart(2, '0')}:${minutesNum.toString().padStart(2, '0')}:00`;

    return formattedTime;
  }

  const [selectedRows, setSelectedRows] = useState([]);
  const [savedData, setSavedData] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [loading, setLoading] = useState('');
  const [facility, setFacility] = useState('');
  const [jobType, setJobType] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [lunchBreak, setLunchBreak] = useState('');
  const [totalHours, setTotalHours] = useState('');
  const [patientsSeen, setPatientsSeen] = useState('');
  const [comment, setComment] = useState('');
  const [status, setStatus] = useState('');
  const [patientSeen, setPatientSeen] = useState('');
  const [facilityData, setFacilityData] = useState([]);
  const [timeSheetData, setTimeSheetData] = useState([]);
  const providerId = localStorage.getItem("userId")
  const userRole = localStorage.getItem("userRole")
  const [updated, setUpdated] = useState(false);
  const [updatTimeSheet, setUpdateTimeSheet] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [remarks, setRemarks] = useState("");
  const navigate = useNavigate();

  const ROWS_PER_PAGE = 10;

  const [tempIndex, setTempIndex] = React.useState('');
  const [tempMilestone, setTempMilestone] = React.useState('');
  const [tempPlan, setTempPlan] = React.useState('');
  const [paymentPlan, setPayment] = React.useState('');
  const [milestone, setMileStone] = React.useState('');
  const [paymentPlans, setPaymentPlans] = React.useState([]);
  const [lastDay, setLastDay] = useState(dayjs());
  const [firstDay, setFirstDay] = useState(dayjs().subtract(10, 'day'));
  const [date, setDate] = useState(dayjs());

  const [showModal, setShowModal] = useState(false);
  const [editId, setEditId] = useState('');
  const [editDate, setEditDate] = useState(dayjs());
  const [editProvider, setEditProvider] = useState('');
  const [editFacility, setEditFacility] = useState('');
  const [editJobType, setEditJobType] = useState('');
  const [editStartTime, setEditStartTime] = useState('');
  const [editEndTime, setEditEndTime] = useState('');
  const [editLunchBreak, setEditLunchBreak] = useState('');
  const [editTotalHours, setEditTotalHours] = useState('');
  const [editPatientSeen, setEditPatientSeen] = useState('');
  const [editComment, setEditComment] = useState('');
  const [editStatus, setEditStatus] = useState('');
  const [editRemarks, setEditRemarks] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [facilityName, setFacilityName] = useState('');
  const [id, setId] = useState('');
  const [lunch, setLunch] = useState('');
  const [provider, setProvider] = useState('');
  const [totalSelectedHours, setTotalSelectedHours] = useState('');

  useEffect(() => {
    const today = dayjs();
    setLastDay(today);
    setFirstDay(today.subtract(10, 'day'));
  }, []);


  useEffect(() => {
    if (userRole !== "Provider") {
      setJobType(userRole)
    }
  }, [])

  // const handleCreatePlan = () => {
  //   if (!date || !startTime || !endTime || !lunchBreak) {
  //     setSnackbarMessage("Please fill all the fields");
  //     setSnackbarOpen(true);
  //     setSnackbarSeverity("error");
  //   } else {
  //     setPaymentPlans((oldArr) => [...oldArr, {
  //       user: providerId,
  //       date: date.format('MM/DD/YYYY'),
  //       facility,
  //       type_of_job: jobType === "Employee_Office_Work" ? "Office" : jobType === "Employee_Admin_Work" ? "Administration" : jobType,
  //       start_time: convertToHHMMSS(startTime),
  //       end_time: convertToHHMMSS(endTime),
  //       lunch_break: lunchBreak,
  //       patient_seen: patientSeen,
  //       remarks
  //     }]);
  //     setDate(dayjs());
  //     setFacility("");

  //     console.log("jobType", jobType)

  //     if (userRole === "Provider") {
  //       setJobType("");
  //     }
  //     else {
  //       setJobType(userRole)
  //     }
  //     setStartTime("");
  //     setEndTime("");
  //     setLunchBreak("");
  //     setTotalHours("");
  //     setPatientSeen("");
  //     setComment("");
  //     setStatus("");
  //     setRemarks("");
  //   }
  // };


  // function convertDate(dateString) {
  //   console.log(dateString)
  //   // Split the input date string by hyphen
  //   const dateParts = dateString;

  //   // Extract the year, month, and day from the array
  //   const year = dateParts[0];
  //   const month = dateParts[1];
  //   const day = dateParts[2];

  //   // Construct the new date string in mm/dd/yyyy format
  //   const formattedDate = `${month}/${day}/${year}`;

  //   return formattedDate;
  // }


  const handleRemoveFromPlan = (index) => {
    setPaymentPlans((prevArray) => {
      const newArray = [...prevArray]; // Create a copy of the original array
      newArray.splice(index, 1); // Remove the element at the specified index
      return newArray; // Update the state with the new array
    });
  };

  const handleEditPackage = (index) => {
    setTempIndex(index);
    setTempMilestone(paymentPlans[index].milestone);
    setTempPlan(paymentPlans[index].payment);
  };


  const handleUpdatePackage = (index) => {
    const val = paymentPlans;
    val[index] = { payment: tempPlan, milestone: tempMilestone };
    setPaymentPlans(val);
    setTempIndex(null);
    setTempMilestone(null);
    setTempPlan(null);
  };


  const [rows, setRows] = useState([
    {
      id: 1,
      date: '',
      facility: '',
      jobType: '',
      startTime: '',
      endTime: '',
      lunchBreak: '',
      totalHours: '',
      patientsSeen: '',
      comment: '',
      status: '',
    }
  ]);

  useEffect(() => {
    // getStartAndEndOfWeek()
    setLoading(true)
    const payload = null;
    dispatch(
      getFacilityByProviderId(payload, providerId,
        (data) => {
          setLoading(false);
          setFacilityData(data?.data?.Data)
        },
        (error) => {
          console.log(error)
          if (error?.response?.data?.code === "token_not_valid") {
            localStorage.clear();
            navigate('/');
          }
          setLoading(false)
        }
      )
    );

  }, [updated])

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getTimeSheetByProvider(payload, providerId,
        (data) => {
          setLoading(false);
          setTimeSheetData(data?.data?.Data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [updatTimeSheet, updated])

  // console.log(timeSheetData)
  const handleEditModal = (row) => {
    console.log("row", row);
    setId(row?.id)
    setShowModal(true);
    setEditDate(dayjs(row?.date));
    setEditProvider(row?.user);
    setEditFacility(row?.facility_id);
    if (row?.type_of_job === "Office") {
      setEditJobType("Employee_Office_Work")
    }
    else if (row?.type_of_job === "Administration") {
      setEditJobType("Employee_Admin_Work")
    }

    else {
      setEditJobType(row?.type_of_job)
    }
    // setEditJobType(row?.type_of_job);
    setEditStartTime(row?.start_time);
    setEditEndTime(row?.end_time);
    setEditLunchBreak(row?.lunch_break);
    setEditTotalHours(row?.total_hours);
    setEditPatientSeen(row?.patient_seen);
    setEditComment(row?.comment);
    setEditStatus(row?.status);
    setEditRemarks(row?.remarks);
  };

  useEffect(() => {
    if (rows.length === 0) {
      // Ensure that at least one row remains by default
      setRows([
        {
          id: 1,
          date: '',
          facility: '',
          jobType: '',
          startTime: '',
          endTime: '',
          lunchBreak: '',
          totalHours: '',
          patientsSeen: '',
          comment: '',
          status: '',
        }
      ]);
    }
  }, [rows]);


  const addRow = () => {
    const newRow = {
      id: rows.length + 1, // Generate a unique ID for the new row
      date: date,
      facility: facility,
      jobType: jobType,
      startTime: convertToHHMMSS(startTime),
      endTime: convertToHHMMSS(endTime),
      lunchBreak: lunchBreak,
      totalHours: totalHours,
      patientsSeen: patientsSeen,
      comment: comment,
      status: status,
    };

    // Update the state to add the new row
    setRows(prevRows => [...prevRows, newRow]);

    // Clear the form fields after adding the new row
    setDate(today.toISOString().split('T')[0]);
    setFacility("");
    if (userRole === "Provider") {
      setJobType("");
    }
    // setJobType("");
    setStartTime("");
    setEndTime("");
    setLunchBreak("");
    setTotalHours("");
    setPatientsSeen("");
    setComment("");
    setStatus("");
  };

  const handleChange = (event, fieldName, rowId) => {
    const updatedRows = rows.map((row) => {
      if (row.id === rowId) {
        return {
          ...row,
          [fieldName]: event.target.value,
        };
      }
      return row;
    });
    setRows(updatedRows);
  };


  const toggleRowSelection = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter(id => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  const deleteRow = () => {
    if (selectedRows.length > 0) {
      const updatedRows = rows.filter(row => !selectedRows.includes(row.id));
      setRows(updatedRows);
      setSelectedRows([]);
    } else if (rows.length > 1) {
      const updatedRows = [...rows];
      updatedRows.pop();
      setRows(updatedRows);
    }
  };

  const handleSave = () => {
    const defaultRow = {
      id: 1,
      date: date,
      facility: facility,
      jobType: jobType,
      startTime: convertToHHMMSS(startTime),
      endTime: convertToHHMMSS(startTime),
      lunchBreak: lunchBreak,
      totalHours: totalHours,
      patientsSeen: patientsSeen,
      comment: comment,
      status: status,
    };

    setSavedData([...savedData, ...rows]);
    setRows([defaultRow]);
    // handleAddNewBiller(defaultRow)
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allCodes = timeSheetData.map((row) => row.id);
      setSelectedRows(allCodes);
      // Set all rows id in id state
      //   setId(allCodes);
    } else {
      setSelectedRows([]);
      //   setId([]);
    }
  };

  console.log(paymentPlans)

  const handleAddNewBiller = (e) => {
    e.preventDefault();


    const tenDaysAgo = dayjs().subtract(10, 'day');
    if (dayjs(date).isBefore(tenDaysAgo)) {
      const errorMessage = 'Date cannot be more than 10 days in the past';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    // handleSave()
    if (dayjs(date).isAfter(currentDate)) {
      const errorMessage = 'Date cannot be in the future';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }
    if (!date || !startTime || !endTime || !lunchBreak) {
      setSnackbarMessage("Please fill all the fields");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
    }

    else {
      const payload = {
        user: providerId,
        date: date.format('MM/DD/YYYY'),
        facility: facility,
        type_of_job: jobType === "Employee_Office_Work" ? "Office" : jobType === "Employee_Admin_Work" ? "Administration" : jobType,
        start_time: convertToHHMMSS(startTime),
        end_time: convertToHHMMSS(endTime),
        lunch_break: lunchBreak,
        patient_seen: patientSeen,
        remarks: remarks
      };

      setLoading(true);
      dispatch(
        addTimeSheetByProvider(
          [payload],
          (data) => {
            setLoading(false);
            setSnackbarMessage("Time Sheet added successfully");
            setSnackbarOpen(true);
            setSnackbarSeverity("success");
            // setDate(today.toISOString().split('T')[0])
            setDate(dayjs())
            setFacility("")
            if (userRole === "Provider") {
              setJobType("");
            }
            setStartTime("")
            setEndTime("")
            setLunchBreak("")
            setPatientSeen("")
            setRemarks("")
            setPaymentPlans([])
            setUpdateTimeSheet(prevState => !prevState)

          },
          (error) => {
            console.log(error);
            setLoading(false);
            const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
            setSnackbarMessage(errorMessage);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        )
      );
    }


  };

  const handleSaveNewState = () => {
    if (!editDate || !editStartTime || !editEndTime || !editLunchBreak) {
      setSnackbarMessage("Please fill all the fields");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
    }

    if (dayjs(date).isAfter(currentDate)) {
      const errorMessage = 'Date cannot be in the future';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const payload = {
      timesheet_id: id,
      date: editDate.format('MM/DD/YYYY'),
      // provider: editProvider,
      facility: editFacility,
      type_of_job: editJobType,
      start_time: convertToHHMMSS(editStartTime),
      end_time: convertToHHMMSS(editEndTime),
      lunch_break: editLunchBreak,
      // total_hours: editTotalHours,
      patient_seen: editPatientSeen,
      // comment: editComment,
      // status: editStatus,
      remarks: editRemarks
    };

    console.log(payload)

    setLoading(true);
    dispatch(
      updateTimeSheetByProvider(
        payload,
        editId,
        (data) => {
          setLoading(false);
          setUpdated(prevState => !prevState);
          setSnackbarMessage("Time Sheet updated successfully");
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          // setOpenModal(false);
          // setUpdated(prevState => !prevState);
          // setFacilityCode("")
          // setFacilityName("")
          // setEmail("")
          // setPhoneNumber("")
          // setStatus("")
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
    setShowModal(false);
  };

  const handleDeleteTimesheet = (id) => {
    console.log("id", id)
    const payload = {
      timesheet_id: id
    };

    setLoading(true);
    dispatch(
      deleteTimeSheetByProvider(
        payload,
        (data) => {
          setLoading(false);
          setSnackbarMessage("Timesheet deleted successfully");
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          // setEditModalOpen(false);
          setUpdated(prevState => !prevState);
          setOpenDeleteModal(false)


        },
        (error) => {
          console.log(error);
          setLoading(false);
          const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
  };


  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };


  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
  const endIndex = startIndex + ROWS_PER_PAGE;

  // useEffect(() => {
  //   const { firstDayOfWeek, lastDayOfWeek } = getStartAndEndOfWeek();
  //   setLastDay(dayjs(lastDayOfWeek));
  //   setFirstDay(dayjs(firstDayOfWeek));
  // }, []);

  // function getStartAndEndOfWeek() {
  //   const today = new Date();
  //   const firstDayOfWeek = new Date(today); // Current date
  //   const lastDayOfWeek = new Date(today);
  //   lastDayOfWeek.setDate(today.getDate() - 10); // 10 days before the current date

  //   return { firstDayOfWeek, lastDayOfWeek };
  // }

  const handleEditClick = (event, selectedFacility) => {
    console.log('in')
  };

  const handleDeleteModal = (id) => {
    // setOpenDeleteModal(true);
    // setId(id)
    console.log('in')

  };


  const downloadSelectedRows = () => {
    // Filter rows based on selectedRows array
    const exportData = timeSheetData
      .filter(row => selectedRows.includes(row.id))
      .map(row => ({
        'Date': row.date,
        'Job Type': row.type_of_job,
        'Start Time': convertTo12HourFormat(row.start_time),
        'End Time': convertTo12HourFormat(row.end_time),
        'Break': row.lunch_break,
        'Total Hours': row.total_hours.replace(/-\d+ day[s]?, /, ""),
        'Comment': row.comment,
        'Status': row.status,
        'Remarks': row.remarks,
      }));


    // Add total hours row
    const totalRow = {
      'Date': '', // If needed, adjust based on your columns
      'Job Type': '',
      'Start Time': '',
      'End Time': '',
      'Break': '',
      'Total Hours': `Total Hours: ${totalSelectedHours}`, // Format the total hours row as needed
      'Comment': '',
      'Status': '',
      'Remarks': '',
    };

    exportData.push(totalRow);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Convert data to worksheet
    const ws = XLSX.utils.json_to_sheet(exportData);

    // Define column widths (adjust as needed)
    const columnWidths = [
      { wch: 20 }, // Width of Date column
      { wch: 20 }, // Width of Job Type column
      { wch: 30 }, // Width of Start Time column
      { wch: 30 }, // Width of End Time column
      { wch: 10 }, // Width of Break column
      { wch: 40 }, // Width of Total Hours column
      { wch: 30 }, // Width of Comment column
      { wch: 15 }, // Width of Status column
      { wch: 30 }, // Width of Remarks column
    ];

    // Set column widths
    ws['!cols'] = columnWidths;

    // Add worksheet to workbook
    XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

    // Save the workbook as an Excel file
    XLSX.writeFile(wb, 'selected_rows_data.xlsx');
    setSelectAll(false)
    setSelectedRows([])
  };

  const handlePlanChange = (value, index, fieldName) => {
    setPaymentPlans(prevPlans => {
      const updatedPlans = [...prevPlans];
      updatedPlans[index][fieldName] = value;
      return updatedPlans;
    });
  };

  //   const [sortedData, setSortedData] = useState([]);

  //   useEffect(() => {
  //     if (timeSheetData?.length) {
  //         const sorted = [...timeSheetData].sort((a, b) => b.id - a.id);
  //         setSortedData(sorted);
  //     }
  // }, [timeSheetData]);

  const [totalPageHours, setTotalPageHours] = useState(0);

  const parseTimeString = (timeString) => {
    // Regular expression to match and remove "-N day(s), "
    const cleanedTimeString = timeString.replace(/-\d+ day[s]?, /, '');

    const [hours, minutes, seconds] = cleanedTimeString.split(':').map(Number);
    return {
      hours: hours || 0,
      minutes: minutes || 0,
      seconds: seconds || 0
    };
  };

  const sumTotalHours = (timeSheetData) => {
    let totalHours = 0;
    let totalMinutes = 0;
    let totalSeconds = 0;

    // Iterate over each row in timeSheetData to parse and sum up hours, minutes, and seconds
    timeSheetData.forEach(row => {
      const { hours, minutes, seconds } = parseTimeString(row.total_hours);
      totalHours += hours;
      totalMinutes += minutes;
      totalSeconds += seconds;
    });

    // Handle overflow of seconds and minutes
    totalMinutes += Math.floor(totalSeconds / 60);  // Add overflow seconds to minutes
    totalSeconds = totalSeconds % 60;               // Remainder of seconds
    totalHours += Math.floor(totalMinutes / 60);    // Add overflow minutes to hours
    totalMinutes = totalMinutes % 60;               // Remainder of minutes

    // Format the total time as a string
    return `${totalHours.toString().padStart(2, '0')} Hours ${totalMinutes.toString().padStart(2, '0')} Minutes`;
    // Optionally, you can return it in HH:MM:SS format:
    // return `${totalHours.toString().padStart(2, '0')}:${totalMinutes.toString().padStart(2, '0')}:${totalSeconds.toString().padStart(2, '0')}`;
  };

  // const totalPageHours = sumTotalHours(timeSheetData.slice(startIndex, endIndex));

  useEffect(() => {
    const pageData = timeSheetData.filter(row => selectedRows.includes(row.id));
    const totalHours = sumTotalHours(pageData);

    setTotalSelectedHours(totalHours);
  }, [selectedRows, timeSheetData]);

  useEffect(() => {
    const pageData = timeSheetData.slice(startIndex, endIndex);
    const totalHours = sumTotalHours(pageData);
    setTotalPageHours(totalHours);
  }, [currentPage, ROWS_PER_PAGE, timeSheetData]);

  console.log("Total Hours", totalPageHours)

  const handleCloseModal = () => {
    setShowModal(false);
  };



  return (
    <Box sx={{ px: { md: 7, xs: 5 }, pb: 3 }}>
      <DeleteModal
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        loading={loading}
        setLoading={setLoading}
        handleDeletefunction={handleDeleteTimesheet}
        id={id}
      />
      <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 3 }}>Time Sheet</Typography>

      <TableContainer component={Paper} className='scrollBar'>
        <Table>
          <TableHead sx={{ backgroundColor: '#0093AF', py: 0 }}>
            <TableRow>
              {/* <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }} /> */}
              <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Date*</TableCell>
              {userRole === "Biller"
                ?
                ""
                :
                <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Facility*</TableCell>

              }

              {userRole === "Biller"
                ?
                ""
                :
                <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Job Type*</TableCell>

              }
              <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Start Time*</TableCell>
              <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>End Time*</TableCell>
              <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Break*</TableCell>

              {userRole === "Provider"
                ?
                <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Patient Seen</TableCell>
                :
                ""
              }

              <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Remarks</TableCell>

            </TableRow>
          </TableHead>
          <TableBody>

            <TableRow>
              {/* <TableCell sx={{ py: 1 }}>
                  <Checkbox
                    style={{ color: '#0093AF' }}
                    checked={selectedRows.includes(row.id)}
                    onChange={() => toggleRowSelection(row.id)}
                  />
                </TableCell> */}
              <TableCell sx={{ py: 1 }}>
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={date}
                    onChange={(newValue) => setDate(newValue)}
                    className='tableCellWidth'
                    disableFuture
                    renderInput={(params) => (
                      <TextField
                        {...params}

                        variant="outlined"
                        className='tableCellWidth'
                        style={{ height: '10px !important' }}
                      />
                    )}
                    minDate={firstDay}
                    maxDate={lastDay}
                    shouldDisableDate={(date) => date.isBefore(firstDay) || date.isAfter(lastDay)}
                  />
                </LocalizationProvider> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(date)}
                    className='tableCellWidth'
                    onChange={(newValue) => {
                      if (newValue) {
                        setDate(newValue);
                      }
                    }}
                    disableFuture
                    minDate={firstDay}
                    maxDate={lastDay}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                          placeholder: '',
                          style: { height: '2rem', width: '10rem !important' },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </TableCell>

              {userRole === "Biller"
                ?
                ""
                :
                <TableCell sx={{ py: 1 }}>
                  <Select
                    className='tableCellWidth'
                    value={facility}
                    onChange={(e) => setFacility(e.target.value)}
                    sx={{ minWidth: '100px', p: 0, m: 0 }}
                    InputProps={{
                      style: {
                        height: '2rem',
                      },
                    }}
                  >
                    {facilityData?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.facility_name}
                      </MenuItem>
                    ))}

                  </Select>
                </TableCell>

              }


              {
                userRole === "Biller" ? "" :
                  <TableCell sx={{ py: 1 }}>
                    {

                      userRole === "Provider" ?
                        <Select
                          disabled={userRole !== "Provider"}
                          className='tableCellWidth'
                          value={jobType}
                          onChange={(e) => setJobType(e.target.value)}
                          sx={{ minWidth: '100px', p: 0, m: 0 }}
                        >
                          <MenuItem value="Consultation">Consultation</MenuItem>
                          <MenuItem value="Procedure">Procedure</MenuItem>
                        </Select>

                        :
                        <Select
                          disabled={userRole !== "Provider"}
                          className='tableCellWidth'
                          value={jobType}
                          onChange={(e) => setJobType(e.target.value)}
                          sx={{ minWidth: '100px', p: 0, m: 0 }}
                        >
                          <MenuItem value="Employee_Admin_Work">Administration</MenuItem>
                          <MenuItem value="Employee_Office_Work">Office</MenuItem>
                        </Select>
                    }

                  </TableCell>
              }
              <TableCell sx={{ py: 1 }}>
                <TextField
                  type="time"
                  value={startTime}
                  onChange={(e) => setStartTime(e.target.value)}
                  className='tableCellWidth'
                  InputProps={{ style: { minWidth: '100px', padding: 0, margin: 0, height: '35px' } }}

                />
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <TextField
                  type="time"
                  value={endTime}
                  onChange={(e) => setEndTime(e.target.value)}
                  className='tableCellWidth'
                  InputProps={{ style: { minWidth: '100px', padding: 0, margin: 0, height: '35px' } }}
                />
              </TableCell>
              <TableCell sx={{ py: 1 }}>
                <Select
                  className='tableCellWidth'
                  value={lunchBreak}
                  onChange={(e) => setLunchBreak(e.target.value)}
                  sx={{ minWidth: '100px', p: 0, m: 0 }}
                >
                  <MenuItem value="none">No Break</MenuItem>

                  <MenuItem value="30 minutes">30 minutes</MenuItem>
                  {/* <MenuItem value="40 minutes">40 minutes</MenuItem>
                  <MenuItem value="50 minutes">50 minutes</MenuItem> */}
                  <MenuItem value="60 minutes">60 minutes</MenuItem>
                </Select>
              </TableCell>

              {userRole === "Provider"
                ?
                <TableCell sx={{ py: 1 }}>
                  <TextField
                    type="number"
                    value={patientSeen}
                    onChange={(e) => {
                      const value = e.target.value;
                      // Ensure the value is a number
                      if (/^\d*$/.test(value)) {
                        setPatientSeen(value);
                      }
                    }}
                    className='tableCellWidth'
                    InputProps={{ style: { minWidth: '100px', padding: 0, margin: 0, height: '35px' } }}
                  />
                </TableCell>
                :
                ""
              }

              <TableCell sx={{ py: 1 }}>
                <TextField
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                  className='tableCellWidth'
                  InputProps={{ style: { minWidth: '100px', padding: 0, margin: 0, height: '35px' } }}
                />
              </TableCell>

            </TableRow>

          </TableBody>

        </Table>
      </TableContainer>

      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
        <Button variant="contained" onClick={handleAddNewBiller} sx={{ mr: 2, background: '#0093AF !important' }}>Save</Button>
        {/* <Button variant="contained" onClick={deleteRow} sx={{ mr: 2 }}>Delete Row</Button> */}
      </Box>

      {/* {paymentPlans.length > 0 && (
        <Box>
          <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 3 }}>Ready for Submission</Typography>

          <TableContainer component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#0093AF', py: 0 }}>
                <TableRow>
                  <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Date</TableCell>
                  {userRole === "Biller"
                    ?
                    ""
                    :
                    <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Facility</TableCell>

                  }

                  {userRole === "Biller"
                    ?
                    ""
                    :
                    <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Job Type</TableCell>

                  }
                  <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Start Time</TableCell>
                  <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>End Time</TableCell>
                  <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Break</TableCell>

                  {userRole === "Provider"
                    ?
                    <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Patient Seen</TableCell>
                    :
                    ""
                  }
                  <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Remarks</TableCell>

                  <TableCell sx={{ color: '#fff', whiteSpace: 'nowrap', py: 0.5 }}>Action</TableCell>

                </TableRow>
              </TableHead>
              <TableBody>
                {paymentPlans.map((plan, index) => (
                  <TableRow key={index}>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>{plan?.date}</TableCell>
                    {userRole === "Biller"
                      ?
                      ""
                      :
                      <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>{facilityData.find(item => item.id === plan.facility)?.facility_name}</TableCell>
                    }

                    {userRole === "Biller"
                      ?
                      ""
                      :
                      <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>
                        {plan.type_of_job}
                      </TableCell>

                    }

                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>{convertTo12HourFormat(plan.start_time)}</TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>{convertTo12HourFormat(plan.end_time)}</TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>{plan.lunch_break}</TableCell>

                    {userRole === "Provider"
                      ?
                      <TableCell>{plan.patient_seen}</TableCell>

                      :
                      ""
                    }
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap', py: 1 }}>{plan.remarks}</TableCell>
                    <TableCell sx={{ whiteSpace: 'nowrap', py: 1 }}>
                      <IconButton onClick={() => handleRemoveFromPlan(index)} color="error">
                        <DeleteIcon className='fs12' />
                      </IconButton>
                    </TableCell>

                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button variant="contained" onClick={handleAddNewBiller} sx={{ mt: 2, float: 'right', background: '#0093AF !important' }} >{loading ? <CircularProgress size={24} sx={{ color: '#fff !important' }} /> : 'Submit'}</Button>

        </Box>

      )} */}

      {timeSheetData?.length !== 0 && (
        <Box sx={{ mt: 8 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
            {/* <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 3 }}>Submitted Data</Typography> */}
            <Typography sx={{ fontSize: '14px', my: 2 }}> <span style={{ fontWeight: 'bold' }}>Total Working Hours:</span> {totalPageHours}</Typography>

            <Button sx={{
              float: 'right',
              background: '#0093AF',
              '&:hover': {
                backgroundColor: '#0093AF',
              },
            }}
              variant="contained" onClick={downloadSelectedRows} disabled={selectedRows.length === 0}>
              Download Excel
            </Button>
          </Box>

          <TableContainer className='scrollBar' component={Paper}>
            <Table>
              <TableHead sx={{ backgroundColor: '#0093AF', py: 0 }}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectAll}
                      onChange={handleSelectAllChange}
                      style={{ color: '#fff' }}
                    />
                  </TableCell>
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Date</TableCell>
                  {userRole === "Biller"
                    ?
                    ""
                    :
                    <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Facility</TableCell>

                  }

                  {userRole === "Biller"
                    ?
                    ""
                    :
                    <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Type of Job</TableCell>

                  }

                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Start Time</TableCell>
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>End Time</TableCell>
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Break</TableCell>
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Total Hours</TableCell>
                  {userRole === "Provider"
                    ?
                    <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Patients Seen</TableCell>
                    :
                    ""
                  }
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Remarks</TableCell>

                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Comment</TableCell>
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Status</TableCell>
                  <TableCell sx={{ color: '#fff', py: 1, whiteSpace: 'nowrap' }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(timeSheetData ? timeSheetData?.slice(startIndex, endIndex) : []).map((row, index) => (
                  <TableRow key={row.id}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        style={{ color: '#0093AF' }}
                        checked={selectedRows.includes(row.id)}
                        onChange={() => toggleRowSelection(row.id)}
                      />
                    </TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.date}</TableCell>
                    {userRole === "Biller"
                      ?
                      ""
                      :
                      <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.facility}</TableCell>

                    }

                    {userRole === "Biller"
                      ?
                      ""
                      :
                      <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.type_of_job}</TableCell>

                    }

                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{convertTo12HourFormat(row.start_time)}</TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{convertTo12HourFormat(row.end_time)}</TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.lunch_break}</TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.total_hours.replace(/-\d+ day[s]?, /, "")}</TableCell>

                    {userRole === "Provider"
                      ?
                      <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.patient_seen}</TableCell>
                      :
                      ""
                    }
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.remarks}</TableCell>

                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.comment}</TableCell>
                    <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row.status}</TableCell>
                    {/* <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.biller_profile !== null ? row?.biller_profile?.First_Name + " " + row?.biller_profile?.Last_Name : ""}</TableCell> */}
                    <TableCell sx={{ textAlign: 'center', display: 'flex', whiteSpace: 'nowrap', py: 0 }}>
                      {row.status === "Pending" ?
                        <Box sx={{ p: 1 }}>
                          <IconButton disabled={row.status !== "Pending"} color="primary" onClick={() => handleEditModal(row)}>
                            <EditIcon sx={{ color: row.status !== "Pending" ? "#d3d3d3" : '#0093AF', fontSize: "16px" }} />
                          </IconButton>
                          <IconButton
                            disabled={row.status !== "Pending"}
                            color="secondary"
                            onClick={(e) => {
                              setOpenDeleteModal(true);
                              setId(row?.id);
                            }}
                          >
                            <DeleteIcon sx={{ color: row.status !== "Pending" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                          </IconButton>
                        </Box>
                        :
                        <Box sx={{ p: 3 }}></Box>

                      }

                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {/* <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button variant="contained" sx={{ mr: 2 }}>Submit</Button>

          </Box> */}

          <Box sx={{ display: 'flex', justifyContent: 'right', verticalAlign: 'middle', alignItems: 'center' }}>
            <CustomPagination
              count={Math.ceil(timeSheetData.length / ROWS_PER_PAGE)}
              page={currentPage}
              onChange={handleChangePage}
            />
          </Box>


        </Box>
      )}


      <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
            <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Timesheet</Typography>
            <IconButton onClick={handleCloseModal}>
              <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
            </IconButton>
          </Box>
          <Box sx={{ my: 2 }}>
            <Grid container spacing={3}>
              <Grid item md={6}>
                <InputLabel>Edit Date</InputLabel>

                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={dayjs(editDate)}
                    className='tableCellWidth'
                    onChange={(newValue) => {
                      if (newValue) {
                        setEditDate(newValue);
                      }
                    }}
                    // fullWidth
                    disableFuture
                    sx={{ width: '16rem !important' }}
                    minDate={firstDay}
                    maxDate={lastDay}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        InputProps={{
                          placeholder: '',
                          style: { height: '2rem', },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={editDate}
                    onChange={(newValue) => setEditDate(newValue)}
                    disableFuture
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider> */}
              </Grid>
              {userRole !== "Biller" && (
                <Grid item md={6}>
                  <InputLabel>Edit Facility</InputLabel>
                  <Select
                    value={editFacility}
                    onChange={(e) => setEditFacility(e.target.value)}
                    fullWidth
                  >
                    {facilityData?.map((item, index) => (
                      <MenuItem key={index} value={item.id}>
                        {item.facility_name}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              )}
              {userRole !== "Biller" && (
                <Grid item md={6}>
                  <InputLabel>Edit Job Type</InputLabel>
                  <Select
                    value={editJobType}
                    onChange={(e) => setEditJobType(e.target.value)}
                    fullWidth
                    disabled={userRole !== "Provider"}
                  >
                    {userRole === "Provider" ? (
                      [
                        <MenuItem key="Consultation" value="Consultation">Consultation</MenuItem>,
                        <MenuItem key="Procedure" value="Procedure">Procedure</MenuItem>
                      ]
                    ) : (
                      [
                        <MenuItem key="Employee_Admin_Work" value="Employee_Admin_Work">Administration</MenuItem>,
                        <MenuItem key="Employee_Office_Work" value="Employee_Office_Work">Office</MenuItem>
                      ]
                    )}
                  </Select>
                </Grid>
              )}
              <Grid item md={6}>
                <InputLabel>Edit Start Time</InputLabel>
                <TextField
                  type="time"
                  value={editStartTime}
                  onChange={(e) => setEditStartTime(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <InputLabel>Edit End Time</InputLabel>
                <TextField
                  type="time"
                  value={editEndTime}
                  onChange={(e) => setEditEndTime(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item md={6}>
                <InputLabel>Edit Break</InputLabel>
                <Select
                  value={editLunchBreak}
                  onChange={(e) => setEditLunchBreak(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="none">No Break</MenuItem>
                  <MenuItem value="30 minutes">30 minutes</MenuItem>
                  <MenuItem value="60 minutes">60 minutes</MenuItem>
                </Select>
              </Grid>
              {userRole === "Provider" && (
                <Grid item md={6}>
                  <InputLabel>Edit Patient Seen</InputLabel>
                  <TextField
                    type="number"
                    value={editPatientSeen}
                    onChange={(e) => setEditPatientSeen(e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}
              <Grid item md={6}>
                <InputLabel>Edit Remarks</InputLabel>
                <TextField
                  value={editRemarks}
                  onChange={(e) => setEditRemarks(e.target.value)}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Box>
          <Button
            variant="contained"
            onClick={() => handleSaveNewState(
              //   {
              //   date: editDate,
              //   facility: editFacility,
              //   jobType: editJobType,
              //   startTime: editStartTime,
              //   endTime: editEndTime,
              //   lunchBreak: editLunchBreak,
              //   patientSeen: editPatientSeen,
              //   remarks: editRemarks
              // }
            )}
            sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
          >
            Save
          </Button>
        </Box>
      </Modal>



      <CustomSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

    </Box>
  );
};

export default BillerTimeSheetTable;
