
import { useState } from 'react';
import VisitReasonBody from '../Component/VisitReasonBody';
import Layout from '../Layout';


export default function VisitReason() {

 
    return (

        <Layout>
            <VisitReasonBody  />
        </Layout>
    )
}