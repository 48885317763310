import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Modal,
    InputLabel,
    CircularProgress, Select
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CloseIcon from '@mui/icons-material/Close';
import CustomTextField from '../../../Utils/CustomTextfield';
import { useDispatch } from 'react-redux';
import { deleteAdmin, getAllAdmins, registeration, updateAdmin } from '../../../api/action';
import CustomSnackbar from '../../../Utils/CustomSnackbar';
import CustomPagination from '../../../Utils/CustomPagination';
import DeleteModal from '../../../Utils/DeleteModal';
import { phoneRegex } from '../../../Utils/Regex';

const ROWS_PER_PAGE = 10; // Number of rows per page

const AdminsBody = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [searchAdminName, setSearchAdminName] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [searchPhoneNumber, setSearchPhoneNumber] = useState('');
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedAdmin, setSelectedAdmin] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [newAdminName, setNewAdminName] = useState('');
    const [newAdminEmail, setNewAdminEmail] = useState('');
    const [newAdminPhoneNumber, setNewAdminPhoneNumber] = useState('');
    const [newAdminPassword, setNewAdminPassword] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [editAdminName, setEditAdminName] = useState('');
    const [editAdminEmail, setEditAdminEmail] = useState('');
    const [editAdminPhoneNumber, setEditAdminPhoneNumber] = useState('');
    const [editAdminPassword, setEditAdminPassword] = useState('');
    const [data, setData] = useState([]);
    const dispatch = useDispatch();
    const [currentPage, setCurrentPage] = useState(1);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [loading, setLoading] = useState('');
    const [updated, setUpdated] = useState(false);
    const [adminId, setAdminID] = useState("");
    const [emailError, setEmailError] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [newAdminUserRole, setNewAdminUserRole] = useState('Admin');
    const userRole = localStorage.getItem("userRole")
    const [editStatus, setEditStatus] = useState(false);
    const [userId, setUserId] = useState('');


    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllAdmins(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])


    const handleOpenEditModal = (selectedRow) => {
        setEditModalOpen(true);
        // Populate state variables with selected row data
        setEditAdminName(selectedRow.Full_Name);
        setEditAdminEmail(selectedRow.email);
        setEditAdminPhoneNumber(selectedRow.Phone_number);
        setEditAdminPassword(selectedRow.password);
        setAdminID(selectedRow.id);
        setEditStatus(selectedRow.is_active)
        setUserId(selectedRow.user_id)

    };

    const handleSelectAll = (event) => {
        if (event.target.checked) {
            const selectedCodes = data.map((row) => row.facilityCode);
            setSelectedRows(selectedCodes);
        } else {
            setSelectedRows([]);
        }
        setSelectAll(event.target.checked);
    };

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        let updatedSelectedRows = [];
        if (isSelected) {
            updatedSelectedRows = selectedRows.filter((code) => code !== facilityCode);
        } else {
            updatedSelectedRows = [...selectedRows, facilityCode];
        }
        setSelectedRows(updatedSelectedRows);
        setSelectAll(updatedSelectedRows.length === data.length);
    };


    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    const handleAddNew = () => {
        setShowModal(true);
    }

    useEffect(() => {
        setCurrentPage(1)
    }, [searchAdminName, searchAdminName, searchPhoneNumber])

    const filteredData = data.filter((row) => {
        const matchesAdminName = row.Full_Name.toLowerCase().includes(searchAdminName.toLowerCase());
        const matchesEmail = row.email.toLowerCase().includes(searchEmail.toLowerCase());
        const matchesPhoneNumber = row.phoneNumber ? row.phoneNumber.includes(searchPhoneNumber) : true;
        return matchesAdminName && matchesEmail && matchesPhoneNumber;
    });

    const handleClearSearch = () => {
        setSearchAdminName('');
        setSearchEmail('');
        setSearchPhoneNumber('');
    };

    const handleCloseModal = () => {
        setShowModal(false);
        // Reset input fields when modal is closed
        setNewAdminName('');
        setNewAdminEmail('');
        setNewAdminPhoneNumber('');
        setNewAdminPassword('');
    };


    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };

    const handleAddNewAdmin = (e) => {
        e.preventDefault();

        if (!newAdminEmail || !newAdminPassword || !newAdminName || !newAdminPhoneNumber) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!emailRegex.test(newAdminEmail)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid email address.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!phoneRegex.test(newAdminPhoneNumber)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            email: newAdminEmail,
            password: newAdminPassword,
            password2: newAdminPassword,
            full_name: newAdminName,
            phone_number: newAdminPhoneNumber,
            user_role: newAdminUserRole,
            is_active: true

        };

        setLoading(true);
        dispatch(
            registeration(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Admin added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setShowModal(false);
                    setUpdated(prevState => !prevState);
                    setNewAdminEmail("")
                    setNewAdminPassword("")
                    setNewAdminName("")
                    setNewAdminPhoneNumber("")
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateAdmin = (e) => {
        e.preventDefault();

        if (!editAdminPassword || !editAdminName || !editAdminPhoneNumber || !editAdminPassword) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!phoneRegex.test(editAdminPhoneNumber)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {

            password: editAdminPassword,
            full_name: editAdminName,
            admin_id: adminId,
            Phone_number: editAdminPhoneNumber,
            password: editAdminPassword,
            is_active: editStatus,
            user_id: userId,
        };

        setLoading(true);
        dispatch(
            updateAdmin(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Admin updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);


                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteAdmin = (user_id) => {
        const payload = {
            user_id
        };

        setLoading(true);
        dispatch(
            deleteAdmin(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Admin deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setAdminID(id)
    };

    // Pagination Handlers
    const handleChangePage = (event, newPage) => {
        setCurrentPage(newPage);
    };

    const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
    // Slice the data array to get only the data for the current page
    const paginatedData = filteredData.slice(startIndex, startIndex + ROWS_PER_PAGE);
    // Calculate the end index of the data for the current page
    const endIndex = startIndex + ROWS_PER_PAGE;

    return (
        <Box sx={{ px: 10, py: 2 }}>
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteAdmin}
                id={adminId}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>Search Admin</Typography>
            <Grid container spacing={5} >
                <Grid item md={4} xs={6}>
                    <InputLabel>Admin Name</InputLabel>
                    <CustomTextField
                        value={searchAdminName}
                        setValue={setSearchAdminName}
                    />
                </Grid>

                <Grid item md={4} sm={6} xs={6}>
                    <Box sx={{ display: 'flex', justifyContent: 'right' }}>
                        <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleClearSearch}
                            sx={{ background: '#0093AF !important', boxShadow: 'none !important', my: 3, mx: 1 }}
                        >
                            Clear
                        </Button>
                        <Button
                            disabled={userRole !== "Admin"}
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleAddNew}
                            sx={{ background: userRole !== "Admin" ? "#d3d3d3" : '#0093AF !important', boxShadow: 'none !important', my: 3, mx: 1 }}
                        >
                            Add New
                        </Button>
                        {/* <Button
                            className='buttonPadding'
                            variant="contained"
                            onClick={handleDelete}
                            disabled={selectedRows.length === 0}
                            sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', my: 3, mx: 1 }}
                        >
                            Delete
                        </Button> */}
                    </Box>
                </Grid>
            </Grid>

            {loading ? ( // Render CircularProgress while loading is true
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '40vh' }}>
                    <CircularProgress sx={{color:'#0093AF !important'}} />
                </Box>
            ) : (
                filteredData.length === 0 ? (
                    <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                        No Data Available
                    </Typography>
                ) : (
                    <>
                        <TableContainer component={Paper} className='scrollBar'>
                            <Table>
                                <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                    <TableRow>
                                        <TableCell sx={{ py: 0, color: '#fff', display: 'flex', justifyContent: 'left', alignItems: 'center', verticalAlign: 'middle' }}>
                                            <Checkbox
                                                checked={selectAll}
                                                onChange={handleSelectAll}
                                                sx={{ color: '#fff !important' }}
                                            />
                                        </TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0 }}>Admin Name</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0 }}>Email ID</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0 }}>Phone Number</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0 }}>Role</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0, whiteSpace: 'nowrap' }}>Status</TableCell>
                                        <TableCell sx={{ color: '#fff', py: 0 }}>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {filteredData.slice(startIndex, endIndex).map((row) => (
                                        <TableRow key={row.facilityCode}>
                                            <TableCell sx={{ py: 0.3 }}>
                                                <Checkbox
                                                    size='small'
                                                    checked={selectedRows.includes(row.user_id)}
                                                    onChange={() => handleCheckboxChange(row.user_id)}
                                                    style={{ color: '#0093AF' }}
                                                />
                                            </TableCell>
                                            <TableCell className='fs12' sx={{ py: 0 }}>{row.Full_Name}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0 }}>{row.email}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0 }}>{row.Phone_number}</TableCell>
                                            <TableCell className='fs12' sx={{ py: 0 }}>
                                                {row.user_role === "Admin" ? "Super Admin" :
                                                    row.user_role === "Admin Operations" ? "Operation Manager" :
                                                        row.user_role === "Admin Finance" ? "Finance Manager" : ""}
                                            </TableCell>
                                            <TableCell className='fs12' sx={{ py: 0.3, whiteSpace: 'nowrap' }}>{row?.is_active === true ? "Active" : "Inactive"}</TableCell>
                                            <TableCell sx={{ py: 0.3 }}>
                                                <IconButton disabled={userRole !== "Admin"} color="primary" onClick={() => handleOpenEditModal(row)}>
                                                    <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                </IconButton>
                                                <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteModal(row.user_id)}>
                                                    <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: '16px' }} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <CustomPagination
                            count={Math.ceil(filteredData.length / ROWS_PER_PAGE)}
                            page={currentPage}
                            onChange={handleChangePage}
                        />
                    </>
                ))}



            {/* ADD MODAL  */}

            <Modal open={showModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add New Admin</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Admin Name</InputLabel>
                                <CustomTextField
                                    label="Admin Name"
                                    value={newAdminName}
                                    setValue={setNewAdminName}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    label="Email"
                                    value={newAdminEmail}
                                    setValue={setNewAdminEmail}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    label="Phone Number"
                                    value={newAdminPhoneNumber}
                                    setValue={setNewAdminPhoneNumber}
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    label="Password"
                                    value={newAdminPassword}
                                    setValue={setNewAdminPassword}
                                />
                            </Grid>

                            <Grid item md={12}>
                                <InputLabel>User Role</InputLabel>
                                <Select
                                    value={newAdminUserRole}
                                    onChange={(e) => setNewAdminUserRole(e.target.value)}
                                    sx={{ width: '100%', height: '32px' }}
                                >
                                    <MenuItem value={'Admin'}>Admin</MenuItem>
                                    <MenuItem value={'Admin Finance'}>Admin Finance</MenuItem>
                                    <MenuItem value={'Admin Operations'}>Admin Operations</MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleAddNewAdmin}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Add"}
                    </Button>
                </Box>
            </Modal>

            {/* Edit Modal  */}

            <Modal open={editModalOpen} onClose={handleCloseEditModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 5, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Admin</Typography>
                        <IconButton onClick={handleCloseEditModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Box sx={{ my: 2 }}>
                        <Grid container spacing={3}>
                            <Grid item md={6}>
                                <InputLabel>Admin Name</InputLabel>
                                <CustomTextField
                                    label="Admin Name"
                                    value={editAdminName}
                                    setValue={setEditAdminName}

                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Email</InputLabel>
                                <CustomTextField
                                    label="Email"
                                    value={editAdminEmail}
                                    setValue={setEditAdminEmail}
                                    disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Phone Number</InputLabel>
                                <CustomTextField
                                    label="Phone Number"
                                    value={editAdminPhoneNumber}
                                    setValue={setEditAdminPhoneNumber}
                                // disabled
                                />
                            </Grid>
                            <Grid item md={6}>
                                <InputLabel>Password</InputLabel>
                                <CustomTextField
                                    label="Password"
                                    value={editAdminPassword}
                                    setValue={setEditAdminPassword}
                                />
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    fullWidth
                                    value={editStatus}
                                    onChange={(e) => setEditStatus(e.target.value)}
                                    sx={{ height: '32px', padding: '5px 5px', fontSize: '12px' }}
                                >


                                    <MenuItem value={true}>
                                        Active
                                    </MenuItem>

                                    <MenuItem value={false}>
                                        Inactive
                                    </MenuItem>
                                </Select>
                            </Grid>
                        </Grid>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={handleUpdateAdmin}
                        sx={{ background: '#0093AF !important', boxShadow: 'none !important', display: 'block', mt: 3, ml: 'auto' }}
                    >
                        {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                    </Button>
                </Box>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />
        </Box>
    );
};

export default AdminsBody;
