import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import store from '../src/redux/store'; 
import Home from '../src/Pages/Home';
import About from '../src/Pages/Home';
import Master from '../src/Pages/Master';
import Login from '../src/Pages/Login';
import SuperBill from './Pages/SuperBill';
import TimeSheet from './Pages/TimeSheet';
import DashboardProvider from './Pages/DashboardProvider';
import Facility from './Pages/Facility';
import Report from './Pages/Report';
import StateMaster from './Pages/StateMaster';
import VisitReason from './Pages/VisitReason';
import Biller from './Pages/SuperAdmin/Biller';
import Admins from './Pages/SuperAdmin/Admins';
import ProviderPage from './Pages/SuperAdmin/Provider';
import BillerProvider from './Pages/SuperAdmin/BillerProvider';
import BillerReport from './Pages/Biller/BillerReport';
import AdminReport from './Pages/SuperAdmin/AdminReport';
import ExcelUpload from './Pages/Provider/ExcelUpload';
import AdminTimeSheet from './Pages/SuperAdmin/TimeSheet';
import Employee from './Pages/SuperAdmin/Employee';
import ForgetPassword from './Pages/ForgetPassword';
import ResetPassword from './Pages/ResetPassword';
import BillerTimeSheet from './Pages/BillerTimeSheet';

function App() {
  return (
    <Provider store={store}>
      <Router>
        <Routes>
          <Route path="/dashboard" element={<Home />} />
          <Route path="/providerdashboard" element={<DashboardProvider />} />
          <Route path="/state-master" element={<StateMaster />} />
          <Route path="/facility-type-master" element={<Master />} />
          <Route path="/visit-reason" element={<VisitReason />} />
          {/* <Route path="/master" element={<Master />} /> */}
          <Route path="/facility" element={<Facility />} />
          <Route path="/superbill" element={<SuperBill />} />
          <Route path="/timesheet" element={<TimeSheet />} />
          <Route path="/" element={<Login />} />
          <Route path="/forget-password" element={<ForgetPassword />} />
          <Route path="/forgot-password/:id/:id" element={<ResetPassword />} />
          <Route path="/report" element={<Report />} />

          <Route path="/admin-report" element={<AdminReport />} />

          <Route path="/biller" element={<Biller />} />
          <Route path="/admins-user" element={<Admins />} />
          <Route path="/provider" element={<ProviderPage />} />
          <Route path="/biller-provider" element={<BillerProvider />} />
          <Route path="/employee" element={<Employee />} />

          <Route path="/biller-dashboard" element={<BillerReport />} />
          <Route path="/excel-upload" element={<ExcelUpload />} />
          <Route path="/admin/timesheet" element={<AdminTimeSheet />} />
          <Route path="/biller/timesheet" element={<BillerTimeSheet />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
