import React from 'react';
import PatientFormTable from '../Component/PatientFormTable';
import ProviderLayout from '../ProviderLayout';

const SuperBill = () => {
  return (
    <ProviderLayout >
      <PatientFormTable />
    </ProviderLayout>
  );
};

export default SuperBill;
