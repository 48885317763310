import React from 'react';
import MastersTable from '../Component/MatersTable';
import { useState } from 'react';
import Layout from '../Layout';


export default function Facility() {

    return (
        <Layout>
            <MastersTable  />
        </Layout>
    )
}