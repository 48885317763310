import { Box, Grid } from "@mui/material";
import Sidebar from "./Component/Sidebar";
import Navbar from "./Component/Navbar";
import Sidebar2 from "./Component/Sidebar2";

export default function Layout({children}) {
    return (
        <Box>
           <Sidebar2 children={children}/>
        </Box>
    )
}