import React from 'react';
import TimeSheetTable from '../Component/TimeSheetTable';
import ProviderLayout from '../ProviderLayout';
import BillerTimeSheetTable from '../Component/BillerTimeSheetTable';
import BillerLayout from './Biller/Component/BillerLayout';

const BillerTimeSheet = () => {


  return (
    <BillerLayout >
      <BillerTimeSheetTable />
    </BillerLayout>
  );
};

export default BillerTimeSheet;
