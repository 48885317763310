import { Box, Grid } from "@mui/material";
import Navbar from "./Component/Navbar";
import SidebarProvider from "./Component/SidebarProvider";
import SidebarProvider2 from "./Component/SidebarProvider2";

export default function ProviderLayout({ children }) {
    return (
        <Box>
            <SidebarProvider2 children1={children} />
        </Box>
    )
}