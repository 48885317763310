import React from 'react';
import { useState } from 'react';
import Layout from '../../Layout';
import ProviderBody from './Components/ProviderBody';


export default function Provider() {


    return (
        <Layout>
            <ProviderBody  />
        </Layout>
    )
}