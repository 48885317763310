import React, { useEffect, useState } from 'react';
import { Box, TextField, Button, Paper, Typography, CircularProgress, InputLabel, useMediaQuery, useTheme } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import Logo from '../Image/Logo.png';
import CustomSnackbar from '../Utils/CustomSnackbar';
import { useDispatch } from 'react-redux';
import { login } from '../api/action';

const LoginScreen = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [loading, setLoading] = useState('');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailRegex.test(value);

    setEmailError(isValid ? '' : 'Invalid email address');

    return isValid;
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    validateEmail(e.target.value);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  // const handleLoginClick = () => {
  //   if (!validateEmail(email)) {
  //     return;
  //   }

  //   setSnackbarMessage('Logging in successfully');
  //   setSnackbarOpen(true);

  //   switch (userRole) {
  //     case 'Super Admin':
  //       navigate('/dashboard');
  //       break;
  //     case 'Provider':
  //       navigate('/providerdashboard');
  //       break;
  //     default:
  //       navigate('/biller-dashboard');
  //       break;
  //   }
  // };

  const handleLoginClick = async (e) => {
    e.preventDefault();

    if (!validateEmail(email)) {
      return;
    }

    if (!email || !password) {
      setLoading(false);
      setSnackbarMessage('Please fill all the fields.');
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    localStorage.clear();
    sessionStorage.clear();

    const payload = {
      email,
      password,
    };

    setLoading(true);
    dispatch(
      login(
        payload,
        (data) => {
          setLoading(false);
          if (data?.data?.msg === "Login Success") {
            console.log(data)
            const accessToken = data?.data?.token?.access;
            const refreshToken = data?.data?.token?.refresh;
            const profileId = data?.data?.profile_id;
            const userId = data?.data?.user_id;
            const userName = data?.data?.user_name;
            const userEmail = data?.data?.user_email;
            const userRole = data?.data?.user_role;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('userEmail', userEmail);
            localStorage.setItem('userId', userId);
            localStorage.setItem('userName', userName);
            localStorage.setItem('profileId', profileId);
            localStorage.setItem('userRole', userRole);
            localStorage.setItem('is_deleted_access', data?.data?.is_deleted_access);
            setSnackbarMessage(data?.data.msg);
            setSnackbarOpen(true);
            setSnackbarSeverity("success")
            switch (userRole) {
              case 'Biller':
                navigate('/biller-dashboard');
                break;
              case 'Provider':
                navigate('/providerdashboard');
                break;
              case 'Employee':
                navigate('/providerdashboard');
                break;
              case 'Employee_Office_Work':
                navigate('/providerdashboard');
                break;
                case 'Consultant':
                  navigate('/providerdashboard');
                  break;
              case 'Employee_Admin_Work':
                navigate('/providerdashboard');
                break;
              default:
                navigate('/dashboard');
                break;
            }
            setTimeout(() => {
              setSnackbarOpen(false);
            }, 2000);
          }
          else {
            setSnackbarMessage(data?.data?.msg);
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
          }
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);

        }
      )
    );
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const userRole = 'Provider';
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#0093AF',
        flexDirection: 'column',
        minHeight: '100vh',
        px: isMobile ? 2 : 0, // Add padding on mobile
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', color: '#0093AF', fontWeight: 'bold', verticalAlign: 'middle', background: '#fff', mb: 2, borderRadius: '6px' }}>
        <img src={Logo} alt="Logo" height={isMobile ? "50px" : "70px"} width={isMobile ? "150px" : "200px"} style={{ borderRadius: '10px' }} />
      </Box>

      <Paper elevation={3} sx={{ padding: 3, minWidth: isMobile ? 200 : 400, py: 5, px: 5 }}>
        <Typography className='font-primary' variant='h5' sx={{ fontWeight: 'bold', color: '#0093AF', textAlign: 'center',  fontSize: isMobile ? "16px" : "32px", }}>Billing Portal</Typography>

        <InputLabel className='fs16'>Email</InputLabel>
        <TextField
          className='fs16'
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={handleEmailChange}
          helperText={emailError}
          sx={{
            mt: 0,
            '& fieldset': {
              borderColor: emailError ? 'red' : '#0093AF',
            },
            '&:hover fieldset': {
              borderColor: emailError ? 'red' : '#0093AF',
            },
            '&.Mui-focused fieldset': {
              borderColor: emailError ? 'red' : '#0093AF',
            },
          }}
        />

        <InputLabel className='fs16'>Password</InputLabel>
        <TextField
          className='fs16'
          sx={{ mt: 0 }}
          variant="outlined"
          fullWidth
          margin="normal"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />

        <Typography
          className='fs16'
          sx={{ color: '#0093AF', textAlign: 'right', cursor: 'pointer', textDecoration: 'underline' }}
          onClick={() => navigate('/forget-password')}
        >
          Forget Password?
        </Typography>

        <Button variant="contained" fullWidth sx={{ background: '#0093AF !important', py: 1, mt: 2 }} onClick={handleLoginClick}>
          <Typography  className='fs16' style={{ color: '#fff', textDecoration: 'none' }}>
            {loading ? (
              <CircularProgress size={18} sx={{ color: '#fff' }} />
            ) : (
              "Login"
            )}
          </Typography>
        </Button>
      </Paper>

      <CustomSnackbar
        open={snackbarOpen}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Box>
  );
};

export default LoginScreen;
