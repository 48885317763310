import React, { useEffect, useState } from 'react';
import { Avatar, Box, Typography, IconButton, InputBase, Badge, Menu, MenuItem, Hidden } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import NotificationsIcon from '@mui/icons-material/Notifications';
import ChatIcon from '@mui/icons-material/Chat';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { getUserProfile } from '../api/action';

export default function Navbar() {
  const [anchorEl, setAnchorEl] = useState(null);
  const [detail, setDetail] = useState([]);
  const [loading, setLoading] = useState(true);
  const userId = localStorage.getItem("userId");
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getUserProfile(payload, userId,
        (data) => {
          setLoading(false);
          console.log(data, "dataA")
          setDetail(data?.data?.Data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [])

  // const history = useHistory()
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const location = useLocation();
  const pathname = location.pathname;

  // const handleLogout = () => {
  //   localStorage.clear();
  //   history.push('/');
  // };


  return (
    <Hidden mdDown>
      <Box>
        <Box sx={{ py: 2, px: { md: 5, xs: 2 }, background: '#fff', display: 'flex', justifyContent: 'right', alignItems: 'center', verticalAlign: 'middle' }}>
          {/* <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            verticalAlign: 'middle',
            border: '1px solid #00000014',
            borderRadius: '20px',
            px: { md: 2, xs: 1 },
            background: '#0000000A',
            width: { md: '20vw', xs: 'fit-content' },
            height: { md: '2.5rem', xs: 'fit-content' },
          }}
        >
          <SearchIcon className="textLight" />
          <InputBase sx={{ ml: 1, flex: 1 }} className="fs16" placeholder="Search patient" />
        </Box> */}

          <Box sx={{ display: 'flex', justifyContent: 'center', verticalAlign: 'middle', alignItems: 'center' }}>
            {/* <Box >
            <IconButton sx={{ height: { md: 24, xs: 6 }, width: { md: 24, xs: 6 }, mx: { md: 4, xs: 2 } }}>
              <Badge
                badgeContent={''}
                color="error"
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <NotificationsIcon className="iconLightColor fs24" />
              </Badge>
            </IconButton>

            <IconButton sx={{ height: { md: 24, xs: 6 }, width: { md: 24, xs: 6 } }}>
              <Badge
                badgeContent={''}
                color="error"
                variant="dot"
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <ChatIcon className="iconLightColor fs24" />
              </Badge>
            </IconButton>
          </Box> */}

            <Box>
              <IconButton onClick={handleClick} disableRipple>
                <Avatar alt="Profile Picture" src="" sx={{ height: { md: 24, xs: 20 }, width: { md: 24, xs: 20 }, p: { xs: 0 } }} />
                <Typography className="fontSans fw500 fs14 mHide" sx={{ px: 1 }}>
                  Dr John Doe
                </Typography>
                {anchorEl ? <KeyboardArrowUpIcon className="iconLightColor mHide" /> : <KeyboardArrowDownRoundedIcon className="iconLightColor mHide" />}
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
              >
                <MenuItem onClick={handleClose}>Profile</MenuItem>
                <MenuItem onClick={handleClose}>My Account</MenuItem>
                
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Hidden>
  );
}
