import React, { useState, useEffect } from 'react';
import {
  Autocomplete,
  TextField,
  Button,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  ListItemText,
  InputLabel

} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CustomTextField from '../Utils/CustomTextfield';
import { addSuperBillByProvider, deleteSuperBill, getAllFacility, getAllFacilityTypes, getAllStateMasters, getAllVisitReasons, getFacilityByProviderId, getFacilityTypeByProviderId, getPatientByProviderId, getSuperBillByProvider, submitForAuthorization, updateSuperBillByProvider } from '../api/action';
import { useDispatch } from 'react-redux';
import CustomSnackbar from '../Utils/CustomSnackbar';
import CustomPagination from '../Utils/CustomPagination';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import allDxCodes from '../Data/DXCode';
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import CustomTable from './CustomTable';
const allPatient = [
  { firstName: 'John', lastName: 'Doe', dob: '1990-01-01', dateOfService: '2022-01-01', provider: 'Provider 1', facility: 'Facility 1', facilityType: 'Type 1', state: 'State 1' },
  { firstName: 'Jane', lastName: 'Smith', dob: '1985-05-15', dateOfService: '2023-01-01', provider: 'Provider 2', facility: 'Facility 2', facilityType: 'Type 2', state: 'State 2' },
  { firstName: 'Alice', lastName: 'Tim', dob: '1985-06-15', dateOfService: '2024-01-01', provider: 'Provider 3', facility: 'Facility 3', facilityType: 'Type 3', state: 'State 3' },
];

const PatientFormTable = () => {

  // const initialRowState = {
  //   firstName: '',
  //   lastName: '',
  //   dob: dayjs().format('MM/DD/YYYY'),
  //   cptCode: null,
  //   dx_code: [],
  //   admission: '',
  //   modifier: '',
  //   reason: '',
  //   comment: ''
  // };
  // const initialState = Array.from({ length: 5 }, () => ({ ...initialRowState }));

  // const [state, setState] = useState(initialState);
  const [formData, setFormData] = useState({});
  const [tableRows, setTableRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [provider, setProvider] = useState(localStorage.getItem("userName"));
  const [providerId, setProviderId] = useState(localStorage.getItem("userId"));
  const [profileId, setProfileId] = useState(localStorage.getItem("profileId"));
  const [allFacilities, setAllFacilities] = useState([]);
  const [allFacilityType, setAllFacilityType] = useState([]);
  const [allState, setAllState] = useState([]);
  const [allCpt, setAllCpt] = useState([]);
  const [allPatient, setAllPatient] = useState([]);
  const [facility, setFacility] = useState(null);
  const [facilityType, setFacilityType] = useState("");
  const [facilityTypeId, setFacilityTypeId] = useState("");
  const [state, setState] = useState(null);

  const [stateId, setStateId] = useState("");
  const [cpt, setCpt] = useState(null);
  const [dob, setDob] = useState(dayjs());
  const [dos, setDos] = useState(dayjs());
  const [fName, setFName] = useState("");
  const [lName, setLName] = useState("");
  const [admission, setAdmission] = useState('');
  const [reason, setReason] = useState(null);
  const [comment, setComment] = useState('');
  const [modifier, setModifier] = useState('');
  const [dx, setDx] = useState([]);
  const [id, setId] = useState([]);
  const [superBillData, setSuperBillData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [filteredData, setFilteredData] = useState();

  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const ROWS_PER_PAGE = 10;

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
    const day = date.getUTCDate().toString().padStart(2, '0');
    const year = date.getUTCFullYear();
  
    return `${year}-${month}-${day}`;
  }

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getFacilityByProviderId(payload, providerId,
        (data) => {
          setLoading(false);
          setAllFacilities(data?.data?.Data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [])


  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getAllStateMasters(payload,
        (data) => {
          setLoading(false);
          setAllState(data?.data?.Data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [])

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getAllVisitReasons(payload,
        (data) => {
          setLoading(false);
          setAllCpt(data?.data?.Data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [])

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getPatientByProviderId(payload, profileId,
        (data) => {
          setLoading(false);
          setAllPatient(data.data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [])

  useEffect(() => {
    setLoading(true)
    const payload = null;
    dispatch(
      getSuperBillByProvider(payload, profileId,
        (data) => {
          setLoading(false);
          setSuperBillData(data?.data)
        },
        (error) => {
          console.log(error)
          setLoading(false)
        }
      )
    );

  }, [updated])

  const handleCheckboxChange = (id) => {
    const isSelected = selectedRows.includes(id);
    setSelectedRows((prevSelected) =>
      isSelected
        ? prevSelected.filter((code) => code !== id)
        : [...prevSelected, id]
    );

    // Set the selected row id in the id state
    setId((prevIds) =>
      isSelected
        ? prevIds.filter((code) => code !== id)
        : [...prevIds, id]
    );
  };

  useEffect(() => {
    const initialRows = Array.from({ length: 1 }, (_, index) => ({
      id: index + 1,
      formData: {},
      dob: '',
      provider: '',
      facility: '',
      facilityType: '',
      state: '',
      dateOfService: '',
      cptCode: '',
      dxCode: '',
      admission: '',
      reasonForVisit: '',
      comment: '',
      modifier: '',
    }));
    setTableRows(initialRows);
  }, []);

  // useEffect(() => {
  //   setFilteredData(state?.filter(item => item.patient_name && item.last_name))
  // }, [state]);

  function formatData(arr) {
    const sortedArr = arr.sort((a, b) => b.localeCompare(a));
    return sortedArr.join(', ');
  }

  const handleAddTimeSheet = (e) => {
    e.preventDefault();

    // if (state === null || []) {
    //   const errorMessage = 'Please fill the mandatory fields';
    //   setSnackbarMessage(errorMessage);
    //   setSnackbarSeverity("error");
    //   setSnackbarOpen(true);
    //   return;
    // }

    // Filter out rows without patient_name and last_name
    const filteredData = state.filter(item => item.patient_name && item.last_name);
    // Check if any item has an empty facility_master
    if (filteredData?.some(item => !item.facility_master)) {
      const errorMessage = 'Please select the facility';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    // Check if any mandatory field is empty
    if (filteredData?.some(item => !item.patient_name || !item.last_name || !item.dx_code || !item.cpt_code)) {
      const errorMessage = 'Please fill the mandatory fields';
      setSnackbarMessage(errorMessage);
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
      return;
    }

    const updatedState = filteredData.map(item => ({
      ...item,
      dob: formatDate(item.dob),
      dx_code: formatData(item.dx_code),
      date_of_service: formatDate(item.date_of_service),
      facility_type: item.facility_type && typeof item.facility_type === 'object'
        ? item.facility_type.id
        : item.facility_type, // If it's not an object, keep the original value
      facility_master: item.facility_master && typeof item.facility_master === 'object'
        ? item.facility_master.id
        : item.facility_master // If it's not an object, keep the original value
    }));

    const payload = updatedState;

    setLoading(true);
    dispatch(
      addSuperBillByProvider(
        payload,
        (data) => {
          setLoading(false);
          setUpdated(prevState => !prevState);
          setSnackbarMessage("Super Bill added successfully");
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setState(null);
          setSelectAll(false)
          setSelectedRows([])
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.msg || "Oops! Looks like we need you to log in again to keep things secure!";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
  };


  const handleDelete = (e) => {
    e.preventDefault();

    const payload = {
      super_biller_id: selectedRows
    };

    setLoading(true);
    dispatch(
      deleteSuperBill(
        payload,
        (data) => {
          setLoading(false);
          setUpdated(prevState => !prevState);

          setSnackbarMessage("Super Bill Deleted successfully");
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
          setSelectedRows([]);
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
  };

  function getCurrentDateTime() {
    const now = new Date();

    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const year = String(now.getFullYear()).slice(-2);
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    return `${month}/${day}/${year} ${hours}:${minutes}:${seconds}`;
  }


  const handleAuthorization = (e) => {
    e.preventDefault();

    if (id.length === 0) {
      setSnackbarMessage("Please select at least one data");
      setSnackbarOpen(true);
      setSnackbarSeverity("error");
    }

    const payload = {
      user_id: id,
      provider_id: providerId,
      datetime: getCurrentDateTime()
    };

    setLoading(true);
    dispatch(
      submitForAuthorization(
        payload,
        (data) => {
          setLoading(false);
          setUpdated(prevState => !prevState)
          setSelectAll(false)

          setSnackbarMessage("Submitted for Billing successfully");
          setSnackbarOpen(true);
          setSnackbarSeverity("success");
        },
        (error) => {
          console.log(error);
          setLoading(false);
          const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
          setSnackbarMessage(errorMessage);
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      )
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      const allCodes = superBillData.map((row) => row.id);
      setSelectedRows(allCodes);
      // Set all rows id in id state
      setId(allCodes);
    } else {
      setSelectedRows([]);
      setId([]);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const startIndex = (currentPage - 1) * ROWS_PER_PAGE;
  const endIndex = startIndex + ROWS_PER_PAGE;

  function convertToFormattedString(data) {
    if (Array.isArray(data)) {
      if (data.length === 0) {
        return ""; // Return an empty string for an empty array
      } else if (typeof data[0] === 'string') {
        return data.join(', ').toUpperCase(); // Convert array items to uppercase and join them with ', '
      } else if (typeof data[0] === 'object' && data[0].Code) {
        return data.map(item => item.Code).join(', '); // Extract 'Code' properties and join them with ', '
      }
    }
    return ""; // Return an empty string for unsupported data type
  }

  return (
    <form id="patientFormTable" onSubmit={handleSubmit}>
      <Box sx={{ px: { md: 7, xs: 2 }, }}>
        <Box >
          <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', }}>Super Bill</Typography>
          <CustomTable state={state} setState={setState} facility={facility} dos={dos} handleAddTimeSheet={handleAddTimeSheet} updated={updated} />
        </Box>

        {/* <Box sx={{ display: 'flex', float: 'right', my: 5 }}>

          <Button className='buttonPadding' type="submit" variant="contained" sx={{ background: '#0093AF !important', px: 3, mx: 1 }} onClick={handleAddTimeSheet}>
            Save
          </Button>


        </Box> */}

        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          superBillData.length !== 0 && (
            <Box sx={{ my: 4 }}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 3 }}>Saved Data</Typography>
              </Box>

              <TableContainer className='scrollBar' component={Paper}>
                <Table>
                  <TableHead sx={{ backgroundColor: '#0093AF', py: 0 }}>
                    <TableRow>
                      <TableCell sx={{ color: '#fff', py: 0.2 }}>
                        <Checkbox
                          checked={selectAll}
                          onChange={handleSelectAllChange}
                          style={{ color: '#fff' }}
                        />
                      </TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Provider</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Facility</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Facility Type</TableCell>
                      {/* <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>State</TableCell> */}
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>First Name</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Last Name</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Date of Birth</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Date of Service</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>CPT Code</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>DX Code</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Admission</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Modifier</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Reason for Visit</TableCell>
                      <TableCell sx={{ color: "#fff", py: 0, whiteSpace: 'nowrap', }}>Comment</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {(superBillData ? superBillData.reverse().slice(startIndex, endIndex) : []).map((row, index) => (
                      <TableRow key={index}>
                        <TableCell sx={{ py: 0.5 }}>
                          <Checkbox
                            size='small'
                            checked={selectedRows.includes(row.id)}
                            onChange={() => handleCheckboxChange(row.id)}
                            style={{ color: '#0093AF' }}
                          />
                        </TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.provider_profile?.First_Name + " " + row.provider_profile?.Last_Name}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_master?.facility_name}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.facility_type?.facility_type_Name}</TableCell>
                        {/* <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.state?.state_Name}</TableCell> */}
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.patient_name}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.last_name}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dob}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.date_of_service}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.cpt_code?.visit_reason_code}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.dx_code}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.admission}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.modifier}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.reason_for_visit}</TableCell>
                        <TableCell className='fs12' sx={{ whiteSpace: 'nowrap' }}>{row?.comment}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>

              <CustomPagination
                count={Math.ceil(superBillData.length / ROWS_PER_PAGE)}
                page={currentPage}
                onChange={handleChangePage}
              />

              <Box sx={{ display: 'flex', my: 5, justifyContent: 'space-between' }}>
                <Button
                  disabled={selectedRows.length === 0}
                  onClick={handleDelete}
                  className="buttonPadding"
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: selectedRows.length === 0 ? "#d3d3d3" : "#0093AF",
                    px: 3,
                    mb: 3,
                    "&:hover": {
                      backgroundColor: selectedRows.length === 0 ? "#d3d3d3" : "#007b92",
                    },
                  }}
                >
                  Delete
                </Button>
                <Button className='buttonPadding' disabled={selectedRows.length === 0} type="submit" variant="contained" sx={{ background: selectedRows.length === 0 ? "#d3d3d3" : '#0093AF !important', px: 3, mx: 1, mb: 3, }} onClick={handleAuthorization}>
                  Submit for Billing
                </Button>
              </Box>
            </Box>
          )
        )}

        <CustomSnackbar
          open={snackbarOpen}
          handleClose={handleSnackbarClose}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />

      </Box>
    </form>
  );
};

export default PatientFormTable;
