// reducers.js
const initialState = {
  loggedInUser: null
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN_USER':
      return {
        ...state,
        loggedInUser: action.payload
      };
    default:
      return state;
  }
};

export default rootReducer;
