import React, { useEffect, useState } from 'react';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Checkbox,
    TextField,
    Grid,
    Typography,
    Box,
    Button,
    Menu,
    MenuItem,
    Select,
    InputLabel,
    Modal,
    CircularProgress,
    Autocomplete
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CircleIcon from '@mui/icons-material/Circle';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import CustomTextField from '../Utils/CustomTextfield';
import CustomPagination from '../Utils/CustomPagination';
import { useDispatch } from 'react-redux';
import { addFacility, deleteFacility, getAllFacility, getAllFacilityTypes, getAllStateMasters, updateFacility } from '../api/action';
import CustomSnackbar from '../Utils/CustomSnackbar';
import DeleteModal from '../Utils/DeleteModal';
import { phoneRegex } from '../Utils/Regex';
import * as XLSX from 'xlsx';
import CloseIcon from '@mui/icons-material/Close';

const MastersTable = ({ onEdit, onDelete }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [searchFacilityCode, setSearchFacilityCode] = useState('');
    const [searchFacilityName, setSearchFacilityName] = useState('');
    const [searchFacilityType, setSearchFacilityType] = useState('');
    const [searchStatus, setSearchStatus] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedFacility, setSelectedFacility] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [editedRow, setEditedRow] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [page, setPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState("10");
    const [data, setData] = useState([]);
    const [allStates, setAllStates] = useState([]);
    const [loading, setLoading] = useState('');
    const [updated, setUpdated] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('');
    const [facilityCode, setFacilityCode] = useState('');
    const [facilityName, setFacilityName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [status, setStatus] = useState(false);
    const [stateList, setStateList] = useState(null);
    const [stateID, setStateID] = useState([]);


    const [facilityCodeEdit, setFacilityCodeEdit] = useState("");
    const [facilityNameEdit, setFacilityNameEdit] = useState("");
    const [emailEdit, setEmailEdit] = useState("");
    const [phoneNumberEdit, setPhoneNumberEdit] = useState("");
    const [statusEdit, setStatusEdit] = useState(false);
    const [facilityListEdit, setFacilityListEdit] = useState(null);
    const [stateListEdit, setStateListEdit] = useState(null);
    const [facilityEditID, setFacilityEditID] = useState([]);
    const [stateEditId, setStateEditId] = useState([]);

    const [openDeleteModal, setOpenDeleteModal] = useState(false);

    const [id, setId] = useState('');
    const [editModalOpen, setEditModalOpen] = useState(false);
    const dispatch = useDispatch();
    const [emailError, setEmailError] = useState('');
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const [facilityTypeData, setFacilityTypeData] = useState([]);
    const [facilityList, setFacilityList] = useState(null);
    const [facilityID, setFacilityID] = useState([]);
    console.log(facilityEditID)
    console.log(facilityListEdit)

    const userRole = localStorage.getItem("userRole")



    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacility(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllFacilityTypes(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setFacilityTypeData(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])

    useEffect(() => {
        setLoading(true)
        const payload = null;
        dispatch(
            getAllStateMasters(payload,
                (data) => {
                    console.log(data?.data?.Data)
                    setLoading(false);
                    setAllStates(data?.data?.Data)
                },
                (error) => {
                    console.log(error)
                    setLoading(false)
                }
            )
        );

    }, [updated])



    const getStateById = (id) => {
        return allStates.find(state => state.id === id) || null;
    };

    const handleEditClick = (event, selectedRow) => {
        console.log("row", selectedRow);
        setEditedRow(selectedRow);
        setEditModalOpen(true);
        setFacilityNameEdit(selectedRow.facility_name);
        setFacilityCodeEdit(selectedRow.facility_code);
        setPhoneNumberEdit(selectedRow.phone_number);
        setEmailEdit(selectedRow.email);
        setStatusEdit(selectedRow.is_active);
        setId(selectedRow.id);
        // Set facility type in the Autocomplete input
        setFacilityListEdit({ id: selectedRow.facility_type.id, facility_type_Name: selectedRow.facility_type.facility_type_name });
        const selectedState = getStateById(selectedRow.state_id);
        setStateListEdit(selectedState);
    };

    console.log("row", stateListEdit);


    const handleAddClick = (event, selectedRow) => {
        setEditedRow(selectedRow);
        setOpenModal(true);
    };

    const handleCheckboxChange = (facilityCode) => {
        const isSelected = selectedRows.includes(facilityCode);
        setSelectedRows((prevSelected) =>
            isSelected
                ? prevSelected.filter((code) => code !== facilityCode)
                : [...prevSelected, facilityCode]
        );
    };

    const handleSelectAllChange = (event) => {
        setSelectAll(event.target.checked);
        if (event.target.checked) {
            const allCodes = data.map((row) => row.facility_code);
            setSelectedRows(allCodes);
        } else {
            setSelectedRows([]);
        }
    };

    const handleEditClose = () => {
        setAnchorEl(null);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleCloseEditModal = () => {
        setEditModalOpen(false);
    };



    const handleEdit = (newStatus) => {
        if (selectedFacility) {
            const updatedFacility = {
                ...selectedFacility,
                status: newStatus,
            };

            const updatedData = data.map((facility) =>
                facility.facilityCode === selectedFacility.facilityCode ? updatedFacility : facility
            );

            setData(updatedData);
            onEdit(updatedFacility);
            setAnchorEl(null);
        }
    };



    const handleDelete = () => {
        onDelete(selectedRows);
        setSelectedRows([]);
    };

    const filteredData = data.filter((row) => {
        const matchesFacilityCode = row.facility_code.toLowerCase().includes(searchFacilityCode.toLowerCase());
        const matchesFacilityName = row.facility_name.toLowerCase().includes(searchFacilityName.toLowerCase());
        const matchesFacilityType = row.facility_type?.facility_type_name ? row.facility_type?.facility_type_name?.toLowerCase().includes(searchFacilityType.toLowerCase()) : true;
        const matchesStatus = searchStatus === null ? true : row.is_active === searchStatus;

        return matchesFacilityCode && matchesFacilityName && matchesFacilityType && matchesStatus;
    });

    useEffect(() => {
        setPage(1)
    }, [searchFacilityCode, searchFacilityName, searchFacilityType, searchStatus])

    console.log(filteredData, "filteredData")

    const handleClearSearch = () => {
        setSearchFacilityCode('');
        setSearchFacilityName('');
        setSearchFacilityType('');
        setSearchStatus(null)
    };


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleAddNewFacility = (e) => {
        e.preventDefault();

        if (!facilityCode || !facilityName || !email || !phoneNumber) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!emailRegex.test(email)) {
            setEmailError('Please enter a valid email address.');
            setLoading(false);
            setSnackbarMessage('Please enter a valid email address.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        if (!phoneRegex.test(phoneNumber)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }


        const payload = {
            facility_code: facilityCode,
            facility_name: facilityName,
            email,
            phone_number: phoneNumber,
            is_active: status,
            facility_type_ids: [facilityID],
            state: stateID
        };

        console.log(payload)

        setLoading(true);
        dispatch(
            addFacility(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Facilty added successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setOpenModal(false);
                    setUpdated(prevState => !prevState);
                    setFacilityCode("")
                    setFacilityName("")
                    setEmail("")
                    setPhoneNumber("")
                    setStatus("")
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleUpdateFacility = (e) => {
        e.preventDefault();

        if (!facilityCodeEdit || !facilityNameEdit || !emailEdit || !phoneNumberEdit) {
            setLoading(false);
            setSnackbarMessage('Please fill all the fields with valid data.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }
        if (!phoneRegex.test(phoneNumberEdit)) {
            setLoading(false);
            setSnackbarMessage('Please enter a valid phone number.');
            setSnackbarSeverity("error");
            setSnackbarOpen(true);
            return;
        }

        const payload = {
            facility_id: id,
            facility_code: facilityCodeEdit,
            facility_name: facilityNameEdit,
            email: emailEdit,
            phone_number: phoneNumberEdit,
            is_active: statusEdit,
            facility_type_ids: [facilityEditID],
            state: stateEditId
        };


        setLoading(true);
        dispatch(
            updateFacility(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Facilty updated successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    setEditModalOpen(false);
                    setUpdated(prevState => !prevState);

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleDeleteFacility = (id) => {
        console.log(id)
        const payload = {
            facility_id: id
        };

        setLoading(true);
        dispatch(
            deleteFacility(
                payload,
                (data) => {
                    setLoading(false);
                    setSnackbarMessage("Facilty deleted successfully");
                    setSnackbarOpen(true);
                    setSnackbarSeverity("success");
                    // setEditModalOpen(false);
                    setUpdated(prevState => !prevState);
                    setOpenDeleteModal(false)

                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                    const errorMessage = error.message === "Network Error" ? error.message : error.response?.data?.detail || "Oops! Looks like we need you to log in again to keep things secure!";
                    setSnackbarMessage(errorMessage);
                    setSnackbarSeverity("error");
                    setSnackbarOpen(true);
                }
            )
        );
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleDeleteModal = (id) => {
        setOpenDeleteModal(true);
        setId(id)
    };

    const handleDownloadExcel = () => {
        // Filter rows based on selectedRows array
        const exportData = filteredData
            .filter(row => selectedRows.includes(row.facility_code))
            .map(row => ({
                'Facility Code': row.facility_code,
                'Facility Name': row.facility_name,
                'Facility Type': row.facility_type?.facility_type_name,
                'Email': row.email,
                'Phone Number': row.phone_number,
                'Status': row.is_active ? 'Active' : 'Inactive'
            }));

        // Create a new workbook
        const wb = XLSX.utils.book_new();

        // Convert data to worksheet
        const ws = XLSX.utils.json_to_sheet(exportData);
        // Set column widths
        const columnWidths = [
            { wch: 15 }, // Width of Facility Code column
            { wch: 60 }, // Width of Facility Name column
            { wch: 30 }, // Width of Email column
            { wch: 15 }, // Width of Phone Number column
            { wch: 10 }  // Width of Status column
        ];

        ws['!cols'] = columnWidths;

        // Add worksheet to workbook
        XLSX.utils.book_append_sheet(wb, ws, 'SelectedRows');

        // Save the workbook as an Excel file
        XLSX.writeFile(wb, 'Facility.xlsx');
    };


    return (
        <Box sx={{ px: 10, py: 2 }}>
            <DeleteModal
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                loading={loading}
                setLoading={setLoading}
                handleDeletefunction={handleDeleteFacility}
                id={id}
            />
            <Typography variant='h6' sx={{ color: '#0093AF', fontWeight: 'bold', mb: 2 }}>Search Facility Master</Typography>
            <Grid container spacing={5} >
                <Grid item md={3} xs={6}>
                    <InputLabel>Search Facility Code</InputLabel>
                    <CustomTextField
                        value={searchFacilityCode}
                        setValue={setSearchFacilityCode}
                    />

                </Grid>
                <Grid item md={3} xs={6}>
                    <InputLabel>Search Facility Name</InputLabel>
                    <CustomTextField
                        value={searchFacilityName}
                        setValue={setSearchFacilityName}
                    />

                </Grid>
                <Grid item md={3} xs={6}>

                    <InputLabel>Search Facility Type</InputLabel>
                    <CustomTextField
                        value={searchFacilityType}
                        setValue={setSearchFacilityType}
                    />

                </Grid>
                <Grid item md={3}>
                    <InputLabel>Status</InputLabel>
                    <Select
                        fullWidth
                        variant="outlined"
                        value={searchStatus}
                        onChange={(e) => setSearchStatus(e.target.value)} // Corrected onChange handler
                        sx={{ height: '32px', '& .MuiOutlinedInput-input': { padding: '5px 10px', fontSize: '12px' } }}
                    >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Inactive</MenuItem>
                    </Select>
                </Grid>
            </Grid>

            <Box sx={{ float: 'right', my: 3 }}>
                <Button
                    className='buttonPadding'
                    variant="contained"
                    onClick={handleClearSearch}
                    sx={{ background: '#0093AF !important', boxShadow: 'none !important', mr: 2 }}
                >
                    Clear
                </Button>
                {/* <Button
                    className='buttonPadding'
                    variant="contained"
                    onClick={handleDelete}
                    disabled={selectedRows.length === 0}
                    sx={{ background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', boxShadow: 'none !important', mr: 2 }}
                >
                    Delete
                </Button> */}
                <Button
                    disabled={userRole !== "Admin"}
                    className='buttonPadding'
                    variant="contained"
                    onClick={handleAddClick}
                    sx={{ background: userRole !== "Admin" ? "#d3d3d3" : '#0093AF !important', boxShadow: 'none !important', mr: 2 }}
                >
                    Add New
                </Button>

                <Button
                    className='buttonPadding'
                    disabled={selectedRows.length === 0}
                    variant="contained"
                    onClick={handleDownloadExcel}
                    sx={{ boxShadow: 'none !important', background: selectedRows.length === 0 ? '#d2d2d2' : '#0093AF !important', }}
                >
                    Download Excel
                </Button>
            </Box>


            {
                loading ? (
                    <Box sx={{ minHeight: '40vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <CircularProgress sx={{ color: '#0093AF !important' }} />
                    </Box>
                ) : (

                    filteredData.length === 0 ? (
                        <Typography variant="body2" sx={{ textAlign: 'center', color: '#555', mt: 15, }}>
                            No Data Available
                        </Typography>
                    ) : (
                        <>
                            <TableContainer component={Paper} className='scrollBar'>
                                <Table>
                                    <TableHead sx={{ backgroundColor: '#0093AF', color: '#fff' }}>
                                        <TableRow>
                                            <TableCell sx={{ color: '#fff', py: 0.5 }}>
                                                <Checkbox
                                                    checked={selectAll}
                                                    onChange={handleSelectAllChange}
                                                    style={{ color: '#fff' }}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Facility Code</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Facility Name</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Facility Type</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>State</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Email ID</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Phone Number</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Status</TableCell>
                                            <TableCell sx={{ color: '#fff', py: 0.5, whiteSpace: 'nowrap' }}>Actions</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {filteredData?.slice((page - 1) * rowsPerPage, page * rowsPerPage).map((row, index) => (
                                            <TableRow key={index}>
                                                <TableCell sx={{ py: 0.5 }}>
                                                    <Checkbox
                                                        size="small"
                                                        checked={selectedRows.includes(row.facility_code)}
                                                        onChange={() => handleCheckboxChange(row.facility_code)}
                                                        style={{ color: '#0093AF' }}
                                                    />
                                                </TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>{row.facility_code}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>{row.facility_name}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>{row.facility_type?.facility_type_name}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>{row.state}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>{row.email}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>{row.phone_number}</TableCell>
                                                <TableCell className='fs12' sx={{ py: 0.5, whiteSpace: 'nowrap' }}>
                                                    {row.is_active === true ? "Active" : row.is_active === false ? "Inactive" : ""}
                                                </TableCell>
                                                <TableCell sx={{ py: 0.5, whiteSpace: 'nowrap    ' }}>
                                                    <IconButton disabled={userRole !== "Admin"} color="primary" onClick={(e) => handleEditClick(e, row)}>
                                                        <EditIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: "16px" }} />
                                                    </IconButton>
                                                    <IconButton disabled={userRole !== "Admin"} color="secondary" onClick={() => handleDeleteModal(row.id)}>
                                                        <DeleteIcon sx={{ color: userRole !== "Admin" ? "#d3d3d3" : '#0093AF', fontSize: "16px" }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <CustomPagination
                                count={Math.ceil(filteredData.length / rowsPerPage)}
                                page={page}
                                onChange={handleChangePage}
                            />
                        </>
                    ))}

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleEditClose}
            >
                <MenuItem onClick={() => handleEdit('Active')}>Active</MenuItem>
                <MenuItem onClick={() => handleEdit('Inactive')}>Inactive</MenuItem>
            </Menu>

            {/* Add Modal  */}

            <Modal open={openModal} onClose={handleCloseModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Add Facility</Typography>
                        <IconButton onClick={handleCloseModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Grid container spacing={3} sx={{ py: 5 }}>
                        <Grid item md={6}>
                            <InputLabel>Facility Code</InputLabel>
                            <CustomTextField
                                value={facilityCode}
                                setValue={setFacilityCode}

                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Facility Name</InputLabel>
                            <CustomTextField
                                value={facilityName}
                                setValue={setFacilityName}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Email</InputLabel>
                            <CustomTextField
                                value={email}
                                setValue={setEmail}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Phone Number</InputLabel>
                            <CustomTextField
                                value={phoneNumber}
                                setValue={setPhoneNumber}

                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>Facility Type</InputLabel>
                            {/* <Autocomplete
                                multiple
                                fullWidth
                                value={facilityList}
                                onChange={(event, newValue) => {
                                    setFacilityList(newValue);
                                }}
                                options={facilityTypeData}
                                getOptionLabel={(option) => `${option.facility_type_code}: ${option.facility_type_Name}`}
                                renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '8px', padding: '5px 10px', } }} />}
                                onChange={(event, newValue) => {
                                    // newValue will contain the selected options
                                    console.log(newValue.map(option => option.id)); // Logging the IDs of selected options
                                }}
                            /> */}

                            <Autocomplete
                                fullWidth
                                value={facilityList} // Ensure that facilityList holds a single selected option
                                onChange={(event, newValue) => {
                                    setFacilityList(newValue);
                                    // Log the ID of the selected option
                                    setFacilityID(newValue ? newValue.id : null); // Set facilityID to the ID of the selected option or null if no option is selected
                                }}
                                options={facilityTypeData}
                                getOptionLabel={(option) => option.facility_type_Name} // Display facility type name in dropdown
                                renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '0px', padding: '5px 10px', fontSize: "12px" } }} />}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>State</InputLabel>
                            <Autocomplete
                                fullWidth
                                value={stateList} // Ensure that facilityList holds a single selected option
                                onChange={(event, newValue) => {
                                    setStateList(newValue);
                                    // Log the ID of the selected option
                                    setStateID(newValue ? newValue.id : null); // Set facilityID to the ID of the selected option or null if no option is selected
                                }}
                                options={allStates}
                                getOptionLabel={(option) => option.state_Name} // Display facility type name in dropdown
                                renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '0px', padding: '5px 10px', fontSize: "12px" } }} />}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                value={status}
                                onChange={(e) => setStatus(e.target.value)} // Corrected onChange handler
                                sx={{ height: '32px', '& .MuiOutlinedInput-input': { padding: '5px 10px' } }}
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </Grid>

                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 2 }}>
                        {/* <Button variant="contained" disableElevation onClick={handleCloseModal} color="primary" sx={{ marginRight: 2 }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" disableElevation onClick={handleAddNewFacility} color="primary">
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Add"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            {/* Edit Modal  */}
            <Modal open={editModalOpen} onClose={handleCloseEditModal} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Box sx={{ background: '#fff', width: '40vw', px: 10, py: 5, mx: 'auto', borderRadius: '6px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', verticalAlign: 'middle' }}>
                        <Typography className="fs16" sx={{ fontWeight: 600, color: '#0093AF' }}>Edit Facility</Typography>
                        <IconButton onClick={handleCloseEditModal}>
                            <CloseIcon sx={{ color: '#fff', background: '#0093AF', borderRadius: '25px', height: 20, width: 20, p: 0.5 }} />
                        </IconButton>
                    </Box>
                    <Grid container spacing={3} sx={{ py: 5 }}>
                        <Grid item md={6}>
                            <InputLabel>Facility Code</InputLabel>
                            <CustomTextField
                                value={facilityCodeEdit}
                                setValue={setFacilityCodeEdit}
                                disabled
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Facility Name</InputLabel>
                            <CustomTextField
                                value={facilityNameEdit}
                                setValue={setFacilityNameEdit}
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Email</InputLabel>
                            <CustomTextField
                                value={emailEdit}
                                setValue={setEmailEdit}
                                disabled
                            />
                        </Grid>
                        <Grid item md={6}>
                            <InputLabel>Phone Number</InputLabel>
                            <CustomTextField
                                value={phoneNumberEdit}
                                setValue={setPhoneNumberEdit}
                            />
                        </Grid>

                        {/* <Grid item md={6}>
                            <InputLabel>Facility Type</InputLabel>
                            <Autocomplete
                                // multiple
                                fullWidth
                                value={facilityListEdit}
                                onChange={(event, newValue) => {
                                    setFacilityListEdit(newValue);
                                    // Log the IDs of selected options
                                    console.log(newValue.map(option => option.id));
                                    setFacilityID(newValue.map(option => option.id));
                                }}
                                options={facilityTypeData}
                                getOptionLabel={(option) => ` ${option.facility_type_Name}`} // Display facility type code in dropdown
                                renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '8px', padding: '5px 10px', } }} />}
                            />
                        </Grid> */}

                        <Grid item md={6}>
                            <InputLabel>Facility Type</InputLabel>
                            <Autocomplete
                                fullWidth
                                value={facilityListEdit} // Ensure that facilityList holds a single selected option
                                onChange={(event, newValue) => {
                                    setFacilityListEdit(newValue);
                                    // Log the ID of the selected option
                                    setFacilityEditID(newValue ? newValue.id : null); // Set facilityID to the ID of the selected option or null if no option is selected
                                }}
                                // getOptionSelected={(option, value) => option.id === value.id} // Set value based on id
                                // isOptionEqualToValue={(option, value) => option.id === value.id} // Customize equality test
                                options={facilityTypeData}
                                getOptionLabel={(option) => option.facility_type_Name} // Display facility type name in dropdown
                                renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '0px', padding: '5px 10px', fontSize: "12px" } }} />}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>State</InputLabel>
                            <Autocomplete
                                fullWidth
                                value={stateListEdit}
                                onChange={(event, newValue) => {
                                    setStateListEdit(newValue);
                                    setStateEditId(newValue ? newValue.id : null);
                                }}
                                options={allStates}
                                getOptionLabel={(option) => option.state_Name}
                                renderInput={(params) => <TextField {...params} sx={{ '& input': { height: '0px', padding: '5px 10px', fontSize: "12px" } }} />}
                            />
                        </Grid>

                        <Grid item md={6}>
                            <InputLabel>Status</InputLabel>
                            <Select
                                fullWidth
                                variant="outlined"
                                value={statusEdit}
                                onChange={(e) => setStatusEdit(e.target.value)}
                                sx={{ height: '32px', '& .MuiOutlinedInput-input': { padding: '5px 10px', fontSize: "12px" } }}
                            >
                                <MenuItem value={true}>Active</MenuItem>
                                <MenuItem value={false}>Inactive</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                        {/* <Button variant="contained" disableElevation onClick={handleCloseEditModal} color="primary" sx={{ mr: 2 }}>
                            Cancel
                        </Button> */}
                        <Button variant="contained" disableElevation onClick={handleUpdateFacility} color="primary">
                            {loading ? <CircularProgress size={18} sx={{ color: '#fff' }} /> : "Update"}
                        </Button>
                    </Box>
                </Box>
            </Modal>

            <CustomSnackbar
                open={snackbarOpen}
                handleClose={handleSnackbarClose}
                message={snackbarMessage}
                severity={snackbarSeverity}
            />

        </Box>
    );
};

export default MastersTable;
