import React from 'react';
import TimeSheetTable from '../Component/TimeSheetTable';
import ProviderLayout from '../ProviderLayout';

const TimeSheet = () => {


  return (
    <ProviderLayout >
      <TimeSheetTable />
    </ProviderLayout>
  );
};

export default TimeSheet;
