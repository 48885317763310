import { useState } from 'react';
import StateMasterBody from '../Component/StateMasterBody';
import Layout from '../Layout';


export default function StateMaster() {

    return (
        <Layout>
            <StateMasterBody  />
        </Layout>
    )
}